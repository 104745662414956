import React, { useReducer, FormEvent, useState } from 'react';
import Modal from 'react-modal';

import './Account.cards.scss';
import Input from '../../Forms/Input';
import Button from '../../Forms/Button';
import { CardParams, CardTokenResponse, ConektaCustomer_PaymentSource } from '../../../models/Conekta';
import { useMutation } from '@apollo/client';
import { graphqlSchema } from '../../../services/graphql.schema';
import { phoneParse } from '../../../utils/phoneParser';
import sharedToasterSubject from '../../../services/shared.toasterSubject';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        padding: '0px',
        border: 'none',
        boxShadow: 'rgba(0, 0, 0, 0.32) 1px 1px 3px 1px'
    }
};

interface ActionCardProps {
    isOpen: boolean;
    onRequestClose: (cardAdded: ConektaCustomer_PaymentSource) => void;

}

declare var Conekta: any;

interface CardTokenize {
    name: string;
    number: string;
    cvc: string;
    exp_month: string;
    exp_year: string;
    zip: string;
    state: string;
    country: string;
}
interface CardInput {
    tokenHash: string;
    cardString: string
    cardName: string;
    expiration: string;
}

function cardReducer(state: CardTokenize, { property, value }: { property: 'name' | 'number' | 'cvc' | 'exp_month' | 'exp_year' | 'zip' | 'state' | 'country', value: any }) {
    const assigned: { [key: string]: string } = {};
    if (property === 'exp_month') {
        if (value < 1) {
            value = 1;
        }
        if (value > 12) {
            value = 12;
        }
        assigned[property] = value;
    } else if (property === 'exp_year') {
        if (value < 1) {
            value = 1;
        }
        if (value > 2030) {
            value = 30;
        }
        assigned[property] = value;
    } else if (property === 'number') {
        assigned[property] = value.split(' ').join('');
    } else {
        assigned[property] = value;
    }
    console.log('Card reducer: ', Object.assign({}, state, assigned));
    return Object.assign({}, state, assigned);
}

function ActionCards({ isOpen, onRequestClose }: ActionCardProps) {
    // Validations
    const [numberValidated, setNumberValidated] = useState(false);
    const [emailValidated, setEmailValidated] = useState(false);
    const [saving, isSaving] = useState(false);

    // Data
    const [clientPhone, setClientPhone] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [cardEntry, dispatchCard] = useReducer(cardReducer, { name: '', number: '', cvc: '', exp_month: '', exp_year: '', zip: '', state: '', country: 'Mexico' });
    const [addCard] = useMutation(graphqlSchema.CONEKTA.CARDS.addCard, {
        onCompleted: ({ addCard }: { addCard: ConektaCustomer_PaymentSource }) => {
            console.log('Card added: ', addCard);
            // UseReducer to update object array
            isSaving(false);
            onRequestClose(addCard);
        },
        onError: (e) => {
            isSaving(false);
            console.error('Error Adding card: ', e);
            sharedToasterSubject.next({ type: 'error', message: 'Error agregar tarjeta, comuníquese con soporte' });
        }
    })

    const cardDispatcher = (property: 'name' | 'number' | 'cvc' | 'exp_month' | 'exp_year' | 'zip' | 'state' | 'country') => (value: string) => dispatchCard({ property, value });

    const cardIsValid = () => {
        if (!(cardEntry.name && cardEntry.number && cardEntry.exp_month && cardEntry.exp_year && cardEntry.cvc && cardEntry.zip)) {
            return;
        }
        if (!(/^\S+@\S+\.\S+$/.test(clientEmail))) {
            return;
        }
        if (!(/^\d{10}$/.test(clientPhone))) {
            return;
        }
        return true
    }

    const tokenizeCard = (e: FormEvent) => {
        e.preventDefault();
        // Validate minimums
        isSaving(true);
        const cardParameters: { card: CardParams } = {
            card: {
                name: cardEntry.name,
                number: cardEntry.number,
                exp_month: `${cardEntry.exp_month}`,
                exp_year: `${cardEntry.exp_year}`,
                cvc: cardEntry.cvc,
                address: {
                    zip: cardEntry.zip,
                    country: cardEntry.country,
                    state: cardEntry.state,
                }
            }
        }

        Conekta.Token.create(cardParameters, (token: CardTokenResponse) => {
            console.log('Conekta Card Token: ', token);
            addCard({
                variables: {
                    card: {
                        tokenHash: token.id,
                        cardString: cardEntry.number.substring(cardEntry.number.length - 5),
                        cardName: cardEntry.name,
                        expiration: `${cardEntry.exp_month}/${cardEntry.exp_year}`
                    },
                    phone: phoneParse(clientPhone),
                    email: clientEmail
                }
            })
        }, (error: Error) => {
            isSaving(false);
            console.error('Error tokenizing: ', error);
        });
    }

    const validateNum = () => {
        setNumberValidated(true);
    }

    const validateEmail = () => {
        setEmailValidated(true);
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => onRequestClose(null)}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Producto Edit Modal"
        >
            <div id="ActionCards">
                <div className="title">
                    <h3>Agrega una nueva tarjeta a tu cuenta</h3>
                </div>
                <form onSubmit={tokenizeCard}>
                    <Input value={cardEntry.name} label="Nombre en la tarjeta*" placeholder="Nombre como viene en la tarjeta" type="text" onChange={cardDispatcher('name')} />
                    <Input value={clientEmail} onBlur={validateEmail} hasError={!!emailValidated && !(/^\S+@\S+\.\S+$/.test(clientEmail))} label="Email usado con su banco*" placeholder="Agregue el correo electrónico que usa con su banco" type="text" onChange={setClientEmail} />
                    <Input value={clientPhone} onBlur={validateNum} hasError={!!numberValidated && !(/^\d{10}$/.test(clientPhone))} label="Teléfono que usa con su banco (10 dígitos)*" placeholder="Teléfono que usa con su banco" type="text" onChange={setClientPhone} />
                    <Input value={cardEntry.number} label="Número de la tarjeta*" placeholder="Número de tarjeta" type="text" onChange={cardDispatcher('number')} />
                    <div className="two md-three">
                        <Input value={cardEntry.exp_month} label="Mes vencimiento*" placeholder="MM" type="number" onChange={cardDispatcher('exp_month')} />
                        <Input value={cardEntry.exp_year} label="Año vencimiento*" placeholder="YY" type="number" onChange={cardDispatcher('exp_year')} />
                        <Input value={cardEntry.cvc} label="Número Seguridad*" placeholder="CVC" type="text" onChange={cardDispatcher('cvc')} />
                        <Input value={cardEntry.zip} label="Código postal*" placeholder="C.P." type="text" onChange={cardDispatcher('zip')} />
                        <Input value={cardEntry.state} label="Estado" placeholder="Estado" type="text" onChange={cardDispatcher('state')} />
                        <Input value={cardEntry.country} label="País" placeholder="País" type="text" onChange={cardDispatcher('country')} />
                    </div>
                    <Button type="submit" primary={true} disabled={!cardIsValid() || saving}>
                        {
                            saving ?
                                <span>Guardando tarjeta</span>
                                :
                                <span>Guardar tarjeta</span>
                        }
                    </Button>
                </form>
            </div>
        </Modal>
    )
}

export default ActionCards;