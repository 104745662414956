import React, { useState, useEffect } from 'react';

import './Facturas.reporte.scss';
import SectionTitleBar from '../../components/titlebar';
import { BaseReact } from '../../../base.model';
import { ProfileQL } from '../../../models/Profile';
import moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FiscalpopCFDI } from '../../../models/Factura';
import ReporteEntry from './Facturas.reporte.entry';
import ReporteEntryTotals from './Facturas.reporte.totals';
import FacturaReportDownload from './Download.reporte';
import InputCard from '../../Forms/Input.cardForm';
import SelectCardMultiple from '../../Forms/SelectCard.multiple';

interface FacturasProps extends BaseReact {
    currentUser: ProfileQL;
}

const FIELD_OPTIONS = [
    { label: 'Fecha', value: 'json.@.Fecha' },
    { label: 'UUID', value: 'uuid' },
    { label: 'Serie', value: 'json.@.Serie' },
    { label: 'Folio', value: 'json.@.Folio' },
    { label: 'RFC', value: 'json.cfdi:Receptor.@.Rfc' },
    { label: 'Razón social', value: 'json.cfdi:Receptor.@.Nombre' },
    { label: 'Tipo', value: 'json.@.TipoDeComprobante' },
    { label: 'Estatus', value: 'status' },
    { label: 'Moneda', value: 'json.@.Moneda' },
    { label: 'Subtotal', value: 'json.@.SubTotal' },
    { label: 'Descuento', value: 'json.@.Descuento' },
    { label: 'Impuestos', value: 'json.cfdi:Impuestos.@.TotalImpuestosTrasladados' },
    { label: 'Total', value: 'json.@.Total' },
]

function FacturaReporte({ currentUser }: FacturasProps) {
    const [dateStart, setDateStart] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [dateEnd, setDateEnd] = useState(moment().endOf('month').format('YYYY-MM-DD'));
    const [fields, setFields] = useState<{ label: string, value: string }[]>([
        {
            "value": "json.@.Fecha",
            "label": "Fecha"
        },
        {
            "value": "json.@.Serie",
            "label": "Serie"
        },
        {
            "value": "json.@.Folio",
            "label": "Folio"
        },
        {
            "value": "uuid",
            "label": "UUID"
        },
        {
            "value": "json.@.TipoDeComprobante",
            "label": "Tipo"
        },
        {
            "value": "status",
            "label": "Estatus"
        }
    ]);

    // Subs management
    const [isLoading, setIsLoading] = useState(false);
    const [billList, setBillList] = useState<FiscalpopCFDI[]>([]);
    const [dateSubs] = useState(new Subject<{ start: string, end: string }>());

    // UI Elements
    const parentRef = React.createRef<HTMLDivElement>();
    const [isOnEdges, setIsOnEdges] = useState(true);
    const [isOnBottom, setIsOnBottom] = useState(false);

    const manageScrollToBottom = () => {
        const maxScroll = parentRef.current.scrollHeight;
        const scrollPos = parentRef.current.scrollTop;
        if (scrollPos < 200) {
            // On Top Edge
            setIsOnEdges(true);
            setIsOnBottom(false);
        } else if ((maxScroll - (scrollPos + window.innerHeight)) < 150) {
            // On Bottom Edge
            if(maxScroll > (window.innerHeight * 2)){
                setIsOnEdges(true);
                setIsOnBottom(true);
            } else {
                setIsOnEdges(true);
                setIsOnBottom(false);
            }
        } else {
            setIsOnEdges(false);
            setIsOnBottom(false);
        }
    }

    const fetchCFDIpreview = (dateRanges: { start: string, end: string }) => {
        // locale is on UTC, move to EOD
        const startTime = moment(new Date(dateRanges.start).getTime()).endOf('day').add(2, 'minutes').toDate().getTime();
        const endTime = moment(new Date(dateRanges.end).getTime()).endOf('day').add(2, 'minutes').toDate().getTime();

        fetch(`https://api.fiscalpop.com/api/v1/cfdi/findBy/${currentUser.claroProfile.authToken}/${startTime}/${endTime}?conceptos=true&impuestos=true`)
            .then(async response => {
                if (response.ok) {
                    const json = await response.json();
                    return json;
                } else {
                    const err = await response.text();
                    throw err;
                }
            })
            .then((cfdiResponse: FiscalpopCFDI[]) => {
                console.log('CFDIs: ', cfdiResponse);
                setBillList(cfdiResponse);
                setIsLoading(false)
            })
            .catch(e => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        const sub = dateSubs.pipe(debounceTime(350)).subscribe((dateRanges) => {
            setIsLoading(true)
            fetchCFDIpreview(dateRanges);
        });
        return () => {
            sub.unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dateSubs.next({ start: dateStart, end: dateEnd });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateStart, dateEnd])

    const _setFields = (fields: string[]) => {
        const fieldValue = fields.map(f => ({ value: f, label: FIELD_OPTIONS.find(fo => fo.value === f).label }))
        setFields(fieldValue);
    }

    const gotToBottom = () => {
        parentRef.current.scrollTo(0, parentRef.current.scrollHeight);
    }

    const goToTop = () => {
        parentRef.current.scrollTo(0, 0);
    }

    const sortedFields = fields.sort((a, b) => FIELD_OPTIONS.findIndex((fo) => a.value === fo.value) - FIELD_OPTIONS.findIndex((fo) => b.value === fo.value));
    return (
        <div id="FacturaReporte" ref={parentRef} onScroll={manageScrollToBottom}>
            <SectionTitleBar currentUser={currentUser} title="Generar Reporte de facturación" />
            <div className="reporteContent">
                <div className="filterChange">
                    <InputCard disabled={isLoading} type='date' label="Fecha inicio" value={dateStart} placeholder="Fecha de inicio" max={dateEnd} onChange={setDateStart} />
                    <InputCard disabled={isLoading} type='date' label="Fecha final" value={dateEnd} placeholder="Fecha final" min={dateStart} onChange={setDateEnd} />
                    <SelectCardMultiple label="Campos a incluír" onChange={_setFields} values={fields.map(f => f.value)} options={FIELD_OPTIONS} />
                    <FacturaReportDownload fields={sortedFields} billList={billList} />
                </div>
                <div className="reportBody card">
                    {
                        sortedFields.length && billList.length ?
                            <table className={fields.length > 10 ? 'long' : ''}>
                                <tbody>
                                    <tr className="headers">
                                        {sortedFields.map((f) => {
                                            return (
                                                <td key={f.label} className={`_${f.label}`}>{f.label}</td>
                                            )
                                        })}
                                    </tr>
                                    {
                                        billList.map((b) => (
                                            <ReporteEntry key={b.uuid} factura={b} fields={sortedFields} />
                                        ))
                                    }
                                    {
                                        billList.length ?
                                            <ReporteEntryTotals billList={billList} fields={sortedFields} />
                                            : ''
                                    }
                                </tbody>
                            </table>
                            : (!billList.length ? <h2>No hay facturas a mostar en estas fechas</h2> : <h2>Selecciona campos para el reporte</h2>)
                    }
                </div>
                <div className={`_scrollToBottom ${isOnEdges ? '' : 'inSight'}`} onClick={gotToBottom}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                        <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
                    </svg>
                    <p>
                        Ir hasta abajo
                    </p>
                </div>
                <div className={`_scrollToBottom toTop ${isOnBottom ? 'inSight' : ''}`} onClick={goToTop}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                        <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
                    </svg>
                    <p>
                        Ir hasta arriba
                    </p>
                </div>
            </div>
        </div>
    )
}

export default FacturaReporte