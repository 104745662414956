import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { graphqlSchema } from '../../services/graphql.schema';
import GraphQlClient from '../../services/graphql';

import './Setup.defaults.scss';

import IconButton from '../Forms/IconButton';
import {ClaroProfile} from '../../models/Profile';
import { post } from '../../services/post';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import AutoComplete from '../Forms/Autocomplete';
import sharedToasterSubject from '../../services/shared.toasterSubject';

function SetupDefaults ({claroProfile}: {claroProfile: ClaroProfile}) {
    const [setDefaults] = useMutation(graphqlSchema.PROFILE.SETUP.setSATdefaults, {
        onCompleted: ({ setSATdefaults }) => {
            console.log('Updated Defaults: ', setSATdefaults);
            GraphQlClient.modifyClientMLprofile(setSATdefaults);
            sharedToasterSubject.next({ type: 'confirm', message: `Se cambió tus claves predeterminadas correctamente` });
        },
        onError: (e) => {
            console.error('Error updating defaults: ', e);
        }
    })

    const [defaultClaveSatProdServ, setDefaultClaveSatProdServ] = useState(claroProfile.defaultClaveSatProdServ);
    const [defaultUnidadSat, setDefaultUnidadSat] = useState(claroProfile.defaultUnidadSat);
    const [claveOptions, setClaveOptions] = useState<{label: string, value:string}[]>([])
    const [unidadOptions, setUnidadOptions] = useState<{label: string, value:string}[]>([])
    const [prodServErr, setProdServErr] = useState(false);
    const [unidadErr, setUnidadErr] = useState(false);

    const [unitSubject] = useState<Subject<string>>(new Subject());
    const [claveSubject] = useState<Subject<string>>(new Subject());

    const fetchClaves = (defaultClaveSatProdServ: string) => {
        console.log('[SETUP - DEFAULTS] Fetching claves: ', defaultClaveSatProdServ);
        return post(`https://api.fiscalpop.com/api/v1/sat/productosServicios/${claroProfile.authToken}`, {
            compare: defaultClaveSatProdServ,
            clave: defaultClaveSatProdServ
        }).then(response => setClaveOptions(response.map((r: {clave: string, descripcion:string}) => ({ label: `(${r.clave}) ${r.descripcion}`, value: r.clave }))))
    }

    const fetchUnidad = (defaultUnidadSat: string) => {
        return post(`https://api.fiscalpop.com/api/v1/sat/claveUnidades/${claroProfile.authToken}`, {
            compare: defaultUnidadSat,
            clave: defaultUnidadSat
        }).then(response => setUnidadOptions(response.map((r: {clave: string, nombre:string})  => ({ label: `(${r.clave}) ${r.nombre}`, value: r.clave }))))
    }

    const claveProdErrTest = () => {
        const isValidProdServ = claveOptions.find(o => o.value.toLowerCase() === (defaultClaveSatProdServ||'').toLowerCase());
        if (!isValidProdServ) {
            setProdServErr(true);
        }
    }

    const unitErrTest = () => {
        // Can be empty for later addition
        if (!defaultUnidadSat) {
            return setUnidadErr(false);
        }
        const isValidUnidad = unidadOptions.find(o => o.value.toLowerCase() === (defaultUnidadSat||'').toLowerCase());
        if (!isValidUnidad) {
            setUnidadErr(true);
        }
    }

    useEffect(() => {
        fetchClaves((!!claroProfile && claroProfile.defaultClaveSatProdServ) || '');
        fetchUnidad((!!claroProfile && claroProfile.defaultUnidadSat) || '');
        console.log('[SETUP - DEFAULTS] useEffect!');
        const claveSubs = claveSubject.pipe(debounceTime(350)).subscribe(claveProdServ => {
            console.log('[SETUP - DEFAULTS] clave subs: ', claveProdServ, defaultClaveSatProdServ);
            fetchClaves(claveProdServ);
        })
        const unitSubs = unitSubject.pipe(debounceTime(350)).subscribe(claveUnidad => {
            fetchUnidad(claveUnidad);
        })

        return () => {
            console.log('[SETUP - DEFAULTS] useEffect closed');
            claveSubs.unsubscribe();
            unitSubs.unsubscribe();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClaveChange = (claveProdServ: string) => {
        setDefaultClaveSatProdServ(claveProdServ);
        setProdServErr(false);
        claveSubject.next(claveProdServ);
    }
    const onUnidadChange = (claveUnidad: string) => {
        setDefaultUnidadSat(claveUnidad);
        setUnidadErr(false);
        unitSubject.next(claveUnidad);
    }

    const onSelectedClaveProd = (claveSatProdServ: string) => {
        setDefaultClaveSatProdServ(claveSatProdServ);
        setProdServErr(false);
    }
    
    const onSelectedUnidad = (unidadSat: string) => {
        setDefaultUnidadSat(unidadSat);
        setUnidadErr(false);
    }

    const saveDefaults = () => {
        console.log('[SETUP - DEFAULTS] defaults: ', {defaultClaveSatProdServ, defaultUnidadSat})
        setDefaults({
            variables: {
                defaults: {
                    defaultClaveSatProdServ,
                    defaultUnidadSat
                }
            }
        })
    }

    return (
        <div className="card defaultsChange" id="SetupDefaults">
            <div className="title">
                <h4>Claves SAT default</h4>
                <IconButton primary={true} disabled={unidadErr || prodServErr || !(defaultUnidadSat && defaultClaveSatProdServ)} handleClick={saveDefaults}>
                    <i className="material-icons">
                        save
                    </i>
                </IconButton>
            </div>
            <div className="defaultWrap">
                <AutoComplete
                    placeholder="Clave producto o servicio default a usar"
                    label="Clave producto o servicio"
                    value={defaultClaveSatProdServ}
                    type="text"
                    hasError={prodServErr}
                    onBlur={claveProdErrTest}
                    onSelect={onSelectedClaveProd}
                    onChange={onClaveChange}
                    options={claveOptions} />

                <AutoComplete
                    placeholder="Clave de unidad default a usar"
                    label="Clave de Unidad de medida"
                    value={defaultUnidadSat}
                    type="text"
                    hasError={unidadErr}
                    onBlur={unitErrTest}
                    onSelect={onSelectedUnidad}
                    onChange={onUnidadChange}
                    options={unidadOptions} />

            </div>
        </div>
    )
}

export default SetupDefaults;