import React, { useState, useEffect } from 'react';

const TIPO_FIELD = 'json.@.TipoDeComprobante'.split('.');

function FacturaReportDownload({ billList, fields }: { billList: any[], fields: { label: string, value: string }[] }) {
    const [csvFile, setCsvFile] = useState(null);

    useEffect(() => {
        if (billList.length && fields.length) {
            const csvHead = fields.map((fv) => fv.label).join(',');
            const csvBody = billList.map((factura) => {
                const billIsRefund = TIPO_FIELD.reduce((p: any, c: any) => p[c], factura) === 'E';
                const row = fields.map((fv) => {
                    const f = fv.value;
                    const value = f.split('.').reduce((p: any, c: any) => p[c], factura);
                    if (fv.label === 'Subtotal' || fv.label === 'Descuento' || fv.label === 'Impuestos' || fv.label === 'Total') {
                        if (billIsRefund) {
                            return `-${value}`;
                        } else {
                            return value;
                        }
                    } else {
                        return value;
                    }
                }).join(',');
                return row;
            })
            const csv = [csvHead].concat(csvBody).join('\n');
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            setCsvFile(URL.createObjectURL(blob));
        } else {
            setCsvFile(null);
        }
    }, [billList, fields]);

    if (!csvFile) {
        return (
            <span className="_downloadCsv disabled">
                <span>
                    Descargar
                </span>
            </span>
        )
    }
    return (
        <a className="_downloadCsv" href={csvFile} download={`Facturas_${new Date().toDateString()}.csv`}>
            <span>
                Descargar
            </span>
        </a>
    )

}

export default FacturaReportDownload;