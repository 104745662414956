import React from 'react';
import Modal from 'react-modal';

import { SpeiResponse } from '../../../models/Conekta';

import './Spei.confirm.scss';
import Button from '../../Forms/Button';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh',
        maxWidth: '500px',
        padding: '0px',
        border: 'none',
        boxShadow: 'rgba(0, 0, 0, 0.32) 1px 1px 3px 1px'
    }
};

interface SpeiConfirmProps {
    email: string;
    isOpen: boolean;
    speiOrder: SpeiResponse;
    onRequestClose: (response?: any) => void;
}

function SpeiConfirm({ email, isOpen, onRequestClose, speiOrder }: SpeiConfirmProps) {

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => onRequestClose()}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Producto Edit Modal"
        >{
                !!speiOrder ?
                    <div id="SpeiConfirm">
                        <div className="speiOrder">
                            <div className="bindLogo">
                                <img src="https://fiscalpop.sfo2.cdn.digitaloceanspaces.com/assets/SPEI-LOGO.png" alt="Spei Logo" />
                                <div className="monto">
                                    <p>Monto A Pagar</p>
                                    <h2>$ {speiOrder.amount.toFixed(0)}<span className="cents">.{speiOrder.amount.toFixed(2).split('.')[1]}</span></h2>
                                </div>
                            </div>
                            <div className="infoEntry">
                                <b>Banco:</b>
                                <p>{speiOrder.bank}</p>
                            </div>
                            <div className="infoEntry">
                                <b>CLABE:</b>
                                <p>{speiOrder.clabe}</p>
                            </div>
                            <p className="instructions">
                                Por favor deposite la cantidad seleccionada exactamente para poder identificar su pago.
                        <b> No se preocupe si se equivoca, la transferencia será devuelta automáticamente.</b>
                            </p>
                            <p>
                                Se envió una copia de estas instrucciones al correo <b>{email}</b>, mismo que recibirá la factura de su pago una vez confirmado
                    </p>
                            <Button secondary={true} handleClick={onRequestClose}>
                                <span>Cerrar</span>
                            </Button>
                        </div>
                    </div>
                    : ''}
        </Modal>
    )
}

export default SpeiConfirm;