import React, { useState, useEffect } from 'react';

import moment from '../../services/unifiedMoment';

import './Stamp.global.dropdown.scss'
import { ClaroOrdersBinded } from '../../models/Orders';
import Checkbox from '../Forms/Checkbox';
import Input from '../Forms/Input';

interface GlobalDropdownProps {
    unSelectedDict: { [key: string]: boolean };
    allOrders: ClaroOrdersBinded[];
    testAllSelected: () => boolean;
    setAllSelect: (val: boolean) => void;
    toggleSelect: (orderId: string) => (value: boolean) => void;
    filteredOrders: ClaroOrdersBinded[];
    search:string;
    setSearch: React.Dispatch<React.SetStateAction<string>>
}

function StampGlobalDropdown({ allOrders, unSelectedDict, testAllSelected, setAllSelect, toggleSelect, search, setSearch, filteredOrders }: GlobalDropdownProps) {
    const [numSelected, setNumSelected] = useState(0);
    const [open, setOpen] = useState(false);
    const [hasAimated, setHasAimated] = useState(false);

    useEffect(() => {
        const num = (allOrders.length - Object.keys(unSelectedDict).reduce((p, c) => {
            p += !!unSelectedDict[c] ? 1 : 0;
            return p;
        }, 0));
        setNumSelected(num);
    }, [allOrders, unSelectedDict]);

    const toggleOpen = () => {
        setOpen(!open);
        if (!hasAimated) {
            setHasAimated(true);
        }
    }

    return (
        <div id="StampGlobalDropdown" className={`${open ? (hasAimated ? 'open' : '') : 'closed'}`}>
            {
                open ?
                    <div className="_dOverlay" onClick={toggleOpen}></div>
                    : ''
            }
            <div className="card trigger" onClick={toggleOpen}>
                <p>{testAllSelected() ? 'Todos los pedidos seleccionados' : `${numSelected} pedidos seleccionados`}</p>
                <i className="material-icons">
                    keyboard_arrow_down
                </i>
            </div>

            <div className="card dropSelect">
                <div className="multiSelectD">
                    <Checkbox color={'#a262c8'} disabled={false} label='Seleccionar todos' value={testAllSelected()} onChange={setAllSelect} />
                </div>
                <div className="listedSelectD">
                    <Input label="Buscar número de orden" type="text" value={search} onChange={setSearch} placeholder={'Buscar ID'} />
                    {
                        filteredOrders.map((order, index) => {
                            return (
                                <div className="checkListWrapD" key={order.order.nopedido} >
                                    <Checkbox disabled={false} label={`#${order.order.nopedido}`} value={!unSelectedDict[order.order.nopedido]} onChange={toggleSelect(order.order.nopedido)} />
                                    <span className="checkTimeD">{moment(order.order.fechaautorizacion).fromNow()}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default StampGlobalDropdown;