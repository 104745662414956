import React, { ReactElement } from "react";
import Loading from "../Animations/loadScreen";

import './Input.scss';

interface AutoCompleteProps {
    onChange: Function,
    onSelect?: Function,
    onBlur?: Function,
    hasError?: boolean;
    isLoading?: boolean;
    placeholder: string,
    label?: string,
    value: string | number,
    type: 'number' | 'text',
    disabled?: boolean;
    displayLabel?: boolean;
    forcePropsValue?: boolean;
    noChangeOnSelect?: boolean;
    options: { label: string, value: any, template?: ReactElement }[];
}

class AutoComplete extends React.Component {
    props: AutoCompleteProps;
    state: {
        active: boolean,
        shown: boolean,
        value: string,
        selected: boolean;
        keydIndex: number;
    }
    constructor(props: AutoCompleteProps) {
        super(props);
        this.state = {
            active: false,
            shown: false,
            value: '',
            selected: false,
            keydIndex: 0
        }

        this.activate = this.activate.bind(this);
        this.arrowMove = this.arrowMove.bind(this);
        this.deactivate = this.deactivate.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.selectValue = this.selectValue.bind(this);
    }

    activate() {
        this.setState({ active: true });
    }

    deactivate() {
        setTimeout(() => {
            this.setState({ active: false });
            if (!!this.props.onBlur) {
                this.props.onBlur(true);
            }
        }, 150);
    }

    changeValue(event: React.ChangeEvent<HTMLInputElement>) {
        const value: string = this.state.selected ? `${this.props.value}` : event.target.value;
        this.setState({ value: value, selected: false });
        if (!this.props.onChange) {
            return;
        }
        if (this.props.type === 'number') {
            this.props.onChange(parseFloat(value));
        } else {
            this.props.onChange(value);
        }
    }

    selectValue(value: any) {
        return () => {
            this.setState({ value, selected: true });
            if (this.props.type === 'number') {
                this.props.onChange(parseFloat(value));
            } else {
                this.props.onChange(value);
            }
            if (!!this.props.onSelect) {
                this.props.onSelect(value);
            }
            setTimeout(() => {
                this.setState({ active: false });
            }, 150);
        }
    }

    arrowMove(e: React.KeyboardEvent<HTMLInputElement>) {
        const options = this.props.options.filter(e => {
            const value = `${this.props.value}`.toLowerCase();
            return e.label.toLowerCase().includes(value) || `${e.value}`.toLowerCase().includes(value);
        });
        if (this.state.active && options.length) {
            // console.log('E.key', e.key)
            if (e.key === 'ArrowDown') {
                if ((this.props.options.length - 1) > this.state.keydIndex) {
                    this.setState({ keydIndex: this.state.keydIndex + 1 })
                } else {
                    this.setState({ keydIndex: 0 })
                }
            }
            if (e.key === 'ArrowUp') {
                if ((this.props.options.length - 1) < this.state.keydIndex) {
                    this.setState({ keydIndex: (this.props.options.length - 1) })
                } else if (this.state.keydIndex === 0) {
                    this.setState({ keydIndex: (this.props.options.length - 1) })
                } else {
                    this.setState({ keydIndex: this.state.keydIndex - 1 })
                }
            }
            if (e.key === 'Enter') {
                const candidate = options[this.state.keydIndex];
                if (!!candidate) {
                    this.selectValue(candidate.value)();
                }
            }
        }
    }

    renderOptions() {
        return this.props.options.filter(e => {
            const value = `${this.props.value}`.toLowerCase();
            return e.label.toLowerCase().includes(value) || `${e.value}`.toLowerCase().includes(value);
        }).map((e, i) => (
            <li key={i} className="option" onClick={this.selectValue(e.value)}>
                <p>
                    {e.label}
                </p>
            </li>
        ))
    }

    render() {
        const _classInput = this.state.active ? `active inputWrapper` : `inputWrapper`;
        const classInput = !!this.props.hasError ? `error ${_classInput}` : _classInput;
        const classComplete = this.state.active ? `active autocomplete` : `autocomplete`;
        const _value = this.props.forcePropsValue ? this.props.value : this.props.value || this.state.value;
        const eligeableOption = this.props.options.find(o => o.value === this.props.value);
        const value = this.props.displayLabel && !!this.props.value && !!eligeableOption && !this.state.active ? `${eligeableOption.label}` : _value;
        return (
            <div  className={`inputBlock ${!!this.props.hasError ? 'error' : ''} ${this.state.active ? 'active' : ''} ${this.props.disabled ? 'disabled' : ''} ${!!value ? 'dirty' : ''}`}>
                {
                    !!this.props.label ?
                        <label>
                            {this.props.label}
                        </label>
                        : <label></label>
                }
                <div className={classInput} >
                    <input type={this.props.type}
                        value={value}
                        disabled={this.props.disabled || false}
                        onKeyDown={this.arrowMove}
                        onFocus={this.activate} onBlur={this.deactivate}
                        placeholder={this.props.placeholder} onChange={this.changeValue} />
                </div>
                <div className={classComplete}>
                    <ul>
                        {this.renderOptions()}
                        {
                            !!this.props.isLoading ?
                                <li>
                                    <Loading
                                        display={this.props.isLoading}
                                        relativePos={true}
                                        svgWidth={'24'}
                                        svgHeight={'24'}
                                    />
                                </li>
                                : ''
                        }
                    </ul>
                </div>
                {
                    this.props.hasError ?
                        <span className="errorText">
                            Selecciona una de las opciones
                        </span>
                        : ''
                }
            </div>
        )
    }
}

export default AutoComplete;