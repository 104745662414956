import React, { useState } from 'react';

import './Items.BulkEdit.scss';

import { BaseReact } from '../../../base.model';
import { ProfileQL } from '../../../models/Profile';
import SectionTitleBar from '../../components/titlebar';
import Select from '../../Forms/Select';
import { useQuery, useMutation } from '@apollo/client';
import { graphqlSchema } from '../../../services/graphql.schema';
import Button from '../../Forms/Button';
import Checkbox from '../../Forms/Checkbox';
import Pagination from '../../Forms/Pagination';
import ItemBulkEditModal from './Items.BulkEdit.dialog';
import sharedToasterSubject from '../../../services/shared.toasterSubject';
import { ClaroItemsBinded, ClaroItemBinder } from '../../../models/Items';
import Input from '../../Forms/Input';


interface ItemsProps extends BaseReact {
    currentUser: ProfileQL;
}

const ENTRIES_PER_PAGE = 35;

function ItemsBulkEdit({ history, location, currentUser }: ItemsProps) {
    const [page, setPage] = useState(1);

    const [filterBy, setFilterBy] = useState('');
    const [search, setSearch] = useState('');

    const [productListing, setProductListing] = useState<{ products: ClaroItemsBinded[] }>({ products: [] });

    const [selectedSet, setSelectedSet] = useState<{ [key: string]: boolean }>({});

    const [itemsToEdit, setItemsToEdit] = useState<ClaroItemsBinded[]>([])

    useQuery(graphqlSchema.PROFILE.ITEMS.getItems, {
        onCompleted: ({ getItems }) => {
            console.log('getItems: ', getItems);
            setProductListing({ products: getItems });
        },
        fetchPolicy: 'cache-and-network'
    })

    const [setBulkItemBinder] = useMutation(graphqlSchema.PROFILE.ITEMS.setBulkItemBinder, {
        onCompleted: ({ setBulkItemBinder }: { setBulkItemBinder: ClaroItemBinder[] }) => {
            console.log('setBulkItemBinder: ', setBulkItemBinder);
            //const _productBinders: { binders: ClaroItemBinder[] } = Object.assign({}, productBinders);
            const products = Object.assign({}, productListing);
            products.products.filter(p => setBulkItemBinder.some(bIB => p.item.claroid)).forEach(p => {
                const binderAssign = setBulkItemBinder.find(bIB => bIB.productId === p.item.claroid);
                if (!!binderAssign) {
                    p.binder = binderAssign;
                }
            })
            setProductListing(products);
            sharedToasterSubject.next({ type: 'confirm', message: 'Se actualizaron los producto seleccionados' });
        },
        onError: e => {
            console.error('Error updating Bulk items')
            sharedToasterSubject.next({ type: 'error', message: 'Error al actualizar productos, comuníquese con soporte' });
        }
    })

    const changePage = (_page: number) => {
        console.log('Changing page to: ', _page);
        setPage(_page);
    }

    const selectItem = (itemId: number) => {
        return (bool: boolean) => {
            selectedSet[itemId] = bool;
            setSelectedSet(Object.assign({}, selectedSet));
        }
    }

    const preFilterProductListing = () => {
        if (!search && !filterBy) {
            return productListing.products
        } else if (!search && !!filterBy) {
            return productListing.products.filter(p => {
                if (filterBy === 'binder') {
                    return !!p.binder
                } else {
                    return !p.binder
                }
            });
        } else {
            return productListing.products.filter(p => {
                if (filterBy === 'binder') {
                    return !!p.binder
                } else if (filterBy === 'no-binder') {
                    return !p.binder
                } else {
                    return true;
                }
            }).filter(p => {
                const searchWords = search.toLowerCase().split(' ');
                const words = p.item.nombre.toLowerCase().split(' ');
                return searchWords.every(sW => words.some(w => w.includes(sW)));
            });
        }
    }

    const filterProductListing = () => {
        const _filteredItems = preFilterProductListing()
        const maxPage = Math.ceil(_filteredItems.length / ENTRIES_PER_PAGE);
        const _page = page <= maxPage ? page : maxPage;
        return _filteredItems.filter((_, i) => (i >= ENTRIES_PER_PAGE * (_page - 1)) && (i < ENTRIES_PER_PAGE * _page));
    }

    const selectAll = (selected: boolean) => {
        preFilterProductListing().forEach(p => {
            selectedSet[p.item.claroid] = selected;
        });
        setSelectedSet(Object.assign({}, selectedSet));
    }

    const checkSelectedAll = () => {
        return preFilterProductListing().reduce((p, c) => {
            return p && selectedSet[c.item.claroid];
        }, true);
    }

    const openModalForSelected = () => {
        const selectedItems = productListing.products.filter(p => !!selectedSet[p.item.claroid])
        setItemsToEdit(selectedItems.map((p) => {
            const _binder = p.binder;
            const defaultBinder: ClaroItemBinder = _binder ? _binder : {
                productId: p.item.claroid,
                claveProdServ: currentUser.claroProfile.defaultClaveSatProdServ,
                claveUnidad: currentUser.claroProfile.defaultUnidadSat,
                customized: false,
                taxes: [{
                    type: 'iva',
                    tipo: 'Fijo',
                    retencion: false,
                    tasa: 0.16
                }]
            }
            const binded = {
                item: p.item,
                binder: defaultBinder
            }
            return binded;
        }))
    }

    const closeModal = (save: boolean, binderForBulk: ClaroItemBinder) => {
        if (save) {
            const itemIdsToEdit = itemsToEdit.map(it => it.item.claroid);
            console.log('Items to update: ', itemIdsToEdit);
            console.log('Items binder: ', binderForBulk);
            if (itemIdsToEdit.length) {
                setBulkItemBinder({
                    variables: {
                        productIds: itemIdsToEdit,
                        binder: binderForBulk
                    }
                })
            }
        }
        setItemsToEdit([]);
    }

    const editEnabled = filterProductListing().reduce((p, c) => p || selectedSet[c.item.claroid], false as boolean);

    const filterByOptions = [
        { value: '', label: 'Todos' },
        { value: 'binder', label: 'Customizados' },
        { value: 'no-binder', label: 'Sin customizar' },
    ]

    const renderProductList = () => {
        const prodsBinded = filterProductListing().map((p) => {
            const _binder = p.binder;
            const defaultBinder: ClaroItemBinder = _binder ? _binder : {
                productId: p.item.claroid,
                claveProdServ: currentUser.claroProfile.defaultClaveSatProdServ,
                claveUnidad: currentUser.claroProfile.defaultUnidadSat,
                customized: false,
                taxes: [{
                    type: 'iva',
                    tipo: 'Fijo',
                    retencion: false,
                    tasa: 0.16
                }]
            }
            const binded = {
                item: p.item,
                binder: defaultBinder
            }
            return binded;
        })
        return (
            <div className="card itemList">
                <div className="item header">

                    <Checkbox value={checkSelectedAll()} onChange={selectAll} />
                    <p className="_id md-2">
                        ID
                            </p>
                    <p className="_title">
                        Nombre
                            </p>
                    <p className="_codes">
                        Claves SAT
                            </p>
                    <p className="_available md-1">
                        Existencias
                            </p>
                    <p className="_price md-3">
                        Precio total
                            </p>
                    <p className="_subtotal md-3">
                        Subtotal
                            </p>
                    <p className="_taxes">
                        <span className="md-5">
                            Impuesto
                        </span>
                        <span className="md-show-5">
                            Imp.
                        </span>
                    </p>
                </div>
                {
                    prodsBinded.map((productBinded, i) => {
                        const product = productBinded.item;
                        const taxes = productBinded.binder.taxes;
                        const iva = taxes.find(t => t.type === 'iva');
                        const ieps = taxes.filter(t => t.type === 'ieps');
                        const iepsCuota = ieps.reduce((p, c) => p += (c.cuota || 0), 0);
                        const iepsTasa = ieps.reduce((p, c) => p += (c.tasa || 0), 0);
                        const subtotalPreIeps = (Math.round((product.precio / (1 + iva.tasa)) * 100) / 100);
                        const subtotal = iepsCuota > 0 ? subtotalPreIeps - iepsCuota : Math.round((subtotalPreIeps / (1 + iepsTasa)) * 100) / 100;
                        return (
                            <div className={`item `} key={product.claroid}>
                                <Checkbox value={selectedSet[product.claroid]} onChange={selectItem(product.claroid)} />
                                <p className="_id md-2">
                                    {product.claroid}
                                </p>
                                <p className="_title">
                                    {product.claroid}
                                </p>
                                <div className="_codes">
                                    <p className={`_clavProdServ ${!!productBinded.binder ? '' : '_notCustomized'}`}>
                                        {productBinded.binder.claveProdServ}
                                    </p>
                                    {
                                        !!productBinded.binder ? '' :
                                            <p className="_notCustomized">(Default)</p>
                                    }
                                </div>
                                <span />
                                <p className="_price md-3">
                                    $ {product.precio.toFixed(2)}
                                </p>
                                <p className="_subtotal md-3">
                                    $ {subtotal.toFixed(2)}
                                </p>
                                <div className="_taxes">
                                    <p className="_iva">
                                        <b>IVA</b><span className="md-4">: $ {(product.precio - subtotalPreIeps).toFixed(2)}</span>
                                    </p>
                                    {
                                        ieps.length ?
                                            <p className="_ieps">
                                                <b>IEPS</b><span className="md-4">: $ {(subtotalPreIeps - subtotal).toFixed(2)}</span>
                                            </p>
                                            : ''
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const renderEmpty = () => {
        return (
            <div className="_empty">
                <h2>No se encontraron resultados...</h2>
            </div>
        )
    }

    return (
        <div id="ItemsBulkEdit">
            <SectionTitleBar currentUser={currentUser} title="Editar productos masivamente" />
            <div className="itemsContent">
                <div className="filterChange">
                    <Input type="text" value={search} onChange={setSearch} label="Buscar por nombre" placeholder="Buscar productos por nombre" />
                    <Select label="Filtrar por Customizados" value={filterBy} onChange={setFilterBy} options={filterByOptions} />
                    <Button secondary={true} disabled={!editEnabled} handleClick={openModalForSelected}>
                        <span>Editar seleccionados</span>
                    </Button>
                </div>
                {
                    productListing && productListing.products.length ?
                        renderProductList()
                        : renderEmpty()
                }
                {
                    productListing && productListing.products.length ?
                        <Pagination page={page} pages={Math.ceil(filterProductListing().length / ENTRIES_PER_PAGE)} onPage={changePage} />
                        : ''
                }
            </div>
            <ItemBulkEditModal currentUser={currentUser} onClose={closeModal} modalIsOpen={!!itemsToEdit.length} items={itemsToEdit} />
        </div>
    )
}

export default ItemsBulkEdit;