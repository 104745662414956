import React from 'react';

import './Button.scss';

function Button({primary, secondary, tertiary, accent, disabled, handleClick, children, type}:{
    primary?: boolean; secondary?: boolean; tertiary?:boolean; accent?:boolean; disabled?:boolean; handleClick?: () => void; children:JSX.Element, type?:'submit'|'button'}){
    return(
        <button className={`${accent ? 'accent':''} ${primary?'primary':''} ${secondary?'secondary':''} ${tertiary ? 'tertiary' : ''} __Button`} onClick={handleClick} type={type || "button"} disabled={disabled}>
            {children}
        </button>
    )
}

export default Button;