import React, { useState } from 'react';

import './Setup.scss';
import { BaseReact } from '../../base.model';
import { ProfileQL } from '../../models/Profile';
import FiscalpopDialog from './Setup.fiscalpop.dialog';

import SetupLogoUpload from './Setup.logoUpload';
import SetupRfcChange from './Setup.rfcChange';
import SetupLugarChange from './Setup.lugarChange';
import SetupUploadCert from './Setup.certUpload';
import SetupKeyUpload from './Setup.keyUpload';
// import SetupGlobalBilling from './Setup.globalBilling';
import SetupDefaults from './Setup.defaults';
import SectionTitleBar from '../components/titlebar';
import SetupClaroClaves from './Setup.claves';

interface SetupProps extends BaseReact {
    currentUser: ProfileQL;
}

function SetupFiscalPop({ history, location, currentUser }: SetupProps) {
    console.log('[SETUP] ', currentUser);


    const search = !location.search ? false : location.search.replace('?', '').split('&').reduce((p, c) => {
        const [key, value] = c.split('=');
        p[key] = value === 'true' ? true : (value === 'false' ? false : (value));
        return p;
    }, {} as { [key: string]: any });
    const [highlighted] = useState(!!search && search['showcerts']);

    return (
        <div id="SetupFiscalPop">
            <SectionTitleBar currentUser={currentUser} title="Configurar cliente de FiscalPOP" />
            {
                currentUser.fiscalpopProfile ?


                    <div className="setupContent">
                        <div className="logoRows">
                            <div className='entryRow'>
                                <SetupRfcChange fiscalpopProfile={currentUser.fiscalpopProfile} />
                                <SetupLugarChange fiscalpopProfile={currentUser.fiscalpopProfile} />
                                <SetupDefaults claroProfile={currentUser.claroProfile} />
                                <SetupClaroClaves claroProfile={currentUser.claroProfile} />
                            </div>
                            <div className='logoColumn'>
                                <SetupLogoUpload currentUser={currentUser} />
                            </div>
                        </div>
                        <SetupUploadCert highlight={highlighted} fiscalpopProfile={currentUser.fiscalpopProfile} fiscalpopProfileStatus={currentUser.fiscalpopProfileStatus} />
                        <SetupKeyUpload highlight={highlighted} fiscalpopProfile={currentUser.fiscalpopProfile} fiscalpopProfileStatus={currentUser.fiscalpopProfileStatus} />
                    </div>

                    : ''
            }
            <FiscalpopDialog modalIsOpen={!currentUser.fiscalpopProfile} />
        </div>
    )
}

export default SetupFiscalPop;