import React, { useState, useEffect } from 'react';

import './Facturas.scss';
import { BaseReact } from '../../base.model';
import { ProfileQL } from '../../models/Profile';
import { FiscalpopCFDI } from '../../models/Factura';
import SectionTitleBar from '../components/titlebar';
import FacturasEntry from './Facturas.entry';

import Modal from 'react-modal';
import Button from '../Forms/Button';
import { useMutation } from '@apollo/client';
import { graphqlSchema } from '../../services/graphql.schema';
import moment from 'moment';
import Select from '../Forms/Select';
import Input from '../Forms/Input';

import Loading from '../Animations/loadScreen';
import sharedToasterSubject from '../../services/shared.toasterSubject';
import { NavLink } from 'react-router-dom';
import SelectCard from '../Forms/Select.cardForm';
import InputCard from '../Forms/Input.cardForm';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh',
        padding: '1em 2em'
    }
};


interface FacturasProps extends BaseReact {
    currentUser: ProfileQL;
}

const monthOptions = [
    { value: moment().startOf('month').toDate().getTime(), label: moment().format('MMMM') },
    { value: moment().subtract(1, 'month').startOf('month').toDate().getTime(), label: moment().subtract(1, 'month').format('MMMM') },
    { value: moment().subtract(2, 'month').startOf('month').toDate().getTime(), label: moment().subtract(2, 'month').format('MMMM') },
]

function Facturas({ history, location, currentUser }: FacturasProps) {
    const [billList, setBillList] = useState<FiscalpopCFDI[]>([]);
    const [cancelModalOpen, setCancelModalOpen] = useState(false);
    const [cancelUuid, setCancelUuid] = useState('');
    const [dateRange, setDateRange] = useState(moment(Date.now()).startOf('month').toDate().getTime());
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [setCancel] = useMutation(graphqlSchema.FISCALPOP.CFDI.setBillToCancel, {
        onCompleted: ({ setBillToCancel }: { setBillToCancel: { folios: { estatus: number; uuid: string }[] } }) => {
            console.log('Canceled CFDI: ', setBillToCancel);
            // UseReducer to update object array
            for (const folio of setBillToCancel.folios) {
                const referBill = billList.find(bl => bl.uuid === folio.uuid);
                referBill.status = false;
            }
            // Breaks original array reference
            setBillList(JSON.parse(JSON.stringify(billList)));
            sharedToasterSubject.next({ type: 'confirm', message: 'Se canceló la factura' });
        },
        onError: (e) => {
            console.error('Error canceling cfdi: ', e);
            sharedToasterSubject.next({ type: 'error', message: 'Error al cancelar factura, comuníquese con soporte' });
        }
    })

    useEffect(() => {
        if (!!currentUser && !!currentUser.claroProfile) {
            setIsLoading(true);
            fetch(`https://api.fiscalpop.com/api/v1/cfdi/findBy/${currentUser.claroProfile.authToken}/${dateRange}/${moment(dateRange).endOf('month').toDate().getTime()}`)
                .then(async response => {
                    if (response.ok) {
                        const json = await response.json();
                        return json;
                    } else {
                        const err = await response.text();
                        throw err;
                    }
                })
                .then((cfdiResponse: FiscalpopCFDI[]) => {
                    console.log('CFDIs: ', cfdiResponse);
                    setBillList(cfdiResponse);
                    setIsLoading(false)
                })
                .catch(e => {
                    setIsLoading(false);
                })

        }
    }, [currentUser, dateRange]);

    const handleSearch = (value: string) => {
        setSearch(value.toUpperCase());
    }

    const requestCancelCfdi = (uuid: string) => {
        return () => {
            setCancelModalOpen(true);
            setCancelUuid(uuid);
        }
    }

    const cancelCFDI = () => {
        setCancelModalOpen(false);
        const uuid = cancelUuid;
        setCancel({
            variables: {
                uuid
            }
        })

    }


    const selectMonth = (value: number) => {
        setDateRange(value);
    }

    const renderCancelDialog = () => {
        return (
            <Modal
                isOpen={cancelModalOpen}
                onRequestClose={() => setCancelModalOpen(false)}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Cancelar Factura"
            >
                <div className="CancelWarning">
                    <h3>
                        Realmente quieres cancelar tu factura ?
                    </h3>
                    <p className="warning">{cancelUuid}</p>
                    <p>
                        Este UUID quedará cancelado pero podrás volver a facturar los pedidos en otro UUID.
                    </p>
                    <div className="actions">
                        <Button primary={true} handleClick={() => setCancelModalOpen(false)}>
                            <span>Regresar</span>
                        </Button>
                        <Button accent={true} handleClick={cancelCFDI}>
                            <span>Cancelar factura</span>
                        </Button>
                    </div>
                </div>
            </Modal>
        )
    }

    const renderNoBillsYet = () => {
        if (!isLoading && !!billList && !billList.length) {
            return (
                <div className='_isEmpty'>
                    <h4>
                        ¡Todavía no has generado tu primera factura!
                    </h4>
                    <p>
                        Cuando generes tus primeras facturas verás sus datos en esta lista, donde podrás descargarlas o cancelarlas
                    </p>
                </div>
            )
        } else {
            return null;
        }
    }

    return (
        <div id="Facturas">
            <Loading
                display={isLoading}
                relativePos={false}
            />
            <SectionTitleBar currentUser={currentUser} title="Revisar Facturas anteriores" />
            <div className="facturasContent">

                <div className='card'>
                    <div className="toReport show-md">
                        <NavLink to="/facturas/report">
                            <span>
                                Crear reporte
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                                <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z" />
                            </svg>
                        </NavLink>
                    </div>

                    <div className='searchBlock'>
                        <InputCard type="text" value={search} onChange={handleSearch} label={'Buscar por UUID'} placeholder="Buscar factura por UUID" />
                    </div>

                    <div className="filterChange">
                        <SelectCard value={dateRange} onChange={selectMonth} label={'Seleccionar Mes'} options={monthOptions} />
                        <div className="toReport">
                            <NavLink to="/facturas/report">
                                <span>
                                    Crear reporte
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                                    <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z" />
                                </svg>
                            </NavLink>
                        </div>
                    </div>
                    <div className="billList">
                        <div className="itemList">
                            <div className="itemEntry header">
                                <p className="_serieDate">
                                    Pedido(s) / Fecha
                                </p>
                                <p className="_serie">
                                    Pedido(s)
                                </p>
                                <p className="_client">
                                    Cliente / RFC
                                </p>
                                <p className="_uuid">
                                    UUID
                                </p>
                                <p className="_date">
                                    Fecha
                                </p>
                                <p>
                                    Status
                                </p>
                                <div></div>
                            </div>
                            {
                                billList.filter(b => !search ? true : b.uuid.includes(search)).sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()).map((cfdi, i) => {
                                    return (
                                        <FacturasEntry key={i} cfdi={cfdi} authToken={currentUser.claroProfile.authToken} requestCancelCfdi={requestCancelCfdi} />
                                    )
                                })
                            }

                            {renderNoBillsYet()}
                        </div>
                    </div>
                </div>
            </div>
            {renderCancelDialog()}
        </div>
    )
}

export default Facturas;