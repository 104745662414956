import React, { useState } from 'react';

import './Setup.rfcChange.scss';
import IconButton from '../Forms/IconButton';
import Input from '../Forms/Input';
import { FiscalpopProfile } from '../../models/Profile';
import { graphqlSchema } from '../../services/graphql.schema';
import { useMutation } from '@apollo/client';
import GraphQlClient from '../../services/graphql';
import sharedToasterSubject from '../../services/shared.toasterSubject';

function SetupRfcChange({ fiscalpopProfile }: { fiscalpopProfile: FiscalpopProfile }){

    const [rfc, setRfc] = useState(!!fiscalpopProfile && fiscalpopProfile.rfc ? fiscalpopProfile.rfc : '');
    const [nombre, setNombre] = useState(!!fiscalpopProfile && fiscalpopProfile.nombre ? fiscalpopProfile.nombre : '');

    const [setRFC] = useMutation(graphqlSchema.PROFILE.SETUP.setNameRfcChange, {
        onCompleted: ({ setNameRfcChange }) => {
            console.log('Updated NM RFC: ', setNameRfcChange);
            GraphQlClient.refreshGQLuser();
            sharedToasterSubject.next({ type: 'confirm', message: `Cambios aplicados correctamente` });
        },
        onError: (e) => {
            console.error('Error updating defaults: ', e);
        }
    })

    const setNewRFC = () => {
        setRFC({
            variables: {
                nombre: fiscalpopProfile.nombre !== nombre ? nombre : '',
                rfc: fiscalpopProfile.rfc !== rfc ? rfc : ''
            }
        })
    }

    return (
        <div className="card rfcChange" id="SetupRfcChange">
            <div className="title">
                <h4>Cambiar Razón Social o RFC </h4>
                <IconButton primary={true} handleClick={setNewRFC}>
                    <i className="material-icons">
                        save
                    </i>
                </IconButton>
            </div>
            <div className="rfcWrap">
                <Input type="text" value={nombre} onChange={setNombre} placeholder="Razón social" label={'Razón social'}/>
                <Input type="text" value={rfc} onChange={setRfc} placeholder="RFC de tu empresa" label={'RFC'}/>
            </div>
        </div>
    )
}

export default SetupRfcChange;