import { ClaroOrdersBinded } from './../models/Orders';
import { BillConceptos } from './../models/Factura';

export const ordersToConceptos = (orders: ClaroOrdersBinded[]) => {
    return orders.reduce((p, c) => {
        const valorUnitarioBase = c.order.totalpedido;
        const valorUnitario = valorUnitarioBase / 1.16;
        const entry: BillConceptos = {
            claveProdServ: '01010101',
            claveUnidad: 'ACT',
            descripcion: "venta",
            noIdentificacion: `${c.order.nopedido}`,
            valorUnitario: valorUnitario,
            cantidad: 1,
            impuestos: [{
                tasa: 0.16,
                type: 'iva',
                retencion: false
            }]
        }
        p.push(entry);
        return p;
    }, [] as BillConceptos[]);
} 