import { ApolloError } from "apollo-client";


const recursiveCleanup = (cleanupArray: any[], toClear: string, obj: any) => {
    if (typeof obj === 'object' && !!obj) {
        Object.keys(obj).forEach((k) => {
            if (k === toClear) {
                delete obj[k];
            } else if (typeof obj[k] === 'object' && !Array.isArray(obj[k])) {
                recursiveCleanup(cleanupArray, toClear, obj[k]);
            } else if (Array.isArray(obj[k])) {
                for (const e of obj[k]) {
                    recursiveCleanup(cleanupArray, toClear, e);
                }
            }
        })
    }
}


export const clearGqlTypename = (obj: any) => {
    const _obj = JSON.parse(JSON.stringify(obj));
    const TO_CLEAR = '__typename';
    const objectsToClear: any[] = [];
    recursiveCleanup(objectsToClear, TO_CLEAR, _obj);
    return _obj;
}


export function validateEmail(email: string) {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


export const formatStampingError = (e: ApolloError) => {
    if(e.graphQLErrors[0].extensions?.exception?.error?.err[0]?.MensajeIncidencia) {
        return e.graphQLErrors[0].extensions?.exception?.error?.err[0]?.MensajeIncidencia;
    } 
    return e.graphQLErrors[0].message;
}