import React, { useState, useRef } from 'react';

import './avatar.scss';
import { ProfileQL } from '../../models/Profile';

interface ProfileMenuProps {
    currentUser: ProfileQL;
    absolute?: true
}

function ProfileAvatar({ currentUser, absolute }: ProfileMenuProps) {
    const inputFileRef = useRef<HTMLInputElement>(null);

    const [newUrl, setNewUrl] = useState('');
    const [logoFileBase, setLogoFileBase] = useState<string | ArrayBuffer>('');

    const fiscalpopProfile = currentUser.fiscalpopProfile;
    //const randQuery = `?i=${Math.random().toString(32).split('.')[1]}`
    const randQuery = ``
    const defaultLogo = 'https://misventas-static.nyc3.digitaloceanspaces.com/LogoSmall.png'
    const clientLogo = newUrl || (!!fiscalpopProfile && fiscalpopProfile.logoUrl ? fiscalpopProfile.logoUrl : null);
    const _assignedLogo = !logoFileBase ? clientLogo : logoFileBase;
    const assignedLogo = logoFileBase ? logoFileBase : (_assignedLogo ? `${_assignedLogo}${randQuery}` : '');

    const getBase64 = (file: File): Promise<string | ArrayBuffer> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const handleLogoFile = (file: File) => {
        console.log('Handling logo file: ', file);
        getBase64(file)
            .then(base => {
                setLogoFileBase(base);
                submitLogoFile(file);
            })
    }

    const submitLogoFile = (file: File) => {
        console.log('Submitting logoFile: ', file);
        if (!file) {
            return;
        }
        console.log('AuthToken: ', fiscalpopProfile.authToken);
        const extension = file.type.split('/')[1];
        fetch(`https://api.fiscalpop.com/api/v1/clients/logo/${fiscalpopProfile.authToken}?extension=${extension}`, {
            method: 'POST',
            credentials: 'omit',
            body: file
        }).then(response => {
            if (response.status > 299) {
                throw response.statusText;
            }
            return response.json()
        })
            .then((body) => {
                const ranString = Math.random().toString(32).substring(2);
                setNewUrl(`${body.logoUrl}?id=${ranString}`);
                setLogoFileBase(null);
                console.log('Logo uploaded: ', body);
            })
            .catch(err => {
                console.error('Error uploading Logo: ', err);
            });
    }

    const selectFileToUpload = () => {
        inputFileRef.current.click();
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleLogoFile(event.target.files[0]);
    }
    return (
        <div id="_Avatar" className={`${!!absolute ? 'absolute' : ''}`}>
            <div className='changePicture' onClick={selectFileToUpload}>
                <span className="material-icons">
                    add_a_photo
                </span>
            </div>
            <div className="logoWrap">
                <input type="file"
                    ref={inputFileRef}
                    //accept={props.accept}
                    onChange={handleInputChange} />
            </div>
            {
                assignedLogo ?
                    <img src={assignedLogo as string} alt="Perfil usuario"  />
                    :
                    <img src={defaultLogo} alt="Perfil usuario"  />
            }
        </div>
    )
}

export default ProfileAvatar;

