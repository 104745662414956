import React, { useState } from 'react';

import './Setup.certUpload.scss';

import { FiscalPopClientStatus, FiscalpopProfile } from '../../models/Profile';
import GraphQlClient from '../../services/graphql';
import UploadButton from '../Forms/Input.buttonUpload';
import Button from '../Forms/Button';

function SetupUploadCert({ fiscalpopProfileStatus, fiscalpopProfile, highlight }: { fiscalpopProfileStatus: FiscalPopClientStatus, fiscalpopProfile: FiscalpopProfile, highlight: boolean }) {
    const [certFile, setCertFile] = useState<File>(null);
    const [certError, setCertError] = useState<string>('');
    const [cerIsUploading, setCerIsUploading] = useState(false);

    // UI
    const [highlightTitle] = useState(!!highlight && !fiscalpopProfileStatus.cerModulus);

    const handleCertFile = (file: File) => {
        setCertFile(file);
    }

    const submitCertFile = () => {
        if (!certFile) {
            return;
        }
        // Fill multiform data
        let fileBrowserSupport: File = null;
        try {
            fileBrowserSupport = new File([certFile], `${fiscalpopProfile.rfc}.cer`, { type: 'application/pkix-cert' });
        } catch (e) {
            let blobFile: any = new Blob([certFile], { type: 'application/pkix-cert' });
            blobFile.name = `${fiscalpopProfile.rfc}.cer`;
            fileBrowserSupport = blobFile as File;
        }
        const formData = new FormData();
        formData.append('upload', fileBrowserSupport);
        setCerIsUploading(true);

        fetch(`https://api.fiscalpop.com/api/v1/upload/cer/${fiscalpopProfile.authToken}`, {
            method: 'POST',
            credentials: 'omit',
            body: formData
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw response.text();
            }
        })
            .then((body) => {

                setCerIsUploading(false);
                GraphQlClient.recallCurrentUser();
                setCertError(null);
            })
            .catch(errProm => {
                errProm.then((err: string) => {

                    setCerIsUploading(false);
                    console.log('Cert submit err: ', err);
                    if (err.includes('no pertenece al RFC del cliente')) {
                        const _certErrorRFC = err.split(')')[0].split('(')[1].replace(' ', '');
                        console.log('certErrorRFC: ', _certErrorRFC);
                        setCertError(err);
                        // setCertErrorIsRfcChange(true);
                    } else {
                        setCertError(err);
                    }

                })
                    .catch(() => null as any);
            });
    }

    const onUpdateCert = () => {
        submitCertFile()
    }

    const renderCertInfo = () => {
        const notAfter = (new Date(fiscalpopProfileStatus.notAfter)).toJSON().split('T')[0];
        const notBefore = (new Date(fiscalpopProfileStatus.notBefore)).toJSON().split('T')[0];
        const notBeforeDate = new Date(fiscalpopProfileStatus.notBefore);
        const isNotUsable = Date.now() <= notBeforeDate.getTime();
        const modulusSame = !!fiscalpopProfileStatus && !!fiscalpopProfileStatus.modulusMatch;
        const missingKey = !!fiscalpopProfileStatus && !!fiscalpopProfileStatus.keyModulus;
        return (
            <div className="certInfo">
                <p className="serial">
                    <b>Número de serie del certificado:</b> <br className="show-mq1" />{fiscalpopProfileStatus.cerNumber}
                </p>
                <p className={`certState`}>
                    <b>Status: </b> <br className="show-mq1" />
                    <span className={`${!modulusSame ? 'warning' : ''}`}>{
                        !modulusSame ?
                            (
                                !missingKey ? 'Es necesario agregar la llave del CSD para usarse'
                                    :
                                    'El certificado no corresponde a la llave'
                            )
                            :
                            (isNotUsable ? <b className="warning">El certificado todavía no puede ser usado</b> : 'El certificado es válido')
                    }
                    </span>
                </p>
                <p className="dateStart">
                    <b>Inicio de uso del certificado</b> <span className="small">(yyyy-mm-dd)</span>: <br className="show-mq1" />{notBefore}
                </p>
                <p className="dateEnd">
                    <b>Caducidad del certificado</b> <span className="small">(yyyy-mm-dd)</span>: <br className="show-mq1" />{notAfter} <br />
                </p>
            </div>
        )
    }

    return (
        <div className="card" id="SetupUploadCert">
            <div className="fields">
                <div className="title">
                    <h4 className={`${highlightTitle ? 'highlightTitle' : ''}`}>
                        Certificado CSD
                        {!!fiscalpopProfileStatus && fiscalpopProfileStatus.modulusMatch ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" style={{ "fill": "#66cb66" }} />
                            </svg>
                            : ''
                        }
                    </h4>
                </div>
                <p>
                    El CSD es un par de archivos (Key y Cer) similares a la FIEL, si no la has generado antes, da click en el botón siguiente.
                </p>

                {!!fiscalpopProfileStatus && fiscalpopProfileStatus.cerNumber ? renderCertInfo() : ''}

                <div className="fileBlock">
                    <UploadButton placeholder='Certificado Digital (.cer)' label='Sube el certificado de tu CSD (.cer)' accept={'.cer'} onChange={handleCertFile} value={null} filenameTrim={31} hasError={!!certError} errorLabel={certError} disabled={cerIsUploading} />
                    <Button handleClick={onUpdateCert} disabled={!certFile} primary={true}>
                        <span>
                            {
                                cerIsUploading ?
                                    'Cargando...'
                                    : 'Actualizar'
                            }
                        </span>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default SetupUploadCert;