import React, { useState, FormEvent } from 'react';
import './Login.scss';
import { BaseReact } from '../../base.model';
import { Link, Redirect } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { graphqlSchema } from '../../services/graphql.schema';
import { ClaroProfile } from '../../models/Profile';
import GraphQlClient from '../../services/graphql';
import sharedToasterSubject from '../../services/shared.toasterSubject';
import { LogoBanner } from '../visuals/banner.logo';
import InputAnimated from './input/input';
import RecoverPassword from './LostPassword';


interface LoginProps extends BaseReact {


}

function Login({ history, location }: LoginProps) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');

    const [loginError, setLoginError] = useState('');

    const [registerRedirect, setRegisterRedirect] = useState(false);
    const [registering, setRegistering] = useState(false);


    const [loginAccount] = useMutation(graphqlSchema.PROFILE.login, {
        onCompleted: ({ login }: { login: { claroProfile: ClaroProfile, token: string } }) => {
            console.log('Login: ', login);
            // UseReducer to update object array
            setRegistering(false);
            if (!login.token) {
                return sharedToasterSubject.next({ type: 'error', message: `Contraseña o usuario equivocados` });
            }
            GraphQlClient.logSession(login.token)
                .then(d => {
                    console.log('Me Result: ', d);
                    setRegisterRedirect(true);
                });
        },
        onError: (e) => {
            console.error('Error Registration: ', e.graphQLErrors[0].message);
            setLoginError(e.graphQLErrors[0].message);
        }
    })

    const submitLogin = (e: FormEvent) => {
        e.preventDefault();
        if (!email || !password) {
            return;
        }
        setRegistering(true);
        loginAccount({
            variables: {
                login: {
                    email,
                    password
                }
            }
        })
    }


    return (
        <div id="Login">
            <div className="_loginContainer">
                <LogoBanner />

                <div className="_logoSelect">
                    <div className="_withReturn">
                        <a href="https://fiscalpop.com/login?type=ecommerce">
                            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                <path d="M26 13.5H1" stroke="#AFAFAF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M13.5 26L1 13.5L13.5 1" stroke="#AFAFAF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </a>
                        <div> <h2 className="gray">eCommerce </h2><h3>ClaroShop</h3></div>
                    </div>
                    <form onSubmit={submitLogin}>
                        <InputAnimated
                            type="text"
                            value={email}
                            label="Email"
                            onChange={setEmail}
                        />
                        <InputAnimated
                            type="password"
                            value={password}
                            label="Contraseña"
                            onChange={setPassword}
                        />
                        <Link to="/login?recover=true" >¿Olvidaste tu contraseña?</Link>
                        <div className="_actions">
                            {
                                (!!loginError ? <span>{loginError}</span> : <span></span>)
                            }
                            <button type="submit" disabled={!email || !password}>
                                {
                                    registering ? 'Entrando' :
                                        'Entrar'
                                }
                            </button>
                        </div>
                    </form>
                </div>
                <Link to="/register">
                    ¿No tienes cuenta? <b>Regístrate</b>
                </Link>
                <RecoverPassword location={location} history={history} />
                {
                    registerRedirect ?
                        <Redirect to="/" />
                        : ''
                }
            </div>
        </div>
    )
}

export default Login;