import React, { useState, useEffect } from 'react';
import { ClaroOrdersBinded } from '../../models/Orders';
import Input from '../Forms/Input';
import Checkbox from '../Forms/Checkbox';
import { numberToCurrencyString } from '../../services/formatting';
import Button from '../Forms/Button';
import { useMutation } from '@apollo/client';
import { graphqlSchema } from '../../services/graphql.schema';

import { Bill, BillConceptos, BillConceptTax } from '../../models/Factura';
import Select from '../Forms/Select';
import sharedToasterSubject from '../../services/shared.toasterSubject';
import { ProfileQL } from '../../models/Profile';
import GlobalSectionReportDownload from './Download.unbilledgroup.reporte';
import Pagination from '../Forms/Pagination';
import { ordersToConceptos } from '../../utils/global.ordersToConceptos';
import { clearGqlTypename } from '../../utils/formatting';

interface GlobalGroupingGroup {
    currentUser: ProfileQL,
    lugarExpedicion: string;
    label: string;
    serie: string;
    folio: number;
    group: ClaroOrdersBinded[];
    toggleSelect: (orderId: string) => (value: boolean) => void;
    unSelectedDict: { [key: string]: boolean };
    propagateStampedGroup: (orderIds: string[], uuid: string, lastDate: number) => void;
}

const FORMA_DE_PAGO_OPTIONS = [
    { label: 'Débito (04)', value: '04' },
    { label: 'Crédito (28)', value: '28' },
    { label: 'Intermediarios (31)', value: '31' },
    { label: 'Por definir (99)', value: '99' },
]

const Metodo_DE_PAGO_OPTIONS = [
    { label: 'PUE', value: 'PUE' },
    { label: 'PPD', value: 'PPD' },
]

const ENTRIES_PER_PAGE = 35;

function StampGlobalGroupingGroup({ currentUser, toggleSelect, unSelectedDict, label, serie, folio, group, lugarExpedicion, propagateStampedGroup }: GlobalGroupingGroup) {
    const [groupSerie, setGroupSerie] = useState(serie);
    const [groupFolio, setGroupFolio] = useState(folio);
    const [enabledOrders, setEnabledOrders] = useState<ClaroOrdersBinded[]>(group);
    const [tax, setTax] = useState(0);
    const [iepsTax, setIepsTax] = useState(0);

    const [subtotal, setSubtotal] = useState(0);
    const [animageGroup, setAnimateGroup] = useState(false);
    const [formaPagoAuto, setFormaPago] = useState('');
    const [formaPagoOverride, setFormaPagoOverride] = useState('');
    const [metodoPagoOverride, setMetodoPagoOverride] = useState<'PUE' | 'PPD'>('PUE');
    const [dateOverride, setDateOverride] = useState(0);

    const [cfdiConceptos, setCfdiConceptos] = useState<Bill['conceptos']>([]);

    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);

    // Submitting
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submmittedDate, setSubmittedDate] = useState(null);

    const [stampGlobal] = useMutation(graphqlSchema.FISCALPOP.CFDI.setGlobalBillToStamp, {
        onCompleted: ({ setGlobalBillToStamp }: { setGlobalBillToStamp: { UUID: string } }) => {
            console.log('GlobalStamp CFDI: ', setGlobalBillToStamp);
            // UseReducer to update object array
            setIsSubmitting(false);
            propagateStampedGroup(enabledOrders.map(o => o.order.nopedido), setGlobalBillToStamp.UUID, submmittedDate);
            sharedToasterSubject.next({ type: 'confirm', message: 'Se emitió la factura global correctamente' });
        },
        onError: (e) => {
            console.error('Error stamping global: ', e);
            setIsSubmitting(false);
            sharedToasterSubject.next({ type: 'error', message: 'Error al emitir la factura global' });
        }
    });

    useEffect(() => {
        /*
        const tax = group.filter(sales => !unSelectedDict[sales.order.nopedido]).reduce((p, c) => {
            const valorUnitarioBase = c.order.total_amount + (currentUser.claroProfile.chargeShippingConcept ? (c.order.shipping_cost || 0) : 0);
            p += (valorUnitarioBase / 1.16) * .16;
            return p;
        }, 0);
        const subtotal = group.filter(sales => !unSelectedDict[sales.order.nopedido]).reduce((p, c) => {
            const valorUnitarioBase = c.order.total_amount + (currentUser.claroProfile.chargeShippingConcept ? (c.order.shipping_cost || 0) : 0);
            p += (valorUnitarioBase) / 1.16;
            return p;
        }, 0);
        setTax(tax);
        setSubtotal(subtotal);
        */
        const _enabledGroup = group.filter(sales => !unSelectedDict[sales.order.nopedido]);
        setEnabledOrders(_enabledGroup);
        if (!animageGroup && !_enabledGroup.length) {
            setAnimateGroup(true);
        }
        const formaPago = '31';
        setFormaPago(formaPago);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unSelectedDict, group]);

    useEffect(() => {
        const conceptos = ordersToConceptos(enabledOrders);
        setCfdiConceptos(conceptos);
        const calculations = conceptos.reduce((p, c) => {
            const hasIeps = c.impuestos.find(tax => tax.type === 'ieps');
            const hasIva = c.impuestos.find(tax => tax.type === 'iva');

            const subt = c.valorUnitario;
            const ieps = !hasIeps ? 0 : c.valorUnitario * hasIeps.tasa;
            const iva = (subt + ieps) * hasIva.tasa;
            p.subtotal += c.valorUnitario;
            p.ieps += ieps;
            p.iva += iva;
            return p
        }, { subtotal: 0, ieps: 0, iva: 0 });
        const { iva, ieps, subtotal } = calculations;
        setTax(Math.round(iva * 100) / 100);
        setIepsTax(Math.round(ieps * 100) / 100);
        setSubtotal(Math.round(subtotal * 100) / 100);

    }, [enabledOrders])




    const submitGroupStamp = () => {
        setIsSubmitting(true);
        const cfdi: Bill = {
            serie: groupSerie,
            folio: `${groupFolio}`,
            formaPago: formaPagoOverride || formaPagoAuto,
            metodoPago: metodoPagoOverride || 'PUE',
            lugarExpedicion,
            tipoDeComprobante: 'I',
            receptor: {
                nombre: 'PUBLICO EN GENERAL',
                rfc: 'XAXX010101000',
                usoCFDI: 'S01',
                regimen: '616',
                zip: currentUser.fiscalpopProfile.lugarExpedicion
            },
            conceptos: cfdiConceptos
        }
        if (dateOverride) {
            cfdi.fecha = (new Date(Date.now() - Math.floor(dateOverride * 24 * 60 * 60 * 1000))).toJSON();
        }

        const lastDate = enabledOrders.map((o) => new Date(o.order.fechaautorizacion).getTime()).sort((a, b) => b - a)[0];
        console.log('CFDI To STAMP: ', cfdi);
        setSubmittedDate(lastDate);
        stampGlobal({
            variables: {
                orderIds: enabledOrders.map(o => o.order.nopedido),
                cfdi: clearGqlTypename(cfdi),
                lastDate
            }
        })
    }

    const avoidFolioToBeRepeated = (_folio: number) => {
        if (_folio < 1) {
            _folio = 1;
            setGroupFolio(_folio);
        } else {
            setGroupFolio(_folio);
        }
    }

    const dateOverrideOptions = [
        { label: 'Hoy', value: 0 },
        { label: 'Ayer', value: 1 },
        { label: 'Hace dos dias', value: 2 },
        { label: 'Hace tres dias', value: 2.9 },
    ];

    // OLD PROCESS TO FILTER
    const fpStatus = !!currentUser.fiscalpopProfileStatus ? currentUser.fiscalpopProfileStatus : null;
    const modulusMatch = !fpStatus ? null : fpStatus.modulusMatch;
    /*
    const filteredGroup = !search ? group : group.filter(g => `${g.order.nopedido}`.includes(search));
    const filteredPaginationGroup = !search ? enabledOrders : enabledOrders.filter(g => `${g.order.nopedido}`.includes(search));
    const conceptosDisplayed = filteredGroup.filter((o, i) => (i >= (ENTRIES_PER_PAGE * (page - 1))) && (i < (ENTRIES_PER_PAGE * (page))))
    */
    const filteredGroup = !search ? cfdiConceptos : cfdiConceptos.filter(g => `${g.noIdentificacion}`.includes(search));
    const filteredPaginationGroup = !search ? cfdiConceptos : cfdiConceptos.filter(g => `${g.noIdentificacion}`.includes(search));
    const conceptosDisplayed = filteredGroup.filter((o, i) => (i >= (ENTRIES_PER_PAGE * (page - 1))) && (i < (ENTRIES_PER_PAGE * (page))))

    return (
        <div className={`card billGrouping ${!enabledOrders.length ? 'empty' : (animageGroup ? 'full' : '')}`}>
            <div className="cardTitle">
                <h3>
                    {label}
                </h3>
            </div>
            <div className="groupContent">
                <div className="groupHeader">
                    <Input type="text" value={groupSerie} onChange={setGroupSerie} label={"Serie"} placeholder="Serie de la factura" />
                    <Input type="number" value={groupFolio} onChange={avoidFolioToBeRepeated} label={"Folio"} placeholder="Folio de la factura" />
                    <Select options={FORMA_DE_PAGO_OPTIONS} value={formaPagoOverride || formaPagoAuto} label={'Forma de pago'} onChange={setFormaPagoOverride} />
                    <Select options={Metodo_DE_PAGO_OPTIONS} value={metodoPagoOverride} label={'Metodo de pago'} onChange={setMetodoPagoOverride} />
                    <Select options={dateOverrideOptions} value={dateOverride} label={'Fecha a emitir'} onChange={setDateOverride} />
                </div>
                <div className="groupConcepts">
                    <div className="orderConcepts header">
                        <div></div>
                        <p>
                            Producto
                        </p>
                        <p className="precio">
                            Precio unitario <br />
                            (x Cantidad)
                        </p>
                        <p className="mq1">
                            Clave (SAT)
                        </p>
                        <p className="mq2">
                            Impuestos
                        </p>
                    </div>
                    <Input label="Buscar número de orden" type="text" value={search} onChange={setSearch} placeholder={'Buscar ID'} />
                    {
                        conceptosDisplayed.map((concepto, j) => (
                            <StampGlobalGroupingGroupConcepto chargeShipping={false} isHidden={unSelectedDict[concepto.noIdentificacion.split('.')[0]]} key={concepto.noIdentificacion} sales={concepto} toggleSelect={toggleSelect} />

                        ))
                    }
                    <Pagination onPage={setPage} pages={Math.ceil(filteredPaginationGroup.length / ENTRIES_PER_PAGE)} page={page} />
                </div>
                <div className="totals">
                    <GlobalSectionReportDownload billList={enabledOrders} label={label} chargeShipping={false} />
                    <div className="col">
                        <p className="label"><b>Subtotal:</b></p>
                        <p>$ {numberToCurrencyString(subtotal)}</p>
                        <p className="label"><b>IVA:</b></p>
                        <p>$ {numberToCurrencyString(tax)}</p>
                        {
                            iepsTax ?
                                <>
                                    <p className="label"><b>IEPS:</b></p>
                                    <p>$ {numberToCurrencyString(iepsTax)}</p>
                                </>
                                : ''
                        }
                        <p className="label"><b>Total:</b></p>
                        <p>$ {numberToCurrencyString(subtotal + tax)}</p>
                    </div>
                </div>
                <div className="stampAction">
                    <Button primary={true} handleClick={submitGroupStamp} disabled={isSubmitting || !modulusMatch}>
                        {!modulusMatch ? <span>Necesita subir su CSD para Facturar</span> : (isSubmitting ? <span>Facturando, espere...</span> : <span>Facturar Grupo</span>)}
                    </Button>
                </div>
            </div>
        </div>
    )
}

interface GlobalGroupingGroupConcept {
    sales: BillConceptos;
    toggleSelect: (orderId: string) => (value: boolean) => void;
    isHidden: boolean;
    chargeShipping: boolean;
}


function StampGlobalGroupingGroupConcepto({ isHidden, sales, toggleSelect, chargeShipping }: GlobalGroupingGroupConcept) {
    const [hiddenTansition, setHideTransition] = useState(isHidden);

    useEffect(() => {
        setTimeout(() => {
            setHideTransition(isHidden);
        }, 400)
    }, [isHidden]);

    if (isHidden && isHidden === hiddenTansition) {
        return <div></div>
    }

    // RE-assign charge Shipping for this
    //const valorUnitario = !chargeShipping ? sales.order.total_amount : sales.order.total_amount + (sales.order.shipping_cost || 0);
    const valorUnitario = sales.valorUnitario;

    const formatTaxing = (tax: BillConceptTax[]) => {
        return tax.map(t => `${t.type.toUpperCase()} ${t.tasa * 100}%`).join(', ');
    }

    return (
        <div className={`orderConcepts ${isHidden ? 'hidden' : (isHidden === false ? 'shown' : '')}`}>
            <Checkbox label="" value={!isHidden} onChange={toggleSelect(sales.noIdentificacion.split('.')[0])} />
            <p>
                venta <span>(id: {sales.noIdentificacion})</span>
            </p>
            <p className="precio">
                $ {numberToCurrencyString(valorUnitario)}
                {
                    /*
                    chargeShipping && (sales.order.shipping_cost || 0) ?
                        <span className="_withShipping">(Envío incluido  $ {numberToCurrencyString((sales.order.shipping_cost || 0) / 1.16)})</span>
                        : ''
                    */
                }
            </p>
            <p className="mq1">
                01010101
            </p>
            <p className="mq2">
                {formatTaxing(sales.impuestos)}
            </p>
        </div>
    )
}

export default StampGlobalGroupingGroup;