import React from 'react';
import { numberToCurrencyString } from '../../../services/formatting';

const TIPO_FIELD = 'json.@.TipoDeComprobante'.split('.');

function ReporteEntryTotals({ billList, fields }: { billList: any[], fields: { label: string, value: string }[] }) {

    return (
        <tr className="__reporteTotals">
            {
                fields.map((fv) => {
                    const f = fv.value;
                    if (fv.label === 'Subtotal' || fv.label === 'Descuento' || fv.label === 'Impuestos' || fv.label === 'Total') {
                        const value = billList.filter(b => b.status).reduce((_p, factura) => {
                            const billIsRefund = TIPO_FIELD.reduce((p: any, c: any) => p[c], factura) === 'E';
                            return _p += billIsRefund ? -parseFloat(f.split('.').reduce((p: any, c: any) => p[c], factura) || 0) : parseFloat(f.split('.').reduce((p: any, c: any) => p[c], factura) || 0);
                        }, 0)
                        return (
                            <td key={f} className={`_${fv.label}`}>$ {numberToCurrencyString(parseFloat(value || '0'))}</td>
                        )
                    }
                    return (
                        <td key={f} className={`_${fv.label}`}></td>
                    )
                })
            }
        </tr>
    )

}

export default ReporteEntryTotals;