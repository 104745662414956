import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { graphqlSchema } from '../../services/graphql.schema';
import GraphQlClient from '../../services/graphql';

import './Setup.claves.scss';

import IconButton from '../Forms/IconButton';
import { ClaroProfile } from '../../models/Profile';
import sharedToasterSubject from '../../services/shared.toasterSubject';
import Input from '../Forms/Input';

function SetupClaroClaves({ claroProfile }: { claroProfile: ClaroProfile }) {
    const [publicKey, setPublicKey] = useState(claroProfile.publicKey);
    const [privateKey, setPrivateKey] = useState(claroProfile.privateKey);
    const [setKeys] = useMutation(graphqlSchema.PROFILE.SETUP.setClaroApi, {
        onCompleted: ({ setClaroApi }) => {
            console.log('Updated Defaults: ', setClaroApi);
            GraphQlClient.modifyClientMLprofile(setClaroApi);
            sharedToasterSubject.next({ type: 'confirm', message: `Se cambió tus llaves de ClaroShop correctamente` });
        },
        onError: (e) => {
            console.error('Error updating defaults: ', e);
        }
    })
    
  
    const saveDefaults = () => {
        console.log('[SETUP - DEFAULTS] defaults: ', { publicKey, privateKey })
        setKeys({
            variables: {
                apiKeys: {
                    publicKey,
                    privateKey
                }
            }
        })
    }

    return (
        <div className="card defaultsChange" id="SetupClaroClaves">
            <div className="title">
                <h4>Llaves API ClaroShop</h4>
                <IconButton primary={true} disabled={!privateKey || !privateKey} handleClick={saveDefaults}>
                    <i className="material-icons">
                        save
                    </i>
                </IconButton>
            </div>
            <div className="defaultWrap">
                <Input placeholder='Llave pública de ClaroShop' label='Llave Pública ClaroShop' type='text' onChange={setPublicKey} value={publicKey} />
                <Input placeholder='Llave privada de ClaroShop' label='Llave Privada ClaroShop' type='text' onChange={setPrivateKey} value={privateKey} />

            </div>
        </div>
    )
}

export default SetupClaroClaves;