export const REGIMEN_OPTIONS = [
    { value: '601', label: 'General de Ley Personas Morales', secondLabel: 'Personas Morales' },
    { value: '603', label: 'Personas Morales con Fines no Lucrativos', secondLabel: 'Fines no Lucrativos' },
    { value: '605', label: 'Sueldos y Salarios e Ingresos Asimilados a Salarios', secondLabel: 'Asalariados' },
    { value: '606', label: 'Arrendamiento', secondLabel: 'Arrendamiento' },
    { value: '607', label: 'Régimen de Enajenación o Adquisición de Bienes', secondLabel: 'Enajenación de Bienes' },
    { value: '608', label: 'Demás ingresos', secondLabel: 'Demás ingresos' },
    { value: '610', label: 'Residentes en el Extranjero sin Establecimiento Permanente en México', secondLabel: 'Residentes en Extranjero' },
    { value: '611', label: 'Ingresos por Dividendos (socios y accionistas)', secondLabel: 'Dividendos' },
    { value: '612', label: 'Personas Físicas con Actividades Empresariales y Profesionales', secondLabel: 'Actividad Empresarial' },
    { value: '614', label: 'Ingresos por intereses', secondLabel: 'Ingresos por Intereses' },
    { value: '615', label: 'Régimen de los ingresos por obtención de premios', secondLabel: 'Obtención Premios' },
    { value: '616', label: 'Sin obligaciones fiscales', secondLabel: 'Sin Obligaciones' },
    { value: '620', label: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos', secondLabel: 'Sociedades Cooperativas' },
    { value: '621', label: 'Incorporación Fiscal', secondLabel: 'Incorporación Fiscal' },
    { value: '622', label: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras', secondLabel: 'Actividad Agricola' },
    { value: '623', label: 'Opcional para Grupos de Sociedades', secondLabel: 'Opcional Grupos Sociedades' },
    { value: '624', label: 'Coordinados', secondLabel: 'Coordinados' },
    { value: '625', label: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas', secondLabel: 'Plataformas Tecnológicas' },
    { value: '626', label: 'Régimen Simplificado de Confianza', secondLabel: 'RESICO' },
].map(r => ({
    value: r.value,
    label: `(${r.value}) ${r.secondLabel}`
}));