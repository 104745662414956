import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";


import './Input.cardForm.scss';

interface InputProps {
    onChange?: Function;
    onBlur?: Function;
    placeholder?: string;
    label?: string;
    value: string | number;
    step?: number;
    type: 'number' | 'text' | 'date' | 'password' | 'month';
    accept?: string;
    disabled?: boolean;
    hasError?: boolean;
    errorLabel?: string;
    min?: string | number;
    max?: string | number;
    isCurrency?: boolean;
    isPercent?: boolean;
    isDecimalAsPercent?: boolean;
    filenameTrim?: number;
    dateFormat?: string;
    icon?: string;
}

const InputCard = React.forwardRef((props: InputProps, ref: React.Ref<HTMLButtonElement>) => {
    const selectRef = React.createRef<HTMLInputElement>();

    const [active, setActive] = useState(false);

    const activate = () => {
        setActive(true);
    }

    const deactivate = () => {
        setActive(false);
        if (!!props.onBlur) {
            props.onBlur(props.value);
        }
    }

    const changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.type === 'number') {
            if (props.onChange) {
                if (!!props.isDecimalAsPercent) {
                    // Value is masked as 0.35 decimal to 35% percent
                    console.log('INPOUT parseFloat(event.target.value): ', parseFloat(event.target.value));
                    props.onChange(parseFloat(event.target.value) / 100);
                } else {
                    props.onChange(parseFloat(event.target.value));
                }
            }
        } else if (props.type === 'text' || props.type === 'date' || props.type === 'password' || props.type === 'month') {
            if (props.onChange) {
                props.onChange(event.target.value);
            }
        }
    }

    const changeDateValue = (date: Date) => {
        console.log('Change Date Value:  ', date);
        props.onChange(moment(date).format('YYYY-MM-DD'));
    }

    const classInput = `inputWrapper ${props.icon ? 'icon' : ''} ${props.disabled ? 'disabled' : ''} ${props.type === 'date' ? 'date' : ''} ${active ? 'active' : ''} ${props.hasError ? 'error' : ''}`;
    return (
        <div className={`inputCardBlock ${props.type === 'date' ? 'dateType' : ''} ${active ? 'active' : ''} ${props.disabled ? 'disabled' : ''}  ${props.hasError ? 'error' : ''}`}>
            {
                !!props.label ?
                    <label>
                        {props.label}
                    </label>
                    : <label></label>
            }
            <div className={`${classInput}${props.isCurrency && !props.isPercent ? 'currency' : ''} ${props.isPercent && !props.isCurrency ? 'percent' : ''}`} >
                {
                    props.icon ?
                        <i className="material-icons">
                            {props.icon}
                        </i>
                        : null
                }
                {
                    props.step && props.type === 'number' ?
                        <input type={props.type}
                            readOnly={!props.onChange}
                            ref={selectRef}
                            value={!!props.isDecimalAsPercent ? Math.round((props.value as number) * 10000000) / 100000 : props.value}
                            step={!!props.isDecimalAsPercent ? (props.step as number) * 100 : props.step}
                            min={props.min || props.min === 0 ? (!!props.isDecimalAsPercent ? (props.min as number) * 100 : props.min) : null}
                            max={(!!props.isDecimalAsPercent ? (props.max as number) * 100 : props.max) || null}
                            onFocus={activate} onBlur={deactivate}
                            placeholder={props.placeholder || ''} onChange={changeValue}
                            disabled={!!props.disabled} />
                        :
                        (props.type === 'date' ?
                            <DatePicker
                                selected={new Date(props.value)}
                                onChange={changeDateValue}
                                className="input-date"
                                dateFormat="dd MMMM yyyy"
                                locale="es"
                            />
                            :
                            <input type={props.type}
                                readOnly={!props.onChange}
                                ref={selectRef}
                                value={props.value}
                                min={props.min || props.min === 0 ? props.min : null}
                                max={props.max || null}
                                step={(props.isCurrency && !props.step ? 'any' : null)}
                                onFocus={activate} onBlur={deactivate}
                                placeholder={props.placeholder || ''} onChange={changeValue}
                                disabled={!!props.disabled} />)


                }
            </div>
            <div className="errorText">
                {
                    props.hasError ?
                        <span className="errorText">{props.errorLabel}</span>
                        : ''
                }
            </div>
        </div>
    )

})

export default InputCard;