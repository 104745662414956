import React, { FormEvent, useState } from 'react';
import Modal from 'react-modal';

import './Setup.fiscalpop.dialog.scss';
import Input from '../Forms/Input';
import Loading from '../Animations/loadScreen';
import { useQuery } from '@apollo/client';

import {graphqlSchema} from '../../services/graphql.schema';
import AutoComplete from '../Forms/Autocomplete';
import Button from '../Forms/Button';
import GraphQlClient from '../../services/graphql';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        border:'none',
        boxShadow: '1px 1px 3px 0px rgba(0, 0, 0, 0.5)',
        transform: 'translate(-50%, -50%)',
        overflow:'visible'
    }
};
Modal.setAppElement('#root');

const ZIP_REGEX = /\b[0-9]{5}\b/

function FiscalpopDialog({modalIsOpen}:{modalIsOpen:boolean}) {
    useQuery(graphqlSchema.FISCALPOP.REGIMEN.getRegimenFiscal, {
        onCompleted: ({getRegimenFiscal}: {getRegimenFiscal:{clave:string, descripcion:string}[]}) => {
            console.log('onRegimen: ', getRegimenFiscal);
            setRegimenOptions(getRegimenFiscal.map(r => ({label: `(${r.clave}) - ${r.descripcion}`, value: r.clave})));
        }
    })
    const [rfc, _setRfc] = useState('');
    const [regimenFiscal, setRegimenFiscal] = useState('');
    const [nombre, setNombre] = useState('');
    const [lugarExpedicion, setLugarExpedicion] = useState('');

    // Errors
    const [lugarExpTested, setLugarExpTested] = useState(false)
    const [regimenTested, setRegimenTest] = useState(false)

    // options
    const [regimenOptions, setRegimenOptions] = useState<{label:string, value:string}[]>([]);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState('');

    const onSubmit = (e:FormEvent) => {
        e.preventDefault();
        if(submitting){
            return;
        }
        setSubmitting(true);
        GraphQlClient.submitFiscalpopClient({rfc, regimenFiscal, nombre, lugarExpedicion})
        .then((d) => {
            setError('');
            setSubmitting(false);
        })
        .catch(e => {
            console.error('Fiscalpop Submittion error.message: ', e.message);
            setSubmitting(false);
            setError(e.message);
        })
    }

    const setRfc = (rfc:string) => {
        _setRfc(rfc.toUpperCase())
    }

    const testZip = () => {
        if(lugarExpedicion) {
            setLugarExpTested(true);
        }
    }

    const testRegimen = () => {
        if(regimenFiscal) {
            setRegimenTest(true);
        }
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            style={customStyles}
            contentLabel="FiscalPOP Modal"
        >
            <div id="_FiscalpopDialog">
                <h4>
                    Configura tu emisor
                </h4>
            </div>
            <form id="FiscalpopDialog" onSubmit={onSubmit}>
                <div className="fields">
                    <Input value={rfc} type="text" onChange={setRfc} placeholder="Tu RFC" label="RFC"/>
                    <Input value={nombre} type="text" onChange={setNombre} placeholder="Tu Razón social" label="Razón social"/>
                    <Input value={lugarExpedicion} type="text" hasError={lugarExpTested && !ZIP_REGEX.test(lugarExpedicion)} onChange={setLugarExpedicion} onBlur={testZip} placeholder="C.P. de tu domicilio fiscal" label="Lugar de expedición (Código postal)"/>
                    <AutoComplete type="text" value={regimenFiscal} hasError={regimenTested && !regimenOptions.find(rO => rO.value === regimenFiscal)} onChange={setRegimenFiscal} onBlur={testRegimen} placeholder="Tu régimen fiscal" label="Regimen fiscal" options={regimenOptions} displayLabel={true}/>
                </div>
                <div className="actions">
                    {
                        !submitting ?
                        <p className="error">
                            {error}
                        </p>
                        : <Loading display={true} relativePos={true} svgWidth={'24'} svgHeight={'24'}/>
                    }
                    <Button primary={true} type={'submit'} disabled={!(rfc && nombre && lugarExpedicion && regimenFiscal) || submitting}><span>Guardar</span></Button>
                </div>
            </form>
        </Modal>
    )
}

export default FiscalpopDialog;