import React from 'react';
import { ProfileQL } from '../../models/Profile';
import ItemMenu from '../Forms/menu/MenuComponent';
import GraphQlClient from '../../services/graphql';

import './Menu.selector.scss';
import AccountSwitch from '../components/accountSwitch';
import ConnectAccountsDialog from '../components/connectAccounts.dialog';


function MenuAccountSelector({ currentUser }: { currentUser: ProfileQL }) {
    const rfcState = !!currentUser.fiscalpopProfile ? currentUser.fiscalpopProfile.rfc : 'Sin RFC';

    const closeSession = () => {
        GraphQlClient.logOut();
    }

    const rfcAccount = () => {
        return (
            <div className={`_menuRfcAccount ${currentUser.claroProfile.linkerId ? 'accounts' : 'accounts'}`}>
                <div className="_rfcWrap">
                    <p className="_rfc">
                        {rfcState}
                    </p>
                </div>
                <i className="material-icons">
                    expand_more
                </i>
            </div>
        )
    }

    return (
        <div className="__menuSelect">
            <ItemMenu triggerContent={rfcAccount()} noPadding={true}>
                <AccountSwitch currentUser={currentUser} />
                <div className="_actionsAccount">
                    <ConnectAccountsDialog currentUser={currentUser} />
                    <div className="_act warn" onClick={closeSession}>
                        <p><b>Cerrar sessión</b></p>
                    </div>
                </div>
            </ItemMenu>
        </div>
    )
}

export default MenuAccountSelector;