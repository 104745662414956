import React, { useState } from 'react';

import './Setup.keyUpload.scss';
import Input from '../Forms/Input';
import { FiscalPopClientStatus, FiscalpopProfile } from '../../models/Profile';
import GraphQlClient from '../../services/graphql';
import sharedToasterSubject from '../../services/shared.toasterSubject';
import UploadButton from '../Forms/Input.buttonUpload';
import Button from '../Forms/Button';

function SetupKeyUpload({ fiscalpopProfileStatus, fiscalpopProfile, highlight }: { fiscalpopProfileStatus: FiscalPopClientStatus, fiscalpopProfile: FiscalpopProfile, highlight: boolean }) {

    const [keyFile, setKeyFile] = useState<File>(null);
    const [keyPassword, setKeyPassword] = useState('');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [keyError, setKeyError] = useState('');
    const [keyIsUploading, setKeyIsUploading] = useState(false);

    // UI
    const [highlightTitle] = useState(!!highlight && !fiscalpopProfileStatus.keyModulus);

    const submitKeyFile = () => {
        if (!keyPassword || !keyFile) {
            return;
        }
        // Fill multiform data
        let fileBrowserSupport: File = null;
        try {
            fileBrowserSupport = new File([keyFile], `${fiscalpopProfile.rfc}.key`, { type: 'application/pkcs8' });
        } catch (e) {
            let blobFile: any = new Blob([keyFile], { type: 'application/pkcs8' });
            blobFile.name = `${fiscalpopProfile.rfc}.key`;
            fileBrowserSupport = blobFile as File;
        }
        const formData = new FormData();
        formData.append('upload', fileBrowserSupport);
        formData.append('password', keyPassword);
        setKeyIsUploading(true);
        fetch(`https://api.fiscalpop.com/api/v1/upload/key/${fiscalpopProfile.authToken}`, {
            method: 'POST',
            credentials: 'omit',
            body: formData
        }).then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const errText = await response.text();
                throw errText;
            }
        })
            .then((body) => {
                setKeyIsUploading(false);
                setKeyError('');
                setKeyFile(null);
                setKeyPassword('');
                GraphQlClient.recallCurrentUser();
                console.log('Key upload body: ', body);
            })
            .catch(err => {
                setKeyIsUploading(false);
                setKeyError(err);
                console.error('Key Upload error: ', err);
                sharedToasterSubject.next({ type: 'error', message: err });
            });
    }

    const onUpdateKey = () => {
        if (!keyPassword) {
            return
        }
        submitKeyFile()
    }

    const handleKeyFile = (file: File) => {
        setKeyFile(file);
    }

    const handleKeyPassword = (password: string) => {
        setKeyPassword(password);
    }

    const renderKeyInfo = () => {
        const modulusSame = !!fiscalpopProfileStatus.modulusMatch;
        const hasCer = !!fiscalpopProfileStatus.cerModulus;
        const hasKey = !!fiscalpopProfileStatus.keyModulus;
        return (
            <div className="keyInfo">
                <p className="keyState">
                    <b>Status: </b> <br />
                    <span className={`${!modulusSame ? (hasKey ? 'incomplete' : 'warning') : ''}`}>{
                        !modulusSame ?
                            (
                                !hasCer ? 'Llave cargada, Es necesario agregar el certificado para usarse'
                                    :
                                    'El certificado no corresponde a la llave'
                            )
                            :
                            'La llave es válida'
                    }
                    </span>
                </p>
            </div>
        )
    }

    return (
        <div className="card" id="SetupKeyUpload">
            <div className="fields">
                <div className="title">
                    <h4 className={`${highlightTitle ? 'highlightTitle' : ''}`}>
                        Llave CSD
                        {fiscalpopProfileStatus.modulusMatch ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" style={{ "fill": "#66cb66" }} />
                            </svg>
                            : ''
                        }
                    </h4>
                </div>
                {fiscalpopProfileStatus.keyModulus ? renderKeyInfo() : ''}


                <div className="fileBlock noMargin">
                    <UploadButton placeholder='Llave de tu CSD (.key)' label='Sube tu Llave CSD (.key)' accept={'.key'} onChange={handleKeyFile} value={null} filenameTrim={31} disabled={keyIsUploading} />
                </div>
                <div className="fileBlock noMargin second">
                    <Input placeholder='Contraseña de tu llave' label='Contraseña de tu llave' type='text' onChange={handleKeyPassword} value={keyPassword} />
                    <Button handleClick={onUpdateKey} disabled={!keyFile || !keyPassword} primary={true}>
                        <span>
                            {
                                keyIsUploading ?
                                    'Cargando...'
                                    : 'Actualizar'
                            }
                        </span>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default SetupKeyUpload;