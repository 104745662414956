import React from 'react';
import { numberToCurrencyString } from '../../../services/formatting';

const TIPO_FIELD = 'json.@.TipoDeComprobante'.split('.');
const SERIE_FIELD = 'json.@.Serie'.split('.');

function ReporteEntry({ factura, fields}: { factura: any, fields: {label:string, value:string}[]}) {
    const billIsRefund = TIPO_FIELD.reduce((p: any, c: any) => p[c], factura) === 'E';
    const billIsGlobal = SERIE_FIELD.reduce((p: any, c: any) => p[c], factura) === 'GB' || SERIE_FIELD.reduce((p: any, c: any) => p[c], factura) === 'GB-AMAZON';
    const isCancelled = !factura.status;
    return (
        <tr className={`__reporteEntry ${billIsRefund ? '_refund' : ''} ${billIsGlobal ? '_global' : ''}`}>
            {
                fields.map((fv) => {
                    const f = fv.value;
                    const value = f.split('.').reduce((p: any, c: any) => p[c], factura);
                    if(fv.label === 'Estatus'){
                        return (
                            <td key={f} className={`_${fv.label} ${value ? '' : 'Cancelada'}`}>{value ? 'Activa' : 'Cancelada'}</td>
                        )
                    }
                    if(fv.label === 'Subtotal' || fv.label === 'Descuento' || fv.label === 'Impuestos' || fv.label === 'Total'){
                        if(isCancelled){
                            return (
                                <td key={f} className={`_${fv.label} NoOp`}>($ {numberToCurrencyString(billIsRefund ? -parseFloat(value || '0') : parseFloat(value || '0'))})</td>
                                )
                        }else {
                            return (
                                <td key={f} className={`_${fv.label}`}>$ {numberToCurrencyString(billIsRefund ? -parseFloat(value || '0') : parseFloat(value || '0'))}</td>
                                )
                            }
                    }
                    if(fv.label === 'Razón social'){
                        return (
                            <td key={f} className={`_${fv.label}`}>{value.substring(0, 38)}</td>
                        )
                    }
                    return (
                        <td key={f} className={`_${fv.label}`}>{value}</td>
                    )
                })
            }
        </tr>
    )

}

export default ReporteEntry;