import { gql } from 'apollo-boost';


export const graphqlSchema = {
    PROFILE: {
        me: gql`
        query getMe{
            me {
                claroProfile {
                    tokensSet
                    companyName
                    cnktId
                    linkerId
                    authToken
                    email
                    emailValid
                    createdAt
                    billGlobalDay
                    billGlobalDayEnabled
                    defaultClaveSatProdServ
                    defaultUnidadSat
                    lastGlobalBill
                    sendOnShipping
                    privateKey
                    publicKey
                }
                fiscalpopProfile {
                    rfc
                    regimenFiscal
                    nombre
                    isProduction
                    authToken
                    lugarExpedicion
                    logoUrl
                }
                fiscalpopProfileStatus {
                    authToken
                    modulusMatch
                    cerModulus
                    cerNumber
                    notAfter
                    notBefore
                    keyModulus
                }
                billing {
                    userId
                    lastPayment
                    lastPaymentId
                    balance
                    enabled
                    isClusterType
                    clusterName
                }
            }
        }`,
        login: gql`
        mutation Login($login:AccountLogin!) {
            login(login: $login){
                claroProfile {
                    _id
                    companyName
                    cnktId
                    authToken
                    tokensSet
                    linkerId
                    email
                    emailValid
                    createdAt
                    billGlobalDay
                    billGlobalDayEnabled
                    defaultClaveSatProdServ
                    defaultUnidadSat
                    lastGlobalBill
                    privateKey
                    publicKey
                }
                token
            }
        }`,
        passwordReset: gql`
        mutation PasswordReset($token:String!, $newPassword: String!) {
            passwordReset(token: $token, newPassword: $newPassword){
                claroProfile {
                    _id
                    tokensSet
                    companyName
                    cnktId
                    linkerId
                    authToken
                    email
                    emailValid
                    createdAt
                    billGlobalDay
                    billGlobalDayEnabled
                    defaultClaveSatProdServ
                    defaultUnidadSat
                    lastGlobalBill
                    privateKey
                    publicKey
                }
                token
            }
        }`,
        requestPasswordReset: gql`
        mutation RequestPasswordReset($email: String!){
            requestPasswordReset(email:$email)
        }`,
        validateToken: gql`
        mutation ValidateToken($token: String!){
            validateToken(token:$token)
        }`,
        SETUP: {
            registerAccount: gql`
            mutation RegisterAccount($account: AccountRegistration!) {
                registerAccount(account: $account){
                    claroProfile {
                        _id
                        tokensSet
                        companyName
                        cnktId
                        linkerId
                        authToken
                        email
                        emailValid
                        createdAt
                        billGlobalDay
                        billGlobalDayEnabled
                        defaultClaveSatProdServ
                        defaultUnidadSat
                        lastGlobalBill
                        privateKey
                        publicKey
                    }
                    token
                }
            }`,
            setNameRfcChange: gql`
            mutation SetNameRfcChange($nombre: String, $rfc: String){
                setNameRfcChange(nombre: $nombre, rfc: $rfc)
            }`,
            setLugarExpedicionChange: gql`
            mutation SetLugarExpedicionChange($lugarExpedicion: String!){
                setLugarExpedicionChange(lugarExpedicion: $lugarExpedicion)
            }`,
            setGlobalBillingLimits: gql`
            mutation SetGlobalBillingLimits($billGlobalDay: Float!){
                setGlobalBillingLimits(billGlobalDay: $billGlobalDay){
                    billGlobalDay
                    billDayLimit
                }
            }`,
            setGlobalBillingEnabled: gql`
            mutation SetGlobalBillingEnabled($billGlobalDayEnabled: Boolean!){
                setGlobalBillingEnabled(billGlobalDayEnabled: $billGlobalDayEnabled){
                    billGlobalDayEnabled
                }
            }`,
            setChargeOnShipping: gql`
            mutation SetChargeOnShipping($charge: Boolean!){
                setChargeOnShipping(charge: $charge){
                    chargeShippingConcept
                }
            }`,
            setLinkTriggerOnShipping: gql`
            mutation SetLinkTriggerOnShipping($sendOnShipping: Boolean!){
                setLinkTriggerOnShipping(sendOnShipping: $sendOnShipping){
                    sendOnShipping
                }
            }`,
            setSelfBillingEnabled: gql`
            mutation SetSelfBillingEnabled($selfBillingEnabled: Boolean!){
                setSelfBillingEnabled(selfBillingEnabled: $selfBillingEnabled){
                    selfBillingEnabled
                }
            }`,
            setSelfBillingLimitsEnabled: gql`
            mutation SetSelfBillingLimitsEnabled($billDayLimitEnabled: Boolean!){
                setSelfBillingLimitsEnabled(billDayLimitEnabled: $billDayLimitEnabled){
                    billDayLimitEnabled
                }
            }`,
            setSelfBillingLimits: gql`
            mutation SetSelfBillingLimits($limits: selfBillingLimits!){
                setSelfBillingLimits(limits: $limits){
                    billDayLimitEnabled
                    billDayLimitParam
                    billDayLimit
                }
            }`,
            setSATdefaults: gql`
            mutation SetSATdefaults($defaults: satDefaults!){
                setSATdefaults(defaults: $defaults){
                    defaultClaveSatProdServ
                    defaultUnidadSat
                }
            }
            `,
            setClaroApi: gql`
            mutation setClaroApi($apiKeys: apiKeys!){
                setClaroApi(apiKeys: $apiKeys){
                    privateKey
                    publicKey
                }
            }
            `,
        },
        ITEMS: {
            setBulkItemBinder: gql`
            mutation SetBulkItemBinder($productIds: [String]!, $binder: ItemBinder!){
                setBulkItemBinder(productIds: $productIds, binder: $binder){
                    productId
                    claveProdServ
                    claveUnidad
                    customized
                    taxes{
                        type
                        tipo
                        tasa
                        cuota
                    }
                }
            }`,
            setItemBinder: gql`
            mutation SetItemBinder($binder: ItemBinderInput!){
                setItemBinder(binder: $binder){
                    productId
                    claveProdServ
                    claveUnidad
                    customized
                    taxes{
                        type
                        tipo
                        tasa
                        cuota
                    }
                }
            }`,
            getItemsByName: gql`
                query GetItemsByName($name: String!, $page: Float){
                    getItemsByName(name: $name, page: $page){
                        page
                        pages
                        items {
                            item{
                                id
                                site_id
                                title
                                subtitle
                                seller_id
                                category
                                official_store_id
                                price
                                base_price
                                original_price
                                inventory_id
                                currency_id
                                initial_quantity
                                available_quantity
                                sold_quantity
                                secure_thumbnail
                                permalink
                                pictures {
                                    id
                                    url
                                    secure_url
                                    size
                                    max_size
                                    quality
                                }
                                shipping {
                                    mode
                                    local_pick_up
                                    free_shipping
                                    dimensions
                                    store_pick_up
                                }
                            }
                            binder{
                                claveProdServ
                                claveUnidad
                                customized
                                taxes {
                                    type
                                    tasa
                                    cuota
                                }
                            }
                        }
                    }
                }
            `,
            getItems: gql`
                query GetItems($page: Float){
                    getItems(page: $page){
                        pages
                        currentPage
                        items{
                            item{
                                _id
                                transactionid
                                nombre
                                precio
                                estatus
                                ean
                                claroid
                                skupadre
                                fulfillment
                            }
                            binder{
                                productId
                                customized
                                claveProdServ
                                claveUnidad
                                taxes {
                                    type
                                    tasa
                                    cuota
                                }
                            }
                        }
                    }
                }
            `,
            getItemsByIds: gql`
                query GetItemsByIds($ids: [String]!){
                    getItemsByIds(ids: $ids) {
                        item{
                            id
                            site_id
                            title
                            subtitle
                            status
                            seller_id
                            category
                            official_store_id
                            price
                            base_price
                            original_price
                            inventory_id
                            currency_id
                            initial_quantity
                            available_quantity
                            sold_quantity
                            secure_thumbnail
                            permalink
                            pictures {
                                id
                                url
                                secure_url
                                size
                                max_size
                                quality
                            }
                            shipping {
                                mode
                                local_pick_up
                                free_shipping
                                dimensions
                                store_pick_up
                            }
                        }
                        binder{
                            claveProdServ
                            claveUnidad
                            customized
                            taxes {
                                type
                                tasa
                                cuota
                            }
                        }
                    }
                }
            `,
            getCategorization: gql`
            query GetCategorization{
                getCategorization{
                    updatedAt
                    inProgress
                    dictionary{
                        category_id
                        category
                        count
                    }

                }
            }`,
            getItemBinders: gql`
            query GetItemBinders($itemIds: [String]!){
                getItemBinders(itemIds: $itemIds){
                    productId
                    customized
                    claveProdServ
                    claveUnidad
                    profileId
                    taxes {
                        type
                        tipo
                        tasa
                        cuota
                    }
                }
            }`
        },
        ORDERS: {
            getOrdersGlobal: gql`
            query GetOrdersGlobal($start: Float, $end: Float) {
                getOrdersGlobal(start: $start, end: $end) {
                    id
                    order {
                        _id
                        nopedido
                        estatus
                        fechacolocacion
                        fechaautorizacion
                        sku
                        articulo
                        claroid
                        idpedidorelacion
                        sla
                        comision
                        totalproducto
                        totalpedido
                        skuhijo
                    }
                    binder{
                        orderId
                        billUUID
                        billIsGlobal
                        refundIds
                        refundBillUUID {
                            uuid
                            refundIds
                        }
                    }
                }
            }`,
            getOrders: gql`
            query GetOrders($start: Float, $end: Float, $action: String) {
                getOrders(start: $start, end: $end, action: $action) {
                    id
                    order {
                        _id
                        nopedido
                        estatus
                        fechacolocacion
                        fechaautorizacion
                        sku
                        articulo
                        claroid
                        idpedidorelacion
                        sla
                        comision
                        totalproducto
                        totalpedido
                        skuhijo
                        channel
                    }
                    binder{
                        guia
                        orderId
                        billUUID
                        billIsGlobal
                        refundIds
                        refundBillUUID {
                            uuid
                            refundIds
                        }
                    }
                    binders {
                        guia
                    }
                }
            }`,
            getOrderById: gql`
            query GetOrderById($id: String) {
                getOrderById(id: $id) {
                    id
                    order {
                        orderId
                        channel
                        estatuspedido {
                            estatus
                            fechacolocado
                        }
                        productos {
                            fechaasignacion
                            fechaenvio
                            producto
                            importe
                            envio
                            estatus
                            asignado
                            guia
                            claroid
                            idpedidorelacion
                            skuhijo
                            sku
                        }
                    }
                    binder{
                        guia
                        orderId
                        billUUID
                        billIsGlobal
                        refundIds
                        refundBillUUID {
                            uuid
                            refundIds
                        }
                    }
                    binders{
                        guia
                        orderId
                        billUUID
                        billIsGlobal
                        refundIds
                        refundBillUUID {
                            uuid
                            refundIds
                        }
                    }
                }
            }`,
        },
    },
    FISCALPOP: {
        CFDI: {
            setRefundToStamp: gql`
                mutation SetRefundToStamp($cfdi: FiscalpopBill!, $refundIds: [String]!, $orderId: String!){
                    setRefundToStamp(cfdi: $cfdi, refundIds: $refundIds, orderId:$orderId){
                        orderId
                        billUUID
                        billIsGlobal
                        refundBillUUID {
                            uuid
                            refundIds
                        }
                        refundIds
                    }
                }
            `,
            setBillToStamp: gql`
                mutation SetBillToStamp($cfdi: FiscalpopBill!, $orderId: String!, $guia: String!, $refundIds: [String]){
                    setBillToStamp(cfdi: $cfdi, orderId: $orderId, guia: $guia, refundIds: $refundIds){
                        orderId
                        billUUID
                        billIsGlobal
                        refundBillUUID {
                            uuid
                            refundIds
                        }
                        refundIds
                    }
                }
            `,
            setGlobalBillToStamp: gql`
                mutation SetGlobalBillToStamp($cfdi: FiscalpopBill!, $orderIds: [String]!, $lastDate: Float){
                    setGlobalBillToStamp(cfdi: $cfdi, orderIds: $orderIds, lastDate: $lastDate){
                        UUID
                    }
                }
            `,
            setBillToCancel: gql`
            mutation SetBillToCancel($uuid: String!){
                setBillToCancel(uuid: $uuid){
                    folios {
                        estatus
                        uuid
                    }
                }
            }
            `,
        },
        REGIMEN: {
            getRegimenFiscal: gql`
                query GetRegimenFiscal{
                    getRegimenFiscal{
                        clave
                        descripcion
                    }
                }
            `
        },
        SETUP: {
            setFiscalpopClient: gql`
            mutation SetFiscalpopClient($client: FiscalpopClient!){
                setFiscalpopClient(client: $client){
                    rfc
                    regimenFiscal
                    nombre
                    lugarExpedicion
                }
            }`,
        },
        SUPPORT: {
            sendSupportEmail: gql`
            mutation SendSupportEmail($support: FiscalpopSupport!){
                sendSupportEmail(support: $support) {
                    success
                }
            }`
        }
    },
    CONEKTA: {
        ORDERS: {
            getPaymentOrders: gql`
            query GetPaymentOrders {
                getPaymentOrders {
                    type
                    orderId
                    userId
                    amount
                    paid
                    currency
                    status
                    createdAt
                    expired
                    invoiceUUID
                }
            }`
        },
        SPEI: {
            requestSpeiPayment: gql`
            mutation RequestSpeiPayment($amount: Float!) {
                requestSpeiPayment(amount: $amount){
                    id
                    bank
                    clabe
                    amount
                    currency
                    order
                    quantity
                }
            }`
        },
        CARDS: {
            requestCardPayment: gql`
            mutation RequestCardPayment($amount: Float!, $sourceid: String!) {
                requestCardPayment(amount: $amount, sourceid: $sourceid) {
                    type
                    orderId
                    userId
                    amount
                    paid
                    currency
                    status
                    createdAt
                }
            }`,
            getCardPayments: gql`
                query GetCardPayments {
                    getCardPayments{
                        id
                        name
                        exp_month
                        exp_year
                        object
                        type
                        created_at
                        last4
                        brand
                    }
                }`,
            addCard: gql`
                mutation AddCard($card: ConektaCard!, $phone: String, $email: String){
                    addCard(card: $card, phone: $phone, email: $email){
                        id
                        name
                        exp_month
                        exp_year
                        object
                        type
                        created_at
                        last4
                        brand
                    }
                }`,
            removeCard: gql`
                mutation RemoveCard($paymentId: String!){
                    removeCard(paymentId: $paymentId){
                        id
                        name
                        exp_month
                        exp_year
                        object
                        type
                        created_at
                        last4
                        brand
                    }

                }`,
        }
    }
}