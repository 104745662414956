import React, { useState, FormEvent } from 'react';
import Modal from 'react-modal';

import Input from '../../Forms/Input';
import Select from '../../Forms/Select';

import { ConektaCustomer_PaymentSource, SpeiResponse, ConektaOrder } from '../../../models/Conekta';

import './Account.payment.scss';
import Button from '../../Forms/Button';
import { useMutation } from '@apollo/client';
import { graphqlSchema } from '../../../services/graphql.schema';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh',
        maxWidth: '500px',
        padding: '0px',
        border: 'none',
        boxShadow: 'rgba(0, 0, 0, 0.32) 1px 1px 3px 1px'
    }
};

interface AccountPaymentProps {
    isOpen: boolean;
    paymentMethods: ConektaCustomer_PaymentSource[];
    onRequestClose: (response?: any) => void;
}

function AccountPayment({ isOpen, onRequestClose, paymentMethods }: AccountPaymentProps) {
    const [cardPayError, setCardPayError] = useState<{[key:string]:boolean}>({});
    const [tryingToPay, setTryingToPay] = useState(false);
    const [payMethod, setPayMethod] = useState<string>('spei');
    const [amount, setAmount] = useState<number>(100);
    const [addBalanceWithSpei] = useMutation(graphqlSchema.CONEKTA.SPEI.requestSpeiPayment, {
        onCompleted: ({ requestSpeiPayment }: { requestSpeiPayment: SpeiResponse }) => {
            console.log('SPEI payment: ', requestSpeiPayment);
            // UseReducer to update object array
            onRequestClose(requestSpeiPayment);
            setTryingToPay(false);
        },
        onError: (e) => {
            setTryingToPay(false);
            console.error('Error Adding SPEI: ', e);
        }
    });
    const [addBalanceWithCard] = useMutation(graphqlSchema.CONEKTA.CARDS.requestCardPayment, {
        onCompleted: ({ requestCardPayment }: { requestCardPayment: ConektaOrder }) => {
            console.log('Card payment: ', requestCardPayment);
            // UseReducer to update object array
            onRequestClose(requestCardPayment);
            setTryingToPay(false);
        },
        onError: (e) => {
            setTryingToPay(false);
            const cardErr:{[key:string]:boolean} = Object.assign({}, cardPayError);
            cardErr[payMethod] = true;
            setCardPayError(cardErr);
            console.error('Error Adding card payment: ', e);
            console.error(typeof e === 'string' ? JSON.parse(e) : e);    
        }
    });

    const handleAmountChange = (am: number) => {
        if (am < 100) {
            am = 100;
        }
        setAmount(am);
    }

    const calculateComission = () => {
        if (payMethod === 'spei') {
            return ((8 + (amount * .01)) * 1.16);
        } else {
            return (2.5 + (amount * .029)) * 1.16;
        }
    }

    const requestMoreCredit = (e: FormEvent) => {
        e.preventDefault();
        setTryingToPay(true);
        if(payMethod === 'spei'){
            addBalanceWithSpei({
                variables: {
                    amount
                }
            })
        } else {
            addBalanceWithCard({
                variables: {
                    amount,
                    sourceid: payMethod
                }
            })
        }
    }

    const paymentOptions = paymentMethods.map(m => ({ value: m.id, label: `${m.brand} - ${m.last4}` })).concat([
        { value: 'spei', label: 'Transferencia SPEI' },
    ]);
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => onRequestClose()}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Producto Edit Modal"
        >
            <div id="AccountPayment">
                <div className="title">
                    <h3>Agregar Saldo a tu cuenta</h3>
                </div>
                <form onSubmit={requestMoreCredit}>
                    <p>El saldo agregado <b>no tiene vencimiento</b> y se utilizará para pagar tus gastos de facturación</p>
                    <Select label="Método de pago" value={payMethod} options={paymentOptions} onChange={setPayMethod} />
                    <Input label="Saldo a agregar (mínimo $100)" placeholder="Saldo a agregar" value={amount} onChange={handleAmountChange} type="number" />
                    <div className="desglose">
                        <p className="accent">Comisión bancaria:</p>
                        <p className="accent">-$ {calculateComission().toFixed(2)}</p>
                        <p><b>Neto a cargar:</b></p>
                        <p><b>$ {(amount - calculateComission()).toFixed(2)}</b></p>
                    </div>
                    <Button primary={true} type="submit" disabled={tryingToPay || (cardPayError[payMethod])} accent={(cardPayError[payMethod])} >
                        <span>{tryingToPay ? 'Pagando...' : (cardPayError[payMethod] ? 'Error, intenta otra forma de pago' :'Agregar Saldo')}</span>
                    </Button>
                </form>
            </div>
        </Modal>
    )
}

export default AccountPayment;