import React, { useState, useReducer, useEffect } from 'react';

import './Products.list.scss';
import { BaseReact } from '../../base.model';
import { ProfileQL } from '../../models/Profile';

import { NavLink } from 'react-router-dom';
import SectionTitleBar from '../components/titlebar';
import { Subject } from 'rxjs';
import { ClaroItemsBinded, ClaroItemBinder, ClaroItemsBindedPaginated } from '../../models/Items';
import { useMutation, useLazyQuery } from '@apollo/client';
import { graphqlSchema } from '../../services/graphql.schema';

import { numberToCurrencyString } from '../../services/formatting';
import ItemEditModal from './Modals/Product.edit';
import sharedToasterSubject from '../../services/shared.toasterSubject';
import Pagination from '../Forms/Pagination';
import InputCard from '../Forms/Input.cardForm';

interface ProductsProps extends BaseReact {
    currentUser: ProfileQL;
}


function itemsReducer(state: { items: ClaroItemsBinded[] }, action: { type: string, payload: ClaroItemsBinded[] }) {
    switch (action.type) {
        case 'set':
            return Object.assign({}, { items: action.payload });
        case 'update':
            return Object.assign({}, { items: action.payload });
        default:
            return Object.assign({}, { items: action.payload });
    }
}

function ProductsList({ history, location, currentUser }: ProductsProps) {
    const [searchSubject] = useState(new Subject<string>());
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);

    const [isLoading, setIsLoading] = useState(false);

    const [search, setSearch] = useState('');

    const [items, dispatchItems] = useReducer(itemsReducer, { items: [] } as { items: ClaroItemsBinded[] });

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<ClaroItemsBinded>(null);

    const [setBinder] = useMutation(graphqlSchema.PROFILE.ITEMS.setItemBinder, {
        onCompleted: ({ setItemBinder }) => {
            console.log('Updated Item Binder: ', setItemBinder);
            // Update product object

            /*
            const newItems = [...items.items];
            const itemToBind = items.items.find(it => it.item.claroid === setItemBinder.productId);
            if (itemToBind) {
                itemToBind.binder = setItemBinder;
                dispatchItems({ type: 'update', payload: items.items });
            }
            */
            const itemToBind = items.items.find(it => it.item.transactionid === setItemBinder.productId);
            if (itemToBind) {
                itemToBind.binder = setItemBinder;
                dispatchItems({ type: 'update', payload: items.items });
            }
            sharedToasterSubject.next({ type: 'confirm', message: 'Se actualizó el producto' });
        },
        onError: (e) => {
            console.error('Error updating binder: ', e);
            sharedToasterSubject.next({ type: 'error', message: 'Error al actualizar el producto, comuníquese con soporte' });
        }
    });

    const [fetchProducts] = useLazyQuery(graphqlSchema.PROFILE.ITEMS.getItems, {
        onCompleted: ({ getItems }: { getItems: ClaroItemsBindedPaginated }) => {
            console.log('Get Items: ', JSON.parse(JSON.stringify(getItems.items)));
            dispatchItems({ type: 'set', payload: JSON.parse(JSON.stringify(getItems.items)) });
            setPage(getItems.currentPage)
            setPages(getItems.pages);
            setIsLoading(false);
        },
        onError: (e) => {
            console.error('E: ', e);
            setIsLoading(false);
        },
        fetchPolicy: 'cache-and-network'
    })

    useEffect(() => {
        fetchProducts({
            variables: {
                page
            }
        })
    }, [page, fetchProducts])


    const saveOnClose = (save: boolean, binder: ClaroItemBinder) => {
        setModalIsOpen(false);
        console.log('Saving Binder: ', save);
        console.log('Binder: ', binder);
        if (save && !!binder) {
            setBinder({
                variables: {
                    binder
                }
            })
        }
    }

    const handleSearch = (value: string) => {
        setSearch(value);
        searchSubject.next(value);
    }

    const selectItemForEdit = (item: ClaroItemsBinded) => {
        return () => {
            setSelectedItem(item);
            setModalIsOpen(true);
        }
    }

    const changePage = (_page: number) => {
        if(isLoading) {
            return;
        }
        setIsLoading(true);
        console.log('Changing page to: ', _page);
        setPage(_page);
    }

    const filteredItems = !search ? items.items : items.items.filter(item => {
        const words = search.toLowerCase().split(' ');
        const nameWords = item.item.nombre.toLowerCase().split(' ');
        return words.every(w => nameWords.some(nW => nW.includes(w)));
    });

    return (
        <div id="Items">
            <SectionTitleBar currentUser={currentUser} title="Mis productos" />
            <div className="itemsContent">
                <div className='card'>



                    <div className="toBulkEdit show-md">
                        <NavLink to="/item/bulkedit">
                            <span>
                                Editar múltiples listados
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                                <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
                            </svg>
                        </NavLink>
                    </div>

                    <div className='searchBlock'>
                        <InputCard type="text" icon='search' value={search} onChange={handleSearch} label={'Buscar por nombre / ID'} placeholder="Busca tus productos por nombre o ID" />
                    </div>

                    <div className="itemList">
                        <div className="item header">
                            <p className="_id">
                                SKU / ID
                            </p>
                            <p className='_name'>
                                Nombre
                            </p>
                            <p className="_price">
                                Precio
                            </p>
                            <p className="_uuid">
                                Clave Producto
                            </p>
                            <p className="_taxes">
                                Impuestos
                            </p>
                        </div>
                        {
                            filteredItems.map((item) => {
                                const defaultClave = currentUser.claroProfile.defaultClaveSatProdServ || null;
                                const defaultUnidad = currentUser.claroProfile.defaultUnidadSat || null;

                                const prodClave = !!item.binder && item.binder.claveProdServ ? item.binder.claveProdServ : (defaultClave || '--');
                                const prodUnidad = !!item.binder && item.binder.claveUnidad ? item.binder.claveUnidad : (defaultUnidad || '--');
                                return (
                                    <div className="item" key={item.item.transactionid}>
                                        <div className="ids">
                                            <p className="_id">
                                                {item.item.skupadre}
                                            </p>
                                            <p className="_sku">
                                                {item.item.claroid}
                                            </p>
                                        </div>
                                        <div className='_name'>
                                            <p className="_name">
                                                {item.item.nombre.substring(0, 45)}
                                            </p>
                                            <p className={`_status ${item.item.estatus}`}>
                                                {item.item.estatus}
                                            </p>
                                        </div>
                                        <p className="_price">
                                            $ {numberToCurrencyString(item.item.precio)}
                                        </p>
                                        <div className='_uuid'>
                                            <p className={`_clave${!!item.binder ? ' customized' : ''}`}>
                                                {prodClave}
                                            </p>
                                            <p className="_clave">
                                                {prodUnidad}
                                            </p>
                                        </div>
                                        <p className="_taxes">
                                            IVA 16%
                                        </p>
                                        <button onClick={selectItemForEdit(item)} className="svg mq4">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
                                            </svg>
                                        </button>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        items && items.items.length ?
                            <Pagination page={page} pages={pages} onPage={changePage} />
                            : ''
                    }

                </div>
            </div>
            {ItemEditModal({ modalIsOpen, item: selectedItem, onClose: saveOnClose, authToken: currentUser.claroProfile.authToken })}
        </div>
    )
}


export default ProductsList;