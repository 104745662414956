export const numberToCurrencyString = (value: number) => {
    const valueString = value.toFixed(2);
    const [integers, cents] = valueString.split('.');
     const integerString = integers.split('').reverse().reduce((p,c, index) => {
        const baseOneIndex = index + 1;
        p.push(c);
        if(!(baseOneIndex % 3) && baseOneIndex < (integers.split('').length)){
            p.push(`,`);
        }
        return p;
    }, []).reverse().join('');
    return `${integerString}.${cents}`;
};