import React, { useState } from 'react';

import './Setup.lugarChange.scss';
import IconButton from '../Forms/IconButton';
import Input from '../Forms/Input';
import { FiscalpopProfile } from '../../models/Profile';
import { useMutation } from '@apollo/client';
import { graphqlSchema } from '../../services/graphql.schema';
import sharedToasterSubject from '../../services/shared.toasterSubject';

const ZIP_REGEX = /\b[0-9]{5}\b/

function SetupLugarChange({ fiscalpopProfile }: { fiscalpopProfile: FiscalpopProfile }) {

    const [triedSubmitting, setTriedSubmitting] = useState(false);
    const [lugarExpedicion, setLugarExpedicion] = useState(!!fiscalpopProfile && fiscalpopProfile.lugarExpedicion? fiscalpopProfile.lugarExpedicion : '');
    const [setLugar] = useMutation(graphqlSchema.PROFILE.SETUP.setLugarExpedicionChange, {
        onCompleted: ({ setLugarExpedicionChange }) => {
            console.log('Updated Lugar: ', setLugarExpedicionChange);
            setLugarExpedicion(setLugarExpedicionChange);
            sharedToasterSubject.next({ type: 'confirm', message: `Se cambió el lugar de expedición` });
        },
        onError: (e) => {
            console.error('Error updating defaults: ', e);
        }
    })

    const setNewLugar = () => {
        if(!ZIP_REGEX.test(lugarExpedicion)){
            setTriedSubmitting(true);
            return;
        }

        setLugar({
            variables: {
                lugarExpedicion
            }
        })
    }

    return (
        <div className="card lugarChange" id="SetupLugarChange">
            <div className="title">
                <h4>Cambiar Lugar de expedición</h4>
                <IconButton primary={true} handleClick={setNewLugar}>
                    <i className="material-icons">
                        save
                    </i>
                </IconButton>

            </div>
            <div className="lugarWrap">
                <Input type="text" hasError={triedSubmitting && !ZIP_REGEX.test(lugarExpedicion)} value={lugarExpedicion} onChange={setLugarExpedicion} placeholder="Código postal de tu empresa" label={'Código postal de tu empresa'} />
            </div>
        </div>
    )
}

/*

*/

export default SetupLugarChange;