
import React, { useState, useReducer, useEffect } from 'react';

import './Orders.list.scss'
import { BaseReact } from '../../base.model';
import { ProfileQL } from '../../models/Profile';
import SectionTitleBar from '../components/titlebar';
import { useLazyQuery } from '@apollo/client';
import { graphqlSchema } from '../../services/graphql.schema';

import moment from 'moment';
import { ClaroOrdersBinded } from '../../models/Orders';
import { Link } from 'react-router-dom';
import { Subject } from 'rxjs';
import { numberToCurrencyString } from '../../services/formatting';
import Loading from '../Animations/loadScreen';

import Pagination from '../Forms/Pagination';

import sharedToasterSubject from '../../services/shared.toasterSubject';
import SelectCard from '../Forms/Select.cardForm';
import InputCard from '../Forms/Input.cardForm';

interface OrdersProps extends BaseReact {
    currentUser: ProfileQL;
}

moment.locale('es');

const ENTRIES_PER_PAGE = 35;

function ordersReducer(state: { orders: ClaroOrdersBinded[] }, action: { type: string, payload: ClaroOrdersBinded[] }) {
    switch (action.type) {
        case 'set':
            console.log('<DISPATCH> action', action);
            return Object.assign({}, { orders: action.payload });
        case 'update':
            return Object.assign({}, { orders: action.payload });
        default:
            return Object.assign({}, { orders: action.payload });
    }
}

function OrdersList({ history, location, currentUser }: OrdersProps) {
    const [page, setPage] = useState(1);

    const [searchSubject] = useState(new Subject<string>());
    const [monthOverride, setMonthOverride] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const [customFilter, setCustomFilter] = useState<'all' | 'billed' | 'un_billed'>('all');
    const [search, setSearch] = useState('');

    const [orders, dispatchOrders] = useReducer(ordersReducer, { orders: [] } as { orders: ClaroOrdersBinded[] });

    const [getOrdersQuery] = useLazyQuery(graphqlSchema.PROFILE.ORDERS.getOrders, {
        onCompleted: ({ getOrders }: { getOrders: ClaroOrdersBinded[] }) => {
            setIsLoading(false);
            console.log('<GetOrders Lazy>  Get Orders Query: ', getOrders);
            dispatchOrders({ type: 'set', payload: getOrders.filter(ord => !orders.orders.some(_ord => _ord.order.nopedido === ord.order.nopedido)) });
        },
        onError: (e) => {
            setIsLoading(false);
            const errMessage = e.graphQLErrors[0]?.message || 'Error al cargar los pedidos';
            console.error('E graphQLErrors: ', e.graphQLErrors[0]);
            sharedToasterSubject.next({ type: 'error', message: errMessage});
        },
        fetchPolicy: 'cache-and-network'
    })

    useEffect(() => {
        console.log(`>> UE On Start`);
        getOrdersQuery({
            variables: {
                start: moment().startOf('month').toDate().getTime(),
                end: moment().endOf('hour').toDate().getTime(),
            }
        })
    }, [getOrdersQuery])

    const handleSearch = (value: string) => {
        setSearch(value.split(' ').join('').split('\n').join(''));
        searchSubject.next(value);
    }

    const orderFilterOptions = [
        { value: 'all', label: 'Todos' },
        { value: 'billed', label: 'Facturados' },
        { value: 'un_billed', label: 'No Facturados' },
    ]

    const dayOverrideOptions = [
        { value: 0, label: moment().format('MMMM') },
        { value: 1, label: moment().subtract(1, 'month').format('MMMM') },
        { value: 2, label: moment().subtract(2, 'month').format('MMMM') },
    ]

    const changeMonthOverride = (monthOverride: 0 | 1 | 2) => {
        setMonthOverride(monthOverride);
        setIsLoading(true);
        if (!monthOverride) {
            getOrdersQuery({
                variables: {
                    start: moment().startOf('month').toDate().getTime(),
                    end: moment().endOf('hour').toDate().getTime()
                }
            })
        } else {
            getOrdersQuery({
                variables: {
                    start: moment().subtract(monthOverride, 'month').startOf('month').toDate().getTime(),
                    end: moment().subtract(monthOverride, 'month').endOf('month').toDate().getTime()
                }
            })
        }
    }

    const changePage = (_page: number) => {
        console.log('Changing page to: ', _page);
        setPage(_page);
    }


    const ordersFiltered = orders.orders
        .filter((binded) => {
            return `${binded.order.nopedido}`.includes(search);
        })
        .filter((binded) => {
            if (customFilter === 'all') {
                return true
            }
            if (customFilter === 'billed') {
                return !!binded.binder
            } else {
                return !binded.binder
            }
        })
    const ordersPaginated = ordersFiltered
        .filter((o, i) => (i >= (ENTRIES_PER_PAGE * (page - 1))) && (i < (ENTRIES_PER_PAGE * (page))));

    return (
        <div id="Ordenes">
            <SectionTitleBar currentUser={currentUser} title="Tus pedidos" />
            <div className="ordersContent">

                <div className='card'>

                    <div className='searchBlock'>
                        <InputCard icon='search' type="text" value={search} onChange={handleSearch} label={`Filtrar por ${search.length > 12 ? 'ID de carrito' : 'ID'}`} placeholder="Filtrar tus pedidos por ID ayuda a buscar en la lista" />
                    </div>
                    <div className="filterChange">
                        <SelectCard value={monthOverride} onChange={changeMonthOverride} label={'Por Mes'} options={dayOverrideOptions} />
                        <div className="toGlobal">
                            <Loading
                                svgHeight={'44px'}
                                svgWidth={'44px'}
                                display={isLoading}
                                relativePos={true}
                            />
                        </div>
                    </div>

                    {
                        /*
                    <div className="filterChange">
                        <Input type="text" value={search} onChange={handleSearch} label={`Filtrar por ID de pedido`} placeholder="Filtrar tus pedidos por ID ayuda a buscar en la lista" />
                        <Select value={customFilter} onChange={setCustomFilter} label={'Facturación'} options={orderFilterOptions} />
                        <Select value={monthOverride} onChange={changeMonthOverride} label={'Por Mes'} options={dayOverrideOptions} />
                    </div>
                        */
                    }



                    <div className="ordersList">
                        <div className="order header">
                            <p className="_id">
                                ID
                            </p>
                            <p>
                                Estado
                            </p>
                            <p className="_title">
                                Fecha / Envío
                            </p>
                            <p className="_uuid">
                                UUID Factura / UUID Reembolso
                            </p>
                            <p className="_price">
                                Precio total
                            </p>
                        </div>
                        {
                            !isLoading && !orders.orders.length
                                ?
                                <div className="_emptyOrder">
                                    <div className="textWrap">

                                        <h2>
                                            Los pedidos se actualizan cada 30 minutos
                                        </h2>
                                        <p>
                                            Elije un mes anterior o vuelve a esta lista en un momento más
                                        </p>
                                    </div>
                                </div>
                                : ''
                        }
                        {
                            ordersPaginated
                                .map((orderbinded, i) => {
                                    const order = orderbinded.order;
                                    const binder = orderbinded.binder;
                                    return (
                                        <div className="order" key={i}>
                                            <div>
                                                <p className="_id">
                                                    {order.nopedido}
                                                </p>
                                                <p className="_channel">
                                                    {order.channel === 'CS' ? 'Claro Shop' : 'SEARS'}
                                                </p>
                                            </div>
                                            <div className="_status">
                                                <p className={`${order.estatus === 'Pending' ? '_pending' : ''}`}>{order.estatus}</p>
                                                <p className={`_statusBilled ${binder ? '_billed' : '_unbilled'}`}>{!!binder ? 'Facturado' : 'Sin facturar'}</p>
                                            </div>
                                            <div className="_title">
                                                <p>
                                                    {moment(order.fechaautorizacion).format('ddd MMM D, YYYY')}
                                                </p>
                                            </div>
                                            <div className="_uuid">
                                                <p className={`_uuid ${binder?.billUUID === 'FACTURA NO NECESARIA TODAVÍA' ? 'noBill' : ''}`}>
                                                    {binder ? binder.billUUID : '--'} {!!binder && binder.billIsGlobal ? <b>(Global)</b> : ''}
                                                </p>
                                                <p className="_uuidRefund">
                                                    {binder && binder.refundBillUUID && binder.refundBillUUID.length ? binder.refundBillUUID[0].uuid : '--'}
                                                </p>
                                            </div>
                                            {
                                                order.totalpedido ?
                                                    <p className="_price">
                                                        $ {numberToCurrencyString(order.totalpedido)}
                                                    </p>
                                                    : '--'
                                            }
                                            {
                                                !order.totalpedido ?
                                                    <button className="svg noBill" disabled={true}>
                                                        <svg className={`${!!binder ? 'billed' : ''}`} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                                                            <path d="M18 17H6v-2h12v2zm0-4H6v-2h12v2zm0-4H6V7h12v2zM3 22l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20z" />
                                                        </svg>
                                                    </button>
                                                    :
                                                    <Link className="svg" to={`/ordenes/stamp/${order.nopedido}`}>
                                                        <svg className={`${!!binder ? (order.estatus === 'Canceled' ? 'isRefund' : (binder.billUUID === 'FACTURA NO NECESARIA TODAVÍA' ? '' : 'billed')) : ''}`} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                                                            <path d="M18 17H6v-2h12v2zm0-4H6v-2h12v2zm0-4H6V7h12v2zM3 22l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20z" />
                                                        </svg>
                                                    </Link>
                                            }
                                        </div>
                                    )
                                })
                        }
                    </div>
                    <Pagination onPage={setPage} pages={Math.ceil(ordersFiltered.length / ENTRIES_PER_PAGE)} page={page} />
                </div>
            </div>
        </div>
    )
}

export default OrdersList;