/* eslint-disable no-mixed-operators */
import React, { useState } from "react";

import './Input.cardForm.scss';

interface SelectProps {
    onChange: Function;
    label?: string;
    value: any;
    disabled?: boolean;
    hasError?: boolean;
    errorLabel?: string;
    displayLabel?: boolean;
    charLimit?: number;
    placeholder?:string;
    options: { label: string, display?: string; value: any, disabled?: boolean }[];

}

const SelectCard = (props: SelectProps) => {
    const selectRef = React.createRef<HTMLSelectElement>();
    const [active, setActive] = useState(false);

    const onSelectRef = () => { }

    const activate = () => {
        setActive(true);
    }

    const deactivate = () => {
        setActive(false);
    }

    const substringChars = (opt: { label: string; display?: string; value: any }) => {
        const text: string = (!!opt && !!opt.label) && !!props.displayLabel ? opt.label : (!!opt && (opt.display || opt.label) || `${props.value}`);
        const exceeds = text.length > (props.charLimit || 0);
        if (!!props.charLimit) {
            return `${text.substring(0, props.charLimit)}${exceeds ? '...' : ''}`;
        } else {
            return text;
        }
    }

    const changeValue = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        const options = props.options.map(o => Object.assign({}, o));
        const candidate = options.map(o => { o.value = `${o.value}`; return o }).find(c => c.value === value);
        if (!candidate) {
            return props.onChange(value);
        }

        // Process Boolean and non string-reversible values
        const option = props.options.find(o => o.label === candidate.label);
        props.onChange(option.value);
    }

    const classInput = `inputWrapper ${props.disabled ? 'disabled' : ''} ${active ? 'active' : ''} ${props.hasError ? 'error' : ''}`;
    const opt = props.options.find(o => o.value === props.value);

    return (
        <div className={`inputCardBlock selectType ${active ? 'active' : ''} ${props.disabled ? 'disabled' : ''} dirty ${props.hasError ? 'error' : ''}`} >
            {
                !!props.label ?
                    <label>
                        {props.label}
                    </label>
                    : <label></label>
            }
            < div className={classInput} >
                <select ref={selectRef} onFocus={activate} onBlur={deactivate} onChange={changeValue} value={props.value} disabled={!!props.disabled} placeholder={props.placeholder || ''}>
                    {
                    props.placeholder ? 
                        <option value="" disabled>{props.placeholder}</option>
                        : ''
                    }
                    {props.options.map((e, i) => {
                        if (!!e.disabled) {
                            return (
                                <option value={e.value} key={i} disabled>
                                    {e.label}
                                </option>
                            )
                        }
                        return (
                            <option value={e.value} key={i}>
                                {e.label}
                            </option>
                        )
                    })}
                </select>
                <div className={`selectContent ${!opt ? '_placeholdered' : ''}`} onClick={onSelectRef}>
                    <span>{substringChars(opt) || props.placeholder || ''}</span>
                </div>
            </div >
            <div className="errorText">
                {
                    props.hasError ?
                        <span className="errorText">{props.errorLabel}</span>
                        : ''
                }
            </div>
        </div >
    )

}


export default SelectCard;