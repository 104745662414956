import React, { useState, useEffect } from 'react';
import { ClaroOrdersBinded } from '../../models/Orders';

function GlobalSectionReportDownload({ billList, label, chargeShipping }: { billList: ClaroOrdersBinded[], label:string, chargeShipping:boolean }){
    const [csvFile, setCsvFile] = useState(null);

    useEffect(() => {
        if(billList.length){
            const csvHead = `Orden,Subtotal,Impuestos,total`;
            const csvBody = billList.map((order) => {
                const valorUnitarioBase = order.order.totalpedido + (chargeShipping ? 0 : 0);
                const valorUnitario = Math.round((valorUnitarioBase / 1.16) * 100) / 100;
                return [order.order.nopedido, valorUnitario, (valorUnitarioBase-valorUnitario).toFixed(2), valorUnitarioBase].join(',');
            })
            const csv = [csvHead].concat(csvBody).join('\n');
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            setCsvFile(URL.createObjectURL(blob));
        }else {
            setCsvFile(null);
        }
    }, [billList, chargeShipping]);

    if(!csvFile){
        return (
            <span className="_downloadCsv disabled">
                Descargar lista como CSV
            </span>
        )
    }
    return (
        <a className="_downloadCsv" href={csvFile} download={`Pedidos_${label.split(' ').join('_').replace('º', '').replace(',', '')}.csv`}>
            Descargar lista como CSV
        </a>
    )

}

export default GlobalSectionReportDownload;