import React, { useState, useEffect, useReducer } from 'react';

import './Stamp.global.scss';
import { BaseReact } from '../../base.model';
import { ProfileQL } from '../../models/Profile';
import SectionTitleBar from '../components/titlebar';
import moment from '../../services/unifiedMoment';
import Select from '../Forms/Select';
import Checkbox from '../Forms/Checkbox';
import { useLazyQuery } from '@apollo/client';
import { graphqlSchema } from '../../services/graphql.schema';
import { ClaroOrdersBinded } from '../../models/Orders';
import StampGlobalGrouping from './Stamp.global.grouping';
import StampGlobalDropdown from './Stamp.global.dropdown';
import GraphQlClient from '../../services/graphql';
import Loading from '../Animations/loadScreen';
import Input from '../Forms/Input';
import sharedToasterSubject from '../../services/shared.toasterSubject';
// moment.locale('es');

interface GlobalProps extends BaseReact {
    currentUser: ProfileQL;
}

const MONTH_SELECT = [
    { value: 0, label: 'Desde Última global' },
    { value: 1, label: 'Todo Mes En Curso' },
    { value: 2, label: 'Desde Mes Anterior' },
]

function selectedReducer(state: { [key: string]: boolean }, action: { type: 'set' | 'all', payload: { [key: string]: boolean } }) {
    switch (action.type) {
        case 'set':
            return Object.assign({}, state, action.payload);
        case 'all':
            return Object.assign({});
        default:
            return Object.assign({}, state);
    }
}


// order.date_created.from
function StampGlobal({ history, location, currentUser }: GlobalProps) {
    // UI Change Month Options
    const [lastMonth, setLastMonth] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState('');
    // =======================

    const [grouping, setGrouping] = useState<1 | 7 | 30>(7);
    const [lastGlobalBill, setLastGlobalBill] = useState(currentUser.claroProfile.lastGlobalBill);
    const [temporalLastGlobal, setTemporalLastGlobal] = useState(currentUser.claroProfile.lastGlobalBill);

     
    const [unSelectedDict, dispatchUnSelected] = useReducer(selectedReducer, {});
    const [allOrders, setAllOrders] = useState<ClaroOrdersBinded[]>([]);

    const [lazilyGetOrders] = useLazyQuery(graphqlSchema.PROFILE.ORDERS.getOrdersGlobal, {
        onCompleted: ({ getOrdersGlobal }: { getOrdersGlobal: ClaroOrdersBinded[]  }) => {
            const _allOrders: ClaroOrdersBinded[] = JSON.parse(JSON.stringify(allOrders.concat(getOrdersGlobal.filter(o => !o.binder))))
            setAllOrders(_allOrders.sort((a, b) => new Date(a.order.fechaautorizacion).getTime() - new Date(b.order.fechaautorizacion).getTime()));
            console.log('[StampGlobal] Orders gotten: ', getOrdersGlobal);
            setIsLoading(false);
        },
        onError: (e) => {
            console.log('ERROR: ', e);
            setIsLoading(false);
            console.error('E graphQLErrors: ', e.graphQLErrors[0].message);
            sharedToasterSubject.next({ type: 'error', message: e.graphQLErrors[0].message });
        },
        fetchPolicy: 'cache-first'
        //fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        setIsLoading(true);
        const momentBase = !!currentUser.claroProfile.lastGlobalBill ? moment(currentUser.claroProfile.lastGlobalBill) : moment(Date.now()).startOf('month');
        const momentApplicable = lastMonth === 0 ? momentBase : (lastMonth === 1 ? moment().startOf('month') : moment().subtract(1, 'month').startOf('month'));
        
        lazilyGetOrders({
            variables: {
                start: momentApplicable.toDate().getTime(),
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (lastGlobalBill !== currentUser.claroProfile.lastGlobalBill) {
            console.log('LAST BILL CHANGE: ', lastGlobalBill, currentUser.claroProfile.lastGlobalBill)
            setLastGlobalBill(currentUser.claroProfile.lastGlobalBill);
        }
    }, [lastGlobalBill, currentUser.claroProfile.lastGlobalBill]);

    useEffect(() => {
        return () => {
            GraphQlClient.recallCurrentUser();
        }
    }, []);

    const handleMonthChange = (_month: number) => {
        if (isLoading) {
            return;
        }
        setLastMonth(_month);
        setAllOrders([]);


        const momentBase = !!currentUser.claroProfile.lastGlobalBill ? moment(currentUser.claroProfile.lastGlobalBill) : moment(Date.now()).startOf('month');
        const momentApplicable = _month === 0 ? momentBase : (_month === 1 ? moment().startOf('month') : moment().subtract(1, 'month').startOf('month'));
        
        lazilyGetOrders({
            variables: {
                start: momentApplicable.toDate().getTime(),
            }
        })
    }

    const formatLastBill = () => {
        if(lastMonth){
            return (lastMonth === 1 ? moment().startOf('month') : moment().subtract(1, 'month').startOf('month')).format('D MMMM, YYYY');
        }
        const lastBill = temporalLastGlobal ? moment(temporalLastGlobal) : null;
        if (!lastBill) {
            return 'Ninguno';
        }
        return `${lastBill.format('D MMMM, YYYY')} (${lastBill.fromNow()})`;
    }

    const toggleSelect = (orderId: string) => (value: boolean) => {
        const payload: { [key: string]: boolean } = {};
        payload[orderId] = !value;
        dispatchUnSelected({ type: 'set', payload });
    }

    const testAllSelected = () => {
        return Object.keys(unSelectedDict).reduce((p, c) => {
            return p && !unSelectedDict[c]
        }, true)
    }

    const setAllSelect = (val: boolean) => {
        if (val) {
            dispatchUnSelected({ type: 'all', payload: {} });
        } else {
            const payload = allOrders.reduce((p, c) => {
                p[c.order.nopedido] = true;
                return p;
            }, {} as { [key: string]: boolean });
            dispatchUnSelected({ type: 'set', payload });
        }
    }

    const propagateStampedGroup = (orderIds: string[], uuid: string, lastDate: number) => {
        const idsSet = new Set(orderIds);
        const _allOrders = allOrders.filter((ord) => !idsSet.has(ord.order.nopedido));
        setAllOrders(JSON.parse(JSON.stringify(_allOrders)));
        if (temporalLastGlobal < lastDate) {
            setTemporalLastGlobal(lastDate);
        }
    }

    const groupingOptions = [
        { label: 'Día', value: 1 },
        { label: 'Semana', value: 7 },
        { label: 'Mes', value: 30 },
    ]

    const filteredOrders = !search ? allOrders.filter((o, i) => i < 35) : allOrders.filter(o => (`${o.order.nopedido}`).includes(search)).filter((o, i) => i < 50)

    return (
        <div id="StampGlobal">
            <SectionTitleBar currentUser={currentUser} title={`Facturación global`} />
            <div className="globalContent">
                <div className="monthSelect">
                    <Select disabled={isLoading} label={"Cambiar mes de facturación"} value={lastMonth} onChange={handleMonthChange} options={MONTH_SELECT} />
                    <div className="loadingProgress">
                        <Loading display={isLoading} svgHeight={'40px'} svgWidth={'40px'} relativePos={true} />
                        {
                            isLoading ?
                                <p>
                                    Cargando tus ordenes
                                </p>
                                : ''
                        }
                    </div>
                </div>
                <div className="infoDetails">
                    <div className="lastGlobal">
                        <p>{!lastMonth ? <b>Última facturación global:</b> : <b className='_changed'>Fecha para facturación global</b> } <br /> {formatLastBill()}</p>
                        <p><b>Pedidos sin reclamar factura:</b> <br />{allOrders.length} </p>
                    </div>
                    <Select label={"Agrupar pedidos por"} value={grouping} onChange={setGrouping} options={groupingOptions} />
                </div>
                <div className="billGroupingContainer">
                    <div className="card selector">
                        <div className="cardTitle">
                            <h3>
                                Pedidos sin facturar
                            </h3>
                        </div>
                        <div className="multiSelect">
                            <Checkbox color={'#a262c8'} disabled={false} label='Seleccionar todos' value={testAllSelected()} onChange={setAllSelect} />
                        </div>
                        <div className="listedSelect">
                            <Input label="Buscar número de orden" type="text" value={search} onChange={setSearch} placeholder={'Buscar ID'} />
                            {
                                filteredOrders.map((order, index) => {
                                    return (
                                        <div className="checkListWrap" key={order.order.nopedido} >
                                            <Checkbox disabled={false} label={`#${order.order.nopedido}`} value={!unSelectedDict[order.order.nopedido]} onChange={toggleSelect(order.order.nopedido)} />
                                            <span className="checkTime">{moment(order.order.fechaautorizacion).fromNow()}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <StampGlobalDropdown allOrders={allOrders} unSelectedDict={unSelectedDict} testAllSelected={testAllSelected} setAllSelect={setAllSelect} toggleSelect={toggleSelect} filteredOrders={filteredOrders} search={search} setSearch={setSearch}/>
                    <StampGlobalGrouping currentUser={currentUser} propagateStampedGroup={propagateStampedGroup} unSelectedDict={unSelectedDict} toggleSelect={toggleSelect} orders={allOrders} grouping={grouping} lugarExpedicion={currentUser.fiscalpopProfile.lugarExpedicion} />
                </div>
            </div>
        </div>
    )
}

export default StampGlobal;