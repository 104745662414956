import React from 'react';


import './Setup.logoUpload.scss';
import ProfileAvatar from '../visuals/avatar';
import { ProfileQL } from '../../models/Profile';

function SetupLogoUpload({ currentUser }: { currentUser: ProfileQL }) {
   
    return (
        <div className="" id="SetupLogoUpload">
           <ProfileAvatar currentUser={currentUser}/>
        </div>
    )
}

export default SetupLogoUpload;