import React, { useEffect, useState } from 'react';

import './Stamp.single.billed.scss';
import { ClaroOrderDetailBinded } from '../../models/Orders';
import { ProfileQL } from '../../models/Profile';
import { FiscalpopCFDI } from '../../models/Factura';
import { numberToCurrencyString } from '../../services/formatting';
import Button from '../Forms/Button';
import Loading from '../Animations/loadScreen';

import Modal from 'react-modal';
import { useMutation } from '@apollo/client';
import { graphqlSchema } from '../../services/graphql.schema';
import { Redirect } from 'react-router-dom';
import sharedToasterSubject from '../../services/shared.toasterSubject';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh',
        padding: '1em 2em',
        boxShadow: '0px 0px 6px -1px rgba(0, 0, 0, 0.32)'
    }
};

async function fetchCFDI(authToken: string, uuid: string) {
    return fetch(`https://api.fiscalpop.com/api/v1/cfdi/find/${authToken}/${uuid}`)
        .then(async response => {
            if (response.ok) {
                const json = await response.json();
                return json;
            } else {
                const err = await response.text();
                throw err;
            }
        })
        .then((cfdiResponse: FiscalpopCFDI[]) => {
            return cfdiResponse[0];
        })
}


function StampSingleBilled({ orderBilled, currentUser, }: { orderBilled: ClaroOrderDetailBinded, currentUser: ProfileQL }) {
    console.log('[StampSingleBilled] orderBilled: ', orderBilled);
    const [cfdis, setCfdis] = useState<FiscalpopCFDI[]>([]);

    const [cancelModalOpen, setCancelModalOpen] = useState(false);
    const [uuidToCancel, setUuidToCancel] = useState('')
    const [wasCancelled, setWasCancelled] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);

    const xmlLinkRef = React.createRef<HTMLAnchorElement>();
    const pdfLinkRef = React.createRef<HTMLAnchorElement>();

    const [setCancel] = useMutation(graphqlSchema.FISCALPOP.CFDI.setBillToCancel, {
        onCompleted: ({ setBillToCancel }: { setBillToCancel: { folios: { estatus: number; uuid: string }[] } }) => {
            console.log('Canceled CFDI: ', setBillToCancel);
            // Redirect user?
            setWasCancelled(true);
            setIsCancelling(false);
            sharedToasterSubject.next({ type: 'confirm', message: 'Se canceló la factura' });
        },
        onError: (e) => {
            setIsCancelling(false);
            console.error('Error canceling cfdi: ', e);
            sharedToasterSubject.next({ type: 'error', message: 'Error al cancelar la factura' });
        }
    })

    useEffect(() => {
        if (!!orderBilled && !!currentUser && !!currentUser.claroProfile) {

            Promise.all(orderBilled.binders.map((currBinder) => fetchCFDI(currentUser.claroProfile.authToken, currBinder.billUUID)))
                .then((allCfdiRes) => {
                    console.log('CFDIs: ', allCfdiRes);
                    setCfdis(allCfdiRes);
                })
                .catch((e) => {
                    console.error('Error fetching cfdis: ', e);
                })
        }
    }, [orderBilled, currentUser]);


    const openPdf = () => {
        pdfLinkRef.current.click();
    }
    const openXml = () => {
        xmlLinkRef.current.click();
    }

    const requestCancelCfdi = (uuid: string) => () => {
        setUuidToCancel(uuid);
        setCancelModalOpen(true);
    }

    const cancelCFDI = () => {
        if (!uuidToCancel) {
            return;
        }
        setCancelModalOpen(false);
        setIsCancelling(true);
        setCancel({
            variables: {
                uuid: uuidToCancel
            }
        })

    }

    const renderCancelDialog = () => {
        return (
            <Modal
                isOpen={cancelModalOpen}
                onRequestClose={() => setCancelModalOpen(false)}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Cancelar Factura"
            >
                <div className="CancelWarning">
                    <h3>
                        Realmente quieres cancelar tu factura ?
                    </h3>
                    <p className="warning">{uuidToCancel}</p>
                    <p>
                        Este UUID quedará cancelado pero podrás volver a facturar los pedidos en otro UUID.
                    </p>
                    <div className="actions">
                        <Button primary={true} handleClick={() => setCancelModalOpen(false)}>
                            <span>Regresar</span>
                        </Button>
                        <Button accent={true} disabled={isCancelling} handleClick={cancelCFDI}>
                            {
                                isCancelling ?
                                    <span>Cancelando</span>
                                    :
                                    <span>Cancelar factura</span>
                            }
                        </Button>
                    </div>
                </div>
            </Modal>
        )
    }

    const renderCfdi = (cfdiEntry: FiscalpopCFDI, guia: string) => {
        return (
            <div className="cfdiInform" key={guia}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" className="_inform">
                    <path d="M18 17H6v-2h12v2zm0-4H6v-2h12v2zm0-4H6V7h12v2zM3 22l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20z" />
                </svg>
                <p className="_inform">
                    La guía de esta órden ya fué facturada
                </p>
                <div className="card _cfdiBilled">
                    <div className="_label">
                        <p className="_key">UUID:</p>
                        <p className="_value accent">{cfdiEntry.uuid}</p>
                    </div>
                    <div className="_label">
                        <p className="_key">Guia Envío ClaroShop:</p>
                        <p className="_value accent">{guia || 'N/A'}</p>
                    </div>
                    <div className="_label">
                        <p className="_key">Folio</p>
                        <p className="_value">{cfdiEntry.json['@']['Folio']}</p>
                    </div>
                    <div className="_label">
                        <p className="_key">Serie</p>
                        <p className="_value">{cfdiEntry.json['@']['Serie']}</p>
                    </div>
                    <div className="_label">
                        <p className="_key">Forma Pago</p>
                        <p className="_value">{cfdiEntry.json['@']['FormaPago']}</p>
                    </div>
                    <div className="_label">
                        <p className="_key">Lugar Expedición</p>
                        <p className="_value">{cfdiEntry.json['@']['LugarExpedicion']}</p>
                    </div>
                    <div className="_label">
                        <p className="_key">Fecha</p>
                        <p className="_value">{cfdiEntry.json['@']['Fecha']}</p>
                    </div>
                    <div className="_label">
                        <p className="_key">Subtotal</p>
                        <p className="_value">$ {numberToCurrencyString(parseFloat(cfdiEntry.json["@"].SubTotal))}</p>
                    </div>
                    <div className="_label">
                        <p className="_key">Descuento</p>
                        <p className="_value">$ {numberToCurrencyString(parseFloat(cfdiEntry.json["@"]['Descuento'] || '0'))}</p>
                    </div>
                    <div className="_label">
                        <p className="_key">Total</p>
                        <p className="_value">$ {numberToCurrencyString(parseFloat(cfdiEntry.json["@"].Total))}</p>
                    </div>
                    <div className="_label">
                        <p className="_key">Impuestos Trasladados</p>
                        <p className="_value">$ {numberToCurrencyString(parseFloat(cfdiEntry.json["cfdi:Impuestos"]["@"].TotalImpuestosTrasladados))}</p>
                    </div>
                    <div className="_label">
                        <p className="_key">Estado</p>
                        <p className={`_value ${cfdiEntry.status ? '' : 'accent'}`}>{cfdiEntry.status ? 'Emitida' : 'Cancelada'}</p>
                    </div>
                    <div className="_label _whole">
                        <p className="_key">Sello</p>
                        <p className="_value">{cfdiEntry.json["@"].Sello}</p>
                    </div>
                </div>
                <div className="cfdiDownloads">
                    <Button tertiary={true} handleClick={requestCancelCfdi(cfdiEntry.uuid)}>
                        <span>Cancelar CFDI</span>
                    </Button>
                    <div></div>
                    <div className="__links">
                        <a ref={xmlLinkRef} rel="noopener noreferrer" href={`https://api.fiscalpop.com/api/v1/cfdi/download/xml/${currentUser.claroProfile.authToken}?uuid=${cfdiEntry.uuid}`} download={true}>Descargar XML</a>
                        <a ref={pdfLinkRef} rel="noopener noreferrer" href={`https://api.fiscalpop.com/api/v1/cfdi/download/pdf/${currentUser.claroProfile.authToken}?uuid=${cfdiEntry.uuid}`} download={true}>Descargar PDF</a>
                    </div>
                    <Button secondary={true} handleClick={openXml}>
                        <span>Descargar XML</span>
                    </Button>
                    <Button primary={true} handleClick={openPdf}>
                        <span>Descargar PDF</span>
                    </Button>
                </div>
                {renderCancelDialog()}
            </div>
        )
    }

    return (
        <div id="StampSingleBilled">
            {
                (cfdis.length && currentUser.claroProfile) ?
                    orderBilled.binders.map((binder) => {
                        const cfdi = cfdis.find(c => c.uuid === binder.billUUID);
                        return renderCfdi(cfdi, binder.guia)
                    })
                    : <Loading display={true} />
            }
            {
                wasCancelled ?
                    <Redirect to='/ordenes' />
                    : ''
            }
        </div>
    )
}

export default StampSingleBilled;