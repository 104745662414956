import { BillConceptos } from './../models/Factura';


export const calculateTotalFromConcepts = (conceptos: BillConceptos[]) => {
    const {subtotal, iva, iepsCuota, iepsTasa} = conceptos.reduce((summary, concepto) => {
        // Taxes =====
        const taxes = concepto.impuestos;
        const iva = taxes.find(t => t.type === 'iva');
        const ieps = taxes.filter(t => t.type === 'ieps');
        const iepsCuota = ieps.reduce((p, c) => p += (c.cuota || 0), 0);
        const iepsTasa = ieps.reduce((p, c) => p += (c.tasa || 0), 0);

        summary.subtotal += (concepto.valorUnitario * concepto.cantidad);
        summary.iepsCuota += iepsCuota;
        summary.iepsTasa += (iepsTasa * (concepto.valorUnitario * concepto.cantidad));
        summary.iva += (iva.tasa * (((1 + iepsTasa) * (concepto.valorUnitario * concepto.cantidad)) + iepsCuota));
        return summary;
    }, { subtotal: 0, iva: 0, iepsCuota: 0, iepsTasa: 0});
    return {
        subtotal, 
        iva, 
        iepsCuota, 
        iepsTasa,
        total: (subtotal + iepsCuota + iepsTasa + iva)
    }
}