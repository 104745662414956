import React, { useState } from 'react';

import './Support.scss';
import { BaseReact } from '../../base.model';
import { ProfileQL } from '../../models/Profile';
import SectionTitleBar from '../components/titlebar';
import Input from '../Forms/Input';
import TextArea from '../Forms/TextArea';
import Button from '../Forms/Button';
import { useMutation } from '@apollo/client';
import { graphqlSchema } from '../../services/graphql.schema';
import sharedToasterSubject from '../../services/shared.toasterSubject';


interface SupportProps extends BaseReact {
    currentUser: ProfileQL;
}

function Support({ history, location, currentUser }: SupportProps) {
    const [subject, setSubject] = useState('');
    const [text, setText] = useState('');
    const [emailResponse, setEmailResponse] = useState(currentUser.claroProfile.email || '');
    const [supportSending, setSupportSending] = useState(false);
    const [supportSuccess, setSupportSuccess] = useState(false);

    const [sendSupport] = useMutation(graphqlSchema.FISCALPOP.SUPPORT.sendSupportEmail, {
        onCompleted: ({ sendSupportEmail }: { sendSupportEmail: {success:boolean} }) => {
            console.log('Support request: ', sendSupportEmail);
            setSubject('');
            setText('');
            setEmailResponse('')
            setSupportSuccess(sendSupportEmail.success);
            setSupportSending(false);
            sharedToasterSubject.next({ type: 'confirm', message: `Mensaje enviado! nos comunicaremos al correo ${emailResponse}` });
        },
        onError: (e) => {
            console.error('Error Support request: ', e);
            setSupportSuccess(false);
            setSupportSending(false);
        }
    });

    const askForSupport = () => {
        setSupportSending(true);
        sendSupport({
            variables: {
                support: {
                    text,
                    subject,
                    emailResponse
                }
            }
        })
    }

    return (
        <div id="Support">
            <SectionTitleBar currentUser={currentUser} title="Solicita soporte técnico" />
            <div className="supportContent">
                <div className="formContain">
                    <Input type="text" value={emailResponse} onChange={setEmailResponse} placeholder="Correo a donde responderemos" label={'Correo a donde responderemos'} />
                    <Input type="text" value={subject} onChange={setSubject} placeholder="Tema en que requieres soporte" label={'Tema de soporte'} />
                    <TextArea value={text} placeholder="Escribe tu mensaje y nos pondremos en contacto lo antes posible" label="¿Cómo podemos ayudarte?" onChange={setText} />
                    <Button primary={true} disabled={!(!!subject && !!text && !!emailResponse) || supportSuccess || supportSending} handleClick={askForSupport}>
                        <span>{supportSuccess ? 'Recibimos tu solicitud' : (supportSending ? 'Enviando...' : 'Solicitar soporte')}</span>
                    </Button>
                </div>
                <div className="instructionsContain">
                    <h3>Soporte fiscalpop</h3>
                    <p>
                        Intentamos contestar dentro de máximo 1 hora desde tu solicitud
                        dentro de horario regular, y 5 horas en fines de semana
                    </p>
                    <p>
                        Si tienes una inquietud o propuesta de mejora con gusto comunícala 
                        por este medio.
                    </p>
                    <p>
                        <b>Atención regular:</b>
                        <br />
                        Lun - Vier: 9AM - 9PM
                        <br />
                    </p>
                    <p>
                        <b>Fines de semana:</b>
                        <br />
                        Sab y Dom: 9AM - 2PM
                        <br />
                    </p>
                    <p>
                        <b>Correo directo de soporte:</b>
                        <br />
                        <a href="mailto:soporte@fiscalpop.com">soporte@fiscalpop.com</a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Support;