/* eslint-disable no-mixed-operators */
import React, { useState } from "react";

import './Input.cardForm.scss';
import Checkbox from "./Checkbox";

interface SelectProps {
    onChange: Function;
    label?: string;
    values: any[];
    disabled?: boolean;
    hasError?: boolean;
    displayLabel?: boolean;
    charLimit?: number;
    placeholder?: string;
    options: { label: string, display?: string; value: any, disabled?: boolean }[];

}

const SelectCardMultiple = (props: SelectProps) => {
    const [active, setActive] = useState(false);


    const activate = () => {
        if (props.disabled) {
            return;
        }
        setActive(true);
    }

    const deactivate = () => {
        setActive(false);
    }

    const checkedValue = (optionValue: any) => {
        return (bool: boolean) => {
            if (bool) {
                const exists = props.values.some(v => v === optionValue);
                if (exists) {
                    // Do nothing, it exists
                } else {
                    // Just add this value
                    props.onChange(JSON.parse(JSON.stringify(props.values.concat([optionValue]))))
                }
            } else {
                const exists = props.values.some(v => v === optionValue);
                if (exists) {
                    // Just omit this value
                    props.onChange(props.values.filter(v => v !== optionValue))
                } else {
                    // Do nothing, it doesn't exists
                }

            }
        }
    }
    const classInput = `inputWrapper ${props.disabled ? 'disabled' : ''} ${active ? 'active' : ''} ${props.hasError ? 'error' : ''}`;
    const opt = props.options.filter(o => props.values.some(v => v === o.value));

    return (
        <div className="inputCardBlock" >
            {
                !!props.label ?
                    <label>
                        {props.label}
                    </label>
                    : <label></label>
            }
            < div className={classInput} >
                <div className="selectContent" onClick={activate}>
                    <span>{`${opt.length} seleccionados` || props.placeholder || ''}</span>
                </div>
            </div >
            {
                active ?
                    <div className="_overlay" onClick={deactivate} />
                    : ''
            }
            <div className={`selectMultiple ${active ? 'active' : ''}`}>
                {props.options.map((e, i) => {
                    const val = props.values.some(v => v === e.value);
                    return (
                        <Checkbox disabled={!!e.disabled} key={i} value={val} onChange={checkedValue(e.value)} label={e.label} color="#AC2E78" />
                    )
                })}
            </div>
        </div >
    )

}


export default SelectCardMultiple;