import React, { useRef, useState } from 'react';

import './input.scss';

interface InputProps {
    type: 'password' | 'text';
    value: string;
    label: string;
    hasError?: boolean;
    onChange: (value: string) => void
}

const InputAnimated = ({
    type,
    value,
    label,
    hasError,
    onChange,
}: InputProps) => {
    const [isFocused, setFocused] = useState(false);
    const inputRef = useRef(null)

    const handleChange = (e: any) => {
        const v = e?.target?.value;
        console.log('V: ', v);
        onChange(v);
    }

    const focusOnInput = () => {
        inputRef?.current.focus();
    }

    const focus = () => setFocused(true)
    const blur = () => setFocused(false)

    return (
        <div
            onClick={focusOnInput}
            className={`inputWrapperAnimated ${isFocused ? 'active' : ''} ${!value && !isFocused ? 'empty' : ''} ${hasError ? 'error' : ''}`}>
            <label>
                {label}
            </label>
            <input
                type={type}
                onChange={handleChange}
                value={value}
                onFocus={focus}
                onBlur={blur}
                ref={inputRef}
            />
        </div>
    )
}

export default InputAnimated;