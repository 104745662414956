import React, { useState, FunctionComponent } from 'react';

import './MenuComponent.scss';

interface ItemMenuProps {
    triggerContent: React.ReactNode;
    actionWillRedirect?: boolean;
    noPadding?: boolean;
}

const ItemMenu: FunctionComponent<ItemMenuProps> = ({ children, actionWillRedirect, triggerContent, noPadding }) => {
    const [opened, setOpened] = useState(false);

    const open = () => {
        setOpened(true);
    }
    
    const close = () => {
        setOpened(false);
    }

    const pseudoBlur = () => {
        if(actionWillRedirect){
            // Avoids setState on unmounted ItemMenu error
            return;
        }
        setTimeout(() => {
            close()
        }, 225);
    }

    return (
        <div className="__ItemMenu">
            <div className="_trigger" onClick={open}>
                {triggerContent}
            </div>
            {
                opened ?
                    <div className="_itemOverlay" onClick={close}></div>
                    : ''
            }
            <div className={`_contentCollapse ${opened ? 'opened' : 'closed'} ${noPadding ? 'noPadding' : ''}`} onClick={pseudoBlur}>
                {children}
            </div>
        </div>
    )
}

export default ItemMenu;