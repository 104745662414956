import React, { useState, useEffect } from 'react';

import './ToasterNotifications.scss';
import sharedToasterSubject from '../../services/shared.toasterSubject';

export default function ToasterNotifications() {
    const [notificationSub] = useState(sharedToasterSubject);
    const [toaster, setToaster] = useState<{ type: 'warning' | 'confirm' | 'error', message: string, id?: string }[]>([]);
    const [currentToast, setCurrentToast] = useState<string>(null);
    const [toastAnimateOut, setToastAnimateOut] = useState<boolean>(false);

    useEffect(() => {
        const subs = notificationSub.subscribe((toast) => {
            toast.id = Math.random().toString(32).split('.')[1];
            setToaster(toaster => toaster.concat([toast]));
            // setCurrentToast(currId => !currId ? toast.id : currId);
        })

        return () => {
            subs.unsubscribe()
        }
    }, [notificationSub])

    useEffect(() => {
        console.log('[TOASTER] UE', currentToast, toaster);
        if (!currentToast && toaster.length) {
            const toast = toaster[0]
            setCurrentToast(toast.id);
            setTimeout(() => {
                setToastAnimateOut(true);
            }, 2600);
            setTimeout(() => {
                toaster.shift();
                setToaster(toaster);
                setCurrentToast(null);
                setToastAnimateOut(false);
            }, 3000);
        }
    }, [toaster, currentToast]);
    
    const toastToUse = !currentToast ? null : toaster.find(t => t.id === currentToast);
    return (
        <div id="TOASTER">
            {
                toastToUse ?
                <div className={`_toast ${toastToUse.type} ${toastAnimateOut ? '_getOut' : ''}`}>
                    <p>
                        {toastToUse.message}
                    </p>    
                </div>
                : ''
            }
        </div>
    )
}