import React, { useEffect, useState } from 'react';
import Button from '../Forms/Button';
import { ConektaCustomer_PaymentSource } from '../../models/Conekta';

import './Account.paymentMethod.scss'
import { useMutation } from '@apollo/client';
import { graphqlSchema } from '../../services/graphql.schema';

interface AccountCardProps {
    onErrase: (selectedId: string)  => void;
    selectCard: (selectedId: string) => () => void;
    method: ConektaCustomer_PaymentSource;
    selectedCardId: string;
}

function AccountCard({ selectCard, method, selectedCardId, onErrase }:AccountCardProps) {
    const cardRef = React.createRef<HTMLDivElement>();
    const cardRemoveRef = React.createRef<HTMLDivElement>();
    const [cardDisabled, setCardDisabled] = useState(false);
    useEffect(() => {
        cardRef.current.style.setProperty("--scalecard-ratio", '1')
        if(cardRef && cardRemoveRef){
            const cardBounding = cardRef.current.getBoundingClientRect();
            const cardRemoveBounding = cardRemoveRef.current.getBoundingClientRect();
            cardRef.current.style.setProperty("--scalecard-ratio", (1 - (cardRemoveBounding.width / cardBounding.width)).toFixed(3))
        }
    }, [cardRef, cardRemoveRef]);
    const [requestCardRemoved] = useMutation(graphqlSchema.CONEKTA.CARDS.removeCard, {
        onCompleted: ({ removeCard }: { removeCard: ConektaCustomer_PaymentSource }) => {
            console.log('Card Removed: ', removeCard);
            // UseReducer to update object array
            setCardDisabled(false);
            onErrase(removeCard.id);
        },
        onError: (e) => {
            console.error('Error Removing card: ', e);
            setCardDisabled(false);
        }
    })
    const removeCard = (cardId: string) => {
        return () => {
            setCardDisabled(true);
            requestCardRemoved({
                variables:{
                    paymentId: cardId
                }
            })
        }
    }

    return (
        <div ref={cardRef} className={`_cardContainer ${method.id === selectedCardId ? 'selected' : ''}`}>
            <div ref={cardRemoveRef} className="_removeContain">
                <Button handleClick={removeCard(method.id)} disabled={cardDisabled}>
                    <i className="material-icons _false">
                        delete
                    </i>
                </Button>
            </div>
            <div className="_card" onClick={selectCard(method.id)}>
                <div className="_naming">
                    <p className="_name">
                        {method.name}
                    </p>
                    <p className="_exp">
                        {method.exp_month}/{method.exp_year}
                    </p>
                </div>
                <div className="_last">
                    <p className="_last4">
                        {method.last4}
                    </p>
                    <p className="_brand">
                        {method.brand}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AccountCard;