import React, { useState, useEffect, useReducer } from 'react';

import './Stamp.single.scss';
import { BaseReact } from '../../base.model';
import { ProfileQL } from '../../models/Profile';
import { useQuery, useMutation } from '@apollo/client';
import { graphqlSchema } from '../../services/graphql.schema';
import { ClaroOrderBinder, ClaroOrderDetailBinded } from '../../models/Orders';
import SectionTitleBar from '../components/titlebar';
import Select from '../Forms/Select';
import Input from '../Forms/Input';
import Loading from '../Animations/loadScreen';
import { numberToCurrencyString } from '../../services/formatting';
import { Bill, BillBase, BillReceptor, BillConceptos, BillRelacionados } from '../../models/Factura';

import { calculateTotalFromConcepts } from '../../services/calculations.cfdi';
import Button from '../Forms/Button';
import StampSingleBilled from './Stamp.single.billed';
import { FiscalPopError, FiscalPopErrorBadReq } from '../../models/Fiscalpop';
import { Link } from 'react-router-dom';
import sharedToasterSubject from '../../services/shared.toasterSubject';
import { orderDetailToConceptos } from '../../utils/stamp.ordersToConceptos';
import { formatStampingError } from '../../utils/formatting';
import { REGIMEN_OPTIONS } from '../../utils/regimen';

interface SetupProps extends BaseReact {
    currentUser: ProfileQL;
}


function cfdiReducer(state: any, action: { type: 'receptor' | 'conceptos' | 'comprobante' | 'relacionados'; payload: BillBase | BillReceptor | BillConceptos[] | BillRelacionados }): Bill {
    switch (action.type) {
        case 'receptor':
            const receptor = Object.assign({}, state.receptor, action.payload as BillReceptor);
            state.receptor = receptor;
            return Object.assign({}, state);
        case 'conceptos':
            state.conceptos = action.payload as BillConceptos[];
            console.log('<StampSingle> CFDI Reducer, conceptos: ', state);
            return Object.assign({}, state);
        case 'comprobante':
            console.log('<StampSingle> cfdiReducer (COMPROBANTE): ', action.payload);
            return Object.assign({}, state, action.payload as BillBase)
        case 'relacionados':
            const cfdiRelacionados = Object.assign({}, state.cfdiRelacionados || {}, action.payload as BillRelacionados);
            state.cfdiRelacionados = cfdiRelacionados;
            return Object.assign({}, state);
        default:
            return state;
    }
}

function StampSingle({ history, location, currentUser }: SetupProps) {
    // CFDI ========
    const [cfdi, assignSetCfdi] = useReducer(cfdiReducer, {
        fecha: new Date().toJSON(),
        serie: 'CLARO-SNG',
        folio: '01',
        formaPago: '31',
        metodoPago: 'PUE',
        lugarExpedicion: currentUser.fiscalpopProfile.lugarExpedicion || '',
        tipoDeComprobante: 'I',
        receptor: {
            nombre: 'PUBLICO EN GENERAL',
            rfc: 'XAXX010101000',
            usoCFDI: 'S01',
            regimen: '616',
            zip: currentUser.fiscalpopProfile.lugarExpedicion
        },
        conceptos: [],
    } as Bill);

    const setComprobanteProp = (property: 'fecha' | 'serie' | 'folio' | 'formaPago' | 'metodoPago' | 'lugarExpedicion') => {
        return (value: string | 'PUE' | 'PPD') => {
            console.log(`<StampSingle> setComprobanteProp - ${property}: `, cfdi);
            const { fecha, serie, folio, formaPago, metodoPago, lugarExpedicion, tipoDeComprobante } = cfdi;
            const newCfdi: BillBase = { fecha, serie, folio, formaPago, metodoPago, lugarExpedicion, tipoDeComprobante };
            if (property === 'metodoPago') {
                newCfdi[property] = value as 'PUE';
            } else {
                newCfdi[property] = value;
            }
            assignSetCfdi({ type: 'comprobante', payload: newCfdi });
        }
    }
    const setReceptorProp = (property: 'nombre' | 'rfc' | 'usoCFDI' | 'email' | 'zip' | 'regimen') => {
        return (value: string) => {
            const newReceptor = cfdi.receptor;
            if (property === 'rfc') {
                newReceptor[property] = value.toUpperCase();
            }
            else if (property === 'zip') {
                newReceptor[property] = value.toLowerCase().trimLeft().trimRight();
            }
            else if (property === 'email') {
                newReceptor[property] = value.toLowerCase();
            }
            else {
                newReceptor[property] = value;
            }
            assignSetCfdi({ type: 'receptor', payload: newReceptor });
        }
    }
    const [orderToBill, setOderToBill] = useState<ClaroOrderDetailBinded>(null);
    const [dateOffset, setDateOffset] = useState<number>(0);
    const [fpopError] = useState<FiscalPopError>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    // =============
    const [usosCFDIoptions, setUsosCFDIoptions] = useState<{ clave: string, descripcion: string }[]>([]);
    const [formaPagoOptions, setFormaPagoOptions] = useState<{ key: string, label: string }[]>([]);

    useQuery(graphqlSchema.PROFILE.ORDERS.getOrderById, {
        variables: {
            id: location.pathname.replace('/ordenes/stamp/', '')
        },
        onCompleted: ({ getOrderById }: { getOrderById: ClaroOrderDetailBinded }) => {
            console.log('<GetOrders> Get Orders: ', getOrderById);
            setOderToBill(getOrderById);
            assignSetCfdi({ type: 'conceptos', payload: orderDetailToConceptos(getOrderById.order, getOrderById.binders, currentUser.claroProfile) })
            //dispatchOrders({ type: 'set', payload: getOrders });
        },
        onError: (e) => {
            // setIsLoading(false);
            console.error('E Error: ', e);
            console.error('E graphQLErrors: ', e.graphQLErrors[0].message);
            sharedToasterSubject.next({ type: 'error', message: e.graphQLErrors[0].message });
        },
        fetchPolicy: 'network-only'
    })

    const [stampCfdi] = useMutation(graphqlSchema.FISCALPOP.CFDI.setBillToStamp, {
        onCompleted: ({ setBillToStamp }: { setBillToStamp: ClaroOrderBinder[] }) => {
            console.log('BILL SUBMITTED: ', setBillToStamp);
            setIsSubmitting(false);
            const binded = Object.assign({}, orderToBill, { binder: setBillToStamp[0], binders: setBillToStamp });
            setOderToBill(binded);
        },
        onError: (e) => {
            // setIsLoading(false);
            setIsSubmitting(false);
            console.error('<Stamp> E Error: ', e);
            console.error('<Stamp> E graphQLErrors: ', e.graphQLErrors[0].message);
            sharedToasterSubject.next({ type: 'error', message: formatStampingError(e) });
        },
    })

    const fetchUsoCfdi = (authToken: string) => {
        fetch(`https://api.fiscalpop.com/api/v1/sat/usoCfdi/${authToken}`)
            .then(d => d.json())
            .then(data => setUsosCFDIoptions(data))
            .catch(e => {

            });
    }

    const fetchFormasPago = (authToken: string) => {
        fetch(`https://api.fiscalpop.com/api/v1/sat/payTypes/${authToken}`)
            .then(d => d.json())
            .then(data => setFormaPagoOptions(data))
            .catch(e => {

            });
    }

    const setDateOverride = (dateOffset: 0 | 1 | 2 | 3) => {
        setDateOffset(dateOffset);
        if (dateOffset === 0) {
            const fecha = new Date().toJSON();
            setComprobanteProp('fecha')(fecha);
        } else {
            const fecha = new Date(Date.now() - (dateOffset * 24 * 60 * 60 * 1000) + (20 * 1000) - (1000 * 60 * 60 * 1)).toJSON();
            setComprobanteProp('fecha')(fecha);
        }
    }

    useEffect(() => {
        setComprobanteProp('folio')(`${location.pathname.replace('/ordenes/stamp/', '')}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    useEffect(() => {
        fetchFormasPago(currentUser.claroProfile.authToken);
        fetchUsoCfdi(currentUser.claroProfile.authToken);
    }, [currentUser]);


    const hasError = (property: string, elementIndex?: number, elementProperty?: string) => {
        if (!fpopError) {
            return false
        }
        const brqCandidate = fpopError.badRequests.find(brq => !!brq[property]);
        if (!brqCandidate) {
            return false;
        } else if (!elementIndex && elementIndex !== 0) {
            return true;
        }
        const _elementCandidate: FiscalPopErrorBadReq[] = brqCandidate[property] as FiscalPopErrorBadReq[];
        const elementCandidate = _elementCandidate[elementIndex];
        if (!elementCandidate) {
            return false;
        } else if (!elementProperty) {
            return true;
        }
        return !!elementCandidate.property && (elementCandidate.property === elementProperty) ? true : false;
    }

    const dateOffsetOptions = [
        { value: 0, label: 'Hoy mismo' },
        { value: 1, label: 'Hace 1 día' },
        { value: 2, label: 'Hace 2 días' },
        { value: 3, label: 'Hace 3 días' },
    ];
    const formasOptions = formaPagoOptions.map(f => ({ label: `(${f.key}) ${f.label}`, value: f.key }));
    const usoOptions = usosCFDIoptions.map((e) => ({ value: e.clave, label: `(${e.clave}) ${e.descripcion}` }));

    const submitCFDI = () => {
        console.log('<StampSingle> CFDI: ', cfdi);
        const pendingGuias = !orderToBill.binder ? orderToBill.order.productos.map(p => p.guia) : orderToBill.order.productos.filter(p => !orderToBill.binders.find(b => b.guia === p.guia)).map(p => p.guia);
        console.log('<StampSingle> Method Args: ', {
            cfdi,
            orderId: orderToBill.order.orderId,
            guia: pendingGuias[0],
            refundIds: []
        })

        setIsSubmitting(true);
        stampCfdi({
            variables: {
                cfdi,
                orderId: orderToBill.order.orderId,
                // Guia is sued for "Plan B"
                guia: pendingGuias[0],
                refundIds: []
            }
        });
    }


    // RENDER METHODS =============
    // ============================
    const renderTotals = () => {


        const { subtotal, iva, iepsCuota, iepsTasa, total } = calculateTotalFromConcepts(cfdi.conceptos);

        return (
            <div className="cfdiTotal">
                <p className="_subtotal">
                    <b>Subtotal:</b> $ {numberToCurrencyString(subtotal)}
                </p>
                <div className="_taxes">
                    <p><b>IVA:</b> $ {numberToCurrencyString(iva)}</p>
                    {
                        iepsCuota ?
                            <p><b>IEPS cuota:</b> $ {numberToCurrencyString(iepsCuota)}</p>
                            : ''
                    }
                    {
                        iepsTasa ?
                            <p><b>IEPS tasa:</b> $ {numberToCurrencyString(iepsTasa)}</p>
                            : ''
                    }
                </div>
                <p className="_total">
                    <b>Total: </b> $ {numberToCurrencyString(total)}
                </p>
            </div>
        )
    }

    const renderItemList = () => {
        /*
        if (!Object.keys(itemBindersMap).length || !cfdi.conceptos.length) {
            return '';
        }
        */
        return (
            <div className="itemList">
                <div className="lineItem header">
                    <p>
                        Producto
                    </p>
                    <p>
                        Precio unitario (x Cantidad)
                    </p>
                    <p>
                        Clave SAT
                    </p>
                    <p>
                        Impuestos incluidos
                    </p>
                </div>
                {
                    cfdi.conceptos.map((concepto, i) => {
                        // Taxes =====
                        const taxes = concepto.impuestos;
                        const iva = taxes.find(t => t.type === 'iva');
                        const ieps = taxes.filter(t => t.type === 'ieps');
                        const iepsCuota = ieps.reduce((p, c) => p += (c.cuota || 0), 0);
                        const iepsTasa = ieps.reduce((p, c) => p += (c.tasa || 0), 0);
                        console.log('<StampSingle> concepto: ', concepto);
                        console.log('<StampSingle> iva: ', iva);
                        // ===========
                        return (
                            <div className="lineItem" key={i}>
                                {
                                    concepto.claveProdServ === '78102203' ?
                                        <div className="itemShipping">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                                <path d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zM6 18.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm13.5-9l1.96 2.5H17V9.5h2.5zm-1.5 9c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />
                                            </svg>
                                            <p>
                                                {concepto.descripcion}
                                            </p>
                                        </div>
                                        :
                                        <p>
                                            {concepto.descripcion}
                                        </p>
                                }
                                <p>
                                    $ {numberToCurrencyString(concepto.valorUnitario)} <b>(x {concepto.cantidad})</b>
                                </p>
                                <div className="_keys">
                                    <p>{concepto.claveProdServ}</p>
                                    <p>{concepto.claveUnidad}</p>
                                    <p className="_isCustomized">{concepto.claveProdServ !== currentUser.claroProfile.defaultClaveSatProdServ ? '' : '(Default)'}</p>
                                </div>
                                <div className="_taxes">
                                    <p className="_iva">
                                        <b>IVA ({(iva.tasa * 100).toFixed(0)} %):</b> $ {numberToCurrencyString(iva.tasa * ((1 + iepsTasa) * concepto.valorUnitario))}
                                    </p>
                                    {
                                        iepsTasa ?
                                            <p className="_ieps">
                                                <b>IEPS ({(iepsTasa * 100).toFixed(0)} %): </b> $ {numberToCurrencyString(iepsTasa * concepto.valorUnitario)}
                                            </p>
                                            : ''
                                    }
                                    {
                                        iepsCuota ?
                                            <p className="_ieps">
                                                <b>IEPS (Cuota): </b> $ {(iepsCuota).toFixed(2)}
                                            </p>
                                            : ''
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const renderConceptosToStamp = () => {
        return (
            <div className="stampContent">


                <div className="card billBody">
                    <div className="row four">
                        <Select value={dateOffset} onChange={setDateOverride} label={'Fecha de factura'} options={dateOffsetOptions} />
                    </div>
                    <div className="row four">
                        <Select value={cfdi.formaPago} onChange={setComprobanteProp('formaPago')} hasError={hasError('formaPago')} label={'Forma de Pago'} options={formasOptions} />
                        <Input type="text" label="Método de pago" placeholder="Método de pago" value={`(PUE) Un solo Pago`} disabled={true} onChange={() => ''} />
                        <Input type="text" label="Lugar de expedición" placeholder="Lugar de expedición" value={cfdi.lugarExpedicion} hasError={hasError('lugarExpedicion')} onChange={setComprobanteProp('lugarExpedicion')} />
                        <div />
                    </div>
                    <div className="divider"></div>
                    <div className="row two">
                        <Input type="text" label="Razón social" placeholder="Razón social del receptor" value={cfdi.receptor.nombre} hasError={hasError('receptor', 0, 'name')} onChange={setReceptorProp('nombre')} />
                        <Input type="text" label="RFC" placeholder="RFC del receptor" value={cfdi.receptor.rfc} hasError={hasError('receptor', 0, 'rfc')} onChange={setReceptorProp('rfc')} />
                        <Input type="text" label="Código Postal" placeholder="ZIP del receptor" value={cfdi.receptor.zip} hasError={hasError('zip', 0, 'rfc')} onChange={setReceptorProp('zip')} />

                        <Select value={cfdi.receptor.regimen} onChange={setReceptorProp('regimen')} label={'Régimen'} hasError={hasError('receptor', 0, 'regimen')} options={REGIMEN_OPTIONS} />
                        <Select value={cfdi.receptor.usoCFDI} onChange={setReceptorProp('usoCFDI')} label={'Uso CFDI'} hasError={hasError('receptor', 0, 'usoCFDI')} options={usoOptions} />
                        <Input type="text" label="Email" placeholder="Email del receptor" value={cfdi.receptor.email} onChange={setReceptorProp('email')} />
                    </div>
                    <div className="divider whole"></div>
                    {renderItemList()}
                    {renderTotals()}
                </div>
                <div className="actions">
                    <div className={`_FpopErrors ${fpopError ? 'hasError' : ''}`}>
                        {
                            fpopError ?
                                <p>
                                    <b>(code: {fpopError.code}) </b> {fpopError.message}
                                </p> :
                                (!currentUser.fiscalpopProfileStatus.modulusMatch ?
                                    <p className="defaultError">
                                        <b>Falta subir CSD </b> Es necesario subir su CSD en <Link to="/setup?showcerts=true">"Mi configuración"</Link> antes de poder factuar
                                    </p>
                                    : '')
                        }
                        {
                            fpopError ?
                                <ul>
                                    {
                                        fpopError.badRequests.map((breq, i) => {
                                            const breqProp = Object.keys(breq)[0];
                                            const breqEntry = Array.isArray(breq[breqProp]) ? (breq[breqProp] as FiscalPopErrorBadReq[])[0] : breq[breqProp] as FiscalPopErrorBadReq;
                                            return (
                                                <li key={i}>
                                                    <p>
                                                        <b>{breqProp}:</b> {breqEntry.message}
                                                    </p>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                : ''
                        }
                    </div>
                    <Button primary={true} handleClick={submitCFDI} disabled={isSubmitting || !currentUser.fiscalpopProfileStatus.modulusMatch}>
                        <span>{isSubmitting ? 'Facturando...' : 'Facturar'}</span>
                    </Button>
                </div>
            </div>
        )
    }

    const isCancelled = !!orderToBill && !!orderToBill.order ? orderToBill.order.estatuspedido.estatus === 'Canceled' : false;
    const isPending = !!orderToBill && !!orderToBill.order ? orderToBill.order.estatuspedido.estatus === 'Pending' : false;
    const isPendingGuia = !!orderToBill && !!orderToBill.order ? orderToBill.order.productos.some(p => !p.guia) && !cfdi.conceptos.length : false;
    if (!orderToBill) {
        return (
            <div id="StampSingle">
                <SectionTitleBar currentUser={currentUser} title={`Facturar pedido: ${location.pathname.replace('/ordenes/stamp/', '')}`} />
                <Loading display={true} />
            </div>
        )
    } else if (!!orderToBill && !!orderToBill.binder) {

        // Partial stamping of guias is supported
        const pendingToBill = cfdi.conceptos.length;
        return (
            <div id="StampSingle">
                <SectionTitleBar currentUser={currentUser} title={`${orderToBill.order.estatuspedido.estatus !== 'Shipped' ? 'Factura de pedido' : 'Pedido Facturado'}: ${location.pathname.replace('/ordenes/stamp/', '')}`} />
                <StampSingleBilled orderBilled={orderToBill} currentUser={currentUser} />
                {pendingToBill ? <div className='divider' /> : ''}
                {pendingToBill ?
                    <div className="cfdiInform">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" className="_inform">
                            <path d="M18 17H6v-2h12v2zm0-4H6v-2h12v2zm0-4H6V7h12v2zM3 22l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20z" />
                        </svg>
                        <p className="_inform">
                            Productos pendientes de facturar
                        </p>
                    </div>
                    : ''}
                {pendingToBill ? renderConceptosToStamp() : ''}
            </div>
        )

    } else if (isCancelled) {
        return (
            <div id="StampSingle">
                <SectionTitleBar currentUser={currentUser} title={`${orderToBill.order.estatuspedido.estatus !== 'Shipped' ? 'Factura de pedido' : 'Pedido Facturado'}: ${location.pathname.replace('/ordenes/stamp/', '')}`} />
                <div className="stampContent">
                    <h1>
                        Pedido cancelado
                    </h1>
                    <div className="card">
                        <p>
                            Pedidos cancelados no pueden facturarse
                        </p>
                    </div>
                </div>
            </div>
        )
    } else if (isPending) {
        return (
            <div id="StampSingle">
                <SectionTitleBar currentUser={currentUser} title={`${orderToBill.order.estatuspedido.estatus !== 'Shipped' ? 'Factura de pedido' : 'Pedido Facturado'}: ${location.pathname.replace('/ordenes/stamp/', '')}`} />
                <div className="stampContent">
                    <h1>
                        Pedido pendiente
                    </h1>
                    <div className="card">
                        <p>
                            Pedidos pendientes de entregarse no pueden facturarse
                        </p>
                    </div>
                </div>
            </div>
        )
    } else if (isPendingGuia) {
        return (
            <div id="StampSingle">
                <SectionTitleBar currentUser={currentUser} title={`${orderToBill.order.estatuspedido.estatus !== 'Shipped' ? 'Factura de pedido' : 'Pedido Facturado'}: ${location.pathname.replace('/ordenes/stamp/', '')}`} />
                <div className="stampContent">
                    <h1>
                        Pedido pendiente de Guía
                    </h1>
                    <div className="card">
                        <p>
                            Productos pendientes de asignar guía no pueden facturarse todavía. <br />
                            Una vez se asigne una guía a los productos podran facturarse automáticamente o manualmente.
                        </p>
                        <ul className='noGuia'>
                            {orderToBill.order.productos.filter(p => !p.guia).map((p, i) => <li key={i}>{p.producto}</li>)}
                        </ul>
                    </div>
                </div>
            </div>
        )
    } else {
        console.log('<StampSingle> CFDI: ', orderToBill);
        //<CopyToClipboard textLabel="Copiar link de facturación en línea" value={`${billLink}${manualOverride ? '&override=' + orderToBill.binder.billUUID : ''}`} />
        return (
            <div id="StampSingle">
                <SectionTitleBar currentUser={currentUser} title={`Facturar pedido: ${location.pathname.replace('/ordenes/stamp/', '')}`} />
                {renderConceptosToStamp()}
            </div>
        )
    }
}

export default StampSingle;