import React, { useEffect, FormEvent, useState } from 'react';
import { ProductTaxes, ClaroItemsBinded, ClaroItemBinder } from '../../../models/Items';
import { Subject } from 'rxjs';
import { post } from '../../../services/post';
import { debounceTime } from 'rxjs/operators';

import Modal from 'react-modal';
import AutoComplete from '../../Forms/Autocomplete';
import Select from '../../Forms/Select';
import Button from '../../Forms/Button';
import { ProfileQL } from '../../../models/Profile';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        border: 'none',
        boxShadow: '1px 1px 3px 0px rgba(0, 0, 0, 0.5)',
        transform: 'translate(-50%, -50%)',
        overflow: 'visible'
    }
};
Modal.setAppElement('#root');


interface ItemModalProps {
    currentUser: ProfileQL;
    modalIsOpen: boolean;
    items: ClaroItemsBinded[];
    onClose: (save: boolean, binderForBulk: ClaroItemBinder) => void;
}


function ItemBulkEditModal({ modalIsOpen, items, onClose, currentUser }: ItemModalProps) {

    useEffect(() => {
        if (items) {
            console.log('[ITEM EDIT MODAL] UEf - item: ', items);
            // Set the most common key
            const binderStats = items.reduce((p,c) => {
                if(!c.binder){
                    return p;
                } else if(c.binder.claveProdServ){
                    if(p[c.binder.claveProdServ]){
                        p[c.binder.claveProdServ]++;
                    }else{
                        p[c.binder.claveProdServ] = 0;
                    }
                } 
                return p;
            }, {} as {[key:string]:number});
            const inOrder = Object.keys(binderStats).reduce((p,c) => {
                p.push({key:c, value: binderStats[c]});
                return p;
            }, [] as {key:string, value:number}[]).sort((a,b) => b.value - a.value)[0];

            setDefaultClaveSatProdServ(!inOrder ? '' : inOrder.key);
            setDefaultUnidadSat(currentUser.claroProfile.defaultUnidadSat);
            fetchClaves(!!inOrder ? inOrder.key : currentUser.claroProfile.defaultClaveSatProdServ);
            fetchUnidad(currentUser.claroProfile.defaultUnidadSat);
            const ivaStats = items.reduce((p,c) => {
                const iva = c.binder.taxes.find(t => t.type === 'iva');
                if(iva.tasa === 0){
                    p.ivaZero++;
                }else{
                    p.ivaSixteen++;
                }
                return p;
            }, {ivaZero: 0, ivaSixteen:0});
            if(ivaStats.ivaZero > ivaStats.ivaSixteen){
                setIva(0);
            }else{
                setIva(0.16);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items])

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        const ivaTax: ProductTaxes = {
            type: 'iva',
            tipo: 'Fijo',
            tasa: iva,
        }
        const iepsTax: ProductTaxes = !ieps ? null : {
            type: 'ieps',
            tipo: 'Fijo',
            tasa: ieps,
        }
        items.forEach(it => {
            it.binder = {
                productId: it.item.claroid,
                claveProdServ: defaultClaveSatProdServ,
                claveUnidad: defaultUnidadSat,
                taxes: [ivaTax, iepsTax].filter(t => t !== null)
            }
        })
        console.log('items Binder Taxes: ', items[0].binder.taxes);
        onClose(true, {
            productId: null,
            claveProdServ: defaultClaveSatProdServ,
            claveUnidad: defaultUnidadSat,
            taxes: [ivaTax, iepsTax].filter(t => t !== null)
        });
    }

    const onCancel = () => {
        onClose(false, null);
    }

    const [ieps, setIEPS] = useState<number>(0);
    const [iva, setIva] = useState(0.16);
    const [isLoading, setIsLoading] = useState(false);
    const [defaultClaveSatProdServ, setDefaultClaveSatProdServ] = useState('');
    const [defaultUnidadSat, setDefaultUnidadSat] = useState(currentUser.claroProfile.defaultUnidadSat || '');
    const [claveOptions, setClaveOptions] = useState<{ label: string, value: string }[]>([])
    const [unidadOptions, setUnidadOptions] = useState<{ label: string, value: string }[]>([])
    const [prodServErr, setProdServErr] = useState(false);
    const [unidadErr, setUnidadErr] = useState(false);

    const [unitSubject] = useState<Subject<string>>(new Subject());
    const [claveSubject] = useState<Subject<string>>(new Subject());

    const fetchClaves = (defaultClaveSatProdServ: string) => {
        console.log('[SETUP - DEFAULTS] Fetching claves: ', defaultClaveSatProdServ);
        setIsLoading(true);
        return post(`https://api.fiscalpop.com/api/v1/sat/productosServicios/${currentUser.claroProfile.authToken}`, {
            compare: defaultClaveSatProdServ,
            clave: defaultClaveSatProdServ
        }).then(response => {
            setIsLoading(false);
            setClaveOptions(response.map((r: { clave: string, descripcion: string }) => ({ label: `(${r.clave}) ${r.descripcion}`, value: r.clave })))
        })
    }

    const fetchUnidad = (defaultUnidadSat: string) => {
        setIsLoading(true);
        return post(`https://api.fiscalpop.com/api/v1/sat/claveUnidades/${currentUser.claroProfile.authToken}`, {
            compare: defaultUnidadSat,
            clave: defaultUnidadSat
        }).then(response => {
            setIsLoading(false);
            setUnidadOptions(response.map((r: { clave: string, nombre: string }) => ({ label: `(${r.clave}) ${r.nombre}`, value: r.clave })))
        })
    }

    const claveProdErrTest = () => {
        const isValidProdServ = claveOptions.find(o => o.value.toLowerCase() === (defaultClaveSatProdServ||'').toLowerCase());
        if (!isValidProdServ) {
            setProdServErr(true);
        }
    }

    const unitErrTest = () => {
        // Can be empty for later addition
        if (!defaultUnidadSat) {
            return setUnidadErr(false);
        }
        const isValidUnidad = unidadOptions.find(o => o.value.toLowerCase() === (defaultUnidadSat||'').toLowerCase());
        if (!isValidUnidad) {
            setUnidadErr(true);
        }
    }

    useEffect(() => {
        fetchClaves('');
        fetchUnidad('');
        console.log('[SETUP - DEFAULTS] useEffect!');
        const claveSubs = claveSubject.pipe(debounceTime(350)).subscribe(claveProdServ => {
            console.log('[SETUP - DEFAULTS] clave subs: ', claveProdServ, defaultClaveSatProdServ);
            fetchClaves(claveProdServ);
        })
        const unitSubs = unitSubject.pipe(debounceTime(350)).subscribe(claveUnidad => {
            fetchUnidad(claveUnidad);
        })

        return () => {
            console.log('[SETUP - DEFAULTS] useEffect closed');
            claveSubs.unsubscribe();
            unitSubs.unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClaveChange = (claveProdServ: string) => {
        setDefaultClaveSatProdServ(claveProdServ);
        setProdServErr(false);
        claveSubject.next(claveProdServ);
    }
    const onUnidadChange = (claveUnidad: string) => {
        setDefaultUnidadSat(claveUnidad);
        setUnidadErr(false);
        unitSubject.next(claveUnidad);
    }

    const onSelectedClaveProd = (claveSatProdServ: string) => {
        console.log('onSelectedClaveProd: ', claveSatProdServ);
        setDefaultClaveSatProdServ(claveSatProdServ);
        setProdServErr(false);
    }

    const onSelectedUnidad = (unidadSat: string) => {
        setDefaultUnidadSat(unidadSat);
        setUnidadErr(false);
    }

    const ivaOptions = [
        { value: 0.16, label: 'IVA 16%' },
        { value: 0, label: 'IVA 0%' },
    ];
    const iepsOptions = [
        { value: '', label: 'Sin IEPS' },
        { value: 0.3, label: 'IEPS 30%' },
        { value: 0.27, label: 'IEPS 27%' },
        { value: 1.6, label: 'IEPS 160%' },
        { value: 0.53, label: 'IEPS 53%' },
        { value: 0.3, label: 'IEPS 30%' },
        { value: 0.5, label: 'IEPS 50%' },
        { value: 0.07, label: 'IEPS 7%' },
        { value: 0.25, label: 'IEPS 25%' },
        { value: 0.06, label: 'IEPS 6%' },
        { value: 0.08, label: 'IEPS 8%' },
        { value: 0.09, label: 'IEPS 9%' },
        { value: 0.03, label: 'IEPS 3%' },
    ]

    return (
        <Modal
            isOpen={modalIsOpen}
            style={customStyles}
            onRequestClose={onCancel}
            contentLabel="FiscalPOP Modal"
        >
            {
                items && items.length ?
                    <>
                        <div id="_FiscalpopDialog">
                            <h4>
                                Configurar {items.length} artículos
                            </h4>
                        </div>
                        <form id="FiscalpopDialog" onSubmit={onSubmit}>
                            <div className="fields">
                                <AutoComplete
                                    isLoading={isLoading}
                                    placeholder="Clave producto o servicio default a usar"
                                    label="Clave producto o servicio"
                                    value={defaultClaveSatProdServ}
                                    displayLabel={true}
                                    type="text"
                                    hasError={prodServErr}
                                    onBlur={claveProdErrTest}
                                    onSelect={onSelectedClaveProd}
                                    onChange={onClaveChange}
                                    options={claveOptions} />

                                <AutoComplete
                                    isLoading={isLoading}
                                    placeholder="Clave de unidad default a usar"
                                    label="Clave de Unidad de medida"
                                    value={defaultUnidadSat}
                                    displayLabel={true}
                                    type="text"
                                    hasError={unidadErr}
                                    onBlur={unitErrTest}
                                    onSelect={onSelectedUnidad}
                                    onChange={onUnidadChange}
                                    options={unidadOptions} />
                                <div className="taxBlock">
                                    <Select value={iva} onChange={setIva} options={ivaOptions} label="IVA %" />
                                    <Select value={ieps} onChange={setIEPS} options={iepsOptions} label="IEPS %" />
                                </div>
                            </div>
                            <div className="actions">
                                <Button secondary={true} type={'button'} disabled={false} handleClick={onCancel}><span>Cancelar</span></Button>
                                <Button primary={true} type={'submit'} disabled={prodServErr || unidadErr}><span>Guardar</span></Button>
                            </div>
                        </form>
                    </>
                    : ''
            }
        </Modal>
    )
}


export default ItemBulkEditModal;