import React, { useState, useEffect } from 'react';

import './Account.scss';
import { BaseReact } from '../../base.model';
import { ProfileQL } from '../../models/Profile';
import SectionTitleBar from '../components/titlebar';
import Button from '../Forms/Button';
import moment from '../../services/unifiedMoment';
import { useQuery, useLazyQuery } from '@apollo/client';
import { graphqlSchema } from '../../services/graphql.schema';
import { ConektaCustomer_PaymentSource, ConektaOrder, SpeiResponse } from '../../models/Conekta';
import ActionCards from './CardDialogs/Account.cards';
import AccountPayment from './PaymentDialogs/Account.payment';
import { numberToCurrencyString } from '../../services/formatting';
import GraphQlClient from '../../services/graphql';
import AccountCard from './Account.paymentMethod';
import SpeiConfirm from './SpeiDialog/Spei.confirm';

interface AccountProps extends BaseReact {
    currentUser: ProfileQL;
}


declare var Conekta: any;

function Account({ history, location, currentUser }: AccountProps) {
    const [addCardOpen, setAddCardOpen] = useState(false);
    const [addPayment, setAddPayment] = useState(false);
    const [selectedCard, setSelectedCard] = useState('');
    const [paymentMethods, setPaymentMethods] = useState<ConektaCustomer_PaymentSource[]>([]);
    const [paymentOrders, setPaymentOrders] = useState<ConektaOrder[]>([]);
    // Display Modal for SPEI
    const [displaySpei, setDisplaySpei] = useState<SpeiResponse>(null);
    const [showSpei, setShowSpei] = useState(false)
    useQuery(graphqlSchema.CONEKTA.CARDS.getCardPayments, {
        onCompleted: ({ getCardPayments }: { getCardPayments: ConektaCustomer_PaymentSource[] }) => {
            console.log('Payment methods gotten: ', getCardPayments);
            setPaymentMethods(getCardPayments)
        }
    });
    useQuery(graphqlSchema.CONEKTA.ORDERS.getPaymentOrders, {
        onCompleted: ({ getPaymentOrders }: { getPaymentOrders: ConektaOrder[] }) => {
            console.log('Payment orders gotten: ', getPaymentOrders);
            setPaymentOrders(getPaymentOrders)
        }
    });
    const [getConektaOrders] = useLazyQuery(graphqlSchema.CONEKTA.ORDERS.getPaymentOrders, {
        onCompleted: ({ getPaymentOrders }: { getPaymentOrders: ConektaOrder[] }) => {
            console.log('Payment orders gotten: ', getPaymentOrders);
            setPaymentOrders(getPaymentOrders)
        },
        fetchPolicy: 'network-only'
    });
    const [getConektaCards] = useLazyQuery(graphqlSchema.CONEKTA.CARDS.getCardPayments, {
        onCompleted: ({ getCardPayments }: { getCardPayments: ConektaCustomer_PaymentSource[] }) => {
            console.log('Payment methods gotten: ', getCardPayments);
            setPaymentMethods(getCardPayments)
        }
    });

    const closeCardModal = (cardAdded: ConektaCustomer_PaymentSource) => {
        setAddCardOpen(false);
        if (!!cardAdded) {
            console.log('Closing card modal: ', cardAdded);
            const _paymentMethods = JSON.parse(JSON.stringify(paymentMethods));
            _paymentMethods.push(cardAdded);
            setPaymentMethods(_paymentMethods)
            getConektaCards();
            getConektaOrders();
        }
    }

    const openCardModal = () => {
        setAddCardOpen(true);
    }

    const closePaymentModal = (paymentResponse: any = null) => {
        setAddPayment(false);
        if (!paymentResponse) {
            return;
        }
        if (paymentResponse.__typename === 'ConektaOrder') {
            // Notify that Card Payment has been created

        } else if (paymentResponse.__typename === 'SpeiResponse') {
            // Notify that Spei Payment has been created
            setDisplaySpei(paymentResponse);
            setShowSpei(true)
        }
        setTimeout(() => {
            getConektaOrders();
        }, 500);
    }
    const openPaymentModal = () => {
        setAddPayment(true);
    }

    useEffect(() => {
        if (typeof Conekta === 'undefined') {
            // Catch possible error on load
            setTimeout(() => {
                Conekta.setPublicKey('key_UEqQKVwc2CnRrwKCe9sXBKw');
            }, 2200)
        } else {
            Conekta.setPublicKey('key_UEqQKVwc2CnRrwKCe9sXBKw');
        }
        GraphQlClient.recallCurrentUser();

    }, []);

    const getOrderState = (ord: ConektaOrder) => {
        if (ord.paid) {
            return <p className="_state good">Pagado</p>;
        } else if (ord.expired) {
            return <p className="_state bad">Vencido</p>
        } else {
            return <p className="_state meh">No pagado</p>;
        }
    }
    const getOrderType = (ord: ConektaOrder) => {
        if (ord.type === 'card') {
            return 'Tarjeta';
        } else if (ord.type === 'spei') {
            return 'SPEI'
        } else {
            return 'Otro';
        }
    }

    const selectCard = (cardId: string) => {
        return () => {
            if (selectedCard === cardId) {
                setSelectedCard('');
            } else {
                setSelectedCard(cardId);
            }
        }
    }

    const eraseCard = (cardId: string) => {
        setPaymentMethods(paymentMethods.filter(pm => pm.id !== cardId));
    }

    const closeSpeiConfirm = () => {
        setShowSpei(null)
    }

    const [datePeriod] = useState([moment().startOf('month'), moment().endOf('month')]);
    return (
        <div id="Account">
            <SectionTitleBar currentUser={currentUser} title="Tu cuenta y pagos" />
            <div className="accountContent">
                <p className="payPeriod">Periodo de pago: <b>{datePeriod[0].format('D MMM')} - {datePeriod[1].format('D MMM')}</b></p>
                <div className="twoColumns">
                    <div className="card">
                        {
                            currentUser?.billing?.isClusterType && currentUser?.billing?.clusterName ?
                                <div className="row">
                                    <h3 className="center">Saldo administrado por <b>{currentUser?.billing?.clusterName}</b></h3>
                                </div>
                                :
                                <div className="twoColumns">
                                    <h3>Tu saldo</h3>
                                    <h3 className="right">$ {numberToCurrencyString(!!currentUser.billing ? currentUser.billing.balance : 0)}</h3>
                                </div>

                        }
                        
                        <Button primary={true} handleClick={openPaymentModal} disabled={!!(currentUser?.billing?.isClusterType && currentUser?.billing?.clusterName) }>
                            <span>Agregar saldo</span>
                        </Button>
                    </div>
                    <div></div>
                </div>
                <div className="paymentMethods card">
                    <h4>Tarjetas asignadas</h4>
                    <p>Agrega tarjetas para agregar crédito rápidamente. <br />
                        Siempre podrás hacer pago por SPEI si prefieres.</p>
                    <div className="divider"></div>
                    <Button primary={true} handleClick={openCardModal} disabled={paymentMethods.length >= 5 || !!(currentUser?.billing?.isClusterType && currentUser?.billing?.clusterName)}><span>{paymentMethods.length >= 5 ? 'Llegaste al límite de tarjetas por usuario (5 tarjetas)' : 'Agregar nueva tarjeta'}</span></Button>
                    <div className={`cardList ${paymentMethods.length ? 'spaced' : ''}`}>
                        {
                            !paymentMethods.length ?
                                <div className="_noCard">
                                    <h4>No has agregado tarjetas todavía</h4>
                                </div>
                                :
                                paymentMethods.map((method, id) => {
                                    return <AccountCard selectCard={selectCard} method={method} selectedCardId={selectedCard} key={id} onErrase={eraseCard} />
                                })
                        }
                    </div>
                </div>
                <div className="movementList card">
                    <h4>Historial de pagos</h4>
                    <div className="paymentEntry header">
                        <p>Fecha pago</p>
                        <p>Tipo de pago</p>
                        <p>Descargar Factura</p>
                        <p>Cantidad</p>
                        <p>Estado</p>
                    </div>
                    {
                        paymentOrders.sort((a, b) => b.createdAt - a.createdAt).map((ord, i) => {

                            return (
                                <div className="paymentEntry" key={i}>
                                    <div>
                                        <p>{moment(ord.createdAt).format('D MMM, YYYY')}</p>
                                        <p className="_small">{moment(ord.updatedAt || ord.createdAt).format('hh:mm A')}</p>
                                    </div>
                                    <p>{getOrderType(ord)}</p>
                                    {
                                        ord.invoiceUUID ?
                                            <a rel="noopener noreferrer" href={`https://api.fiscalpop.com/api/v1/cfdi/download/pdf/${currentUser.claroProfile.authToken}?uuid=${ord.invoiceUUID}`} download={true}>
                                                Descargar
                                        </a>
                                            :
                                            <p className="uuid">
                                                {ord.paid ? 'No disponible' : ''}
                                            </p>
                                    }
                                    <p>$ {numberToCurrencyString(ord.amount / 100)}</p>
                                    {getOrderState(ord)}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <ActionCards isOpen={addCardOpen} onRequestClose={closeCardModal} />
            <AccountPayment isOpen={addPayment} onRequestClose={closePaymentModal} paymentMethods={paymentMethods} />
            <SpeiConfirm speiOrder={displaySpei} email={currentUser.claroProfile.email} isOpen={showSpei} onRequestClose={closeSpeiConfirm} />
        </div>
    )
}

export default Account;