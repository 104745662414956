import React, { useState, FormEvent, useEffect } from 'react';
import './LostPassword.scss';
import { BaseReact } from '../../base.model';
import { Link, Redirect } from 'react-router-dom';
import Button from '../Forms/Button';
import { useMutation } from '@apollo/client';
import { graphqlSchema } from '../../services/graphql.schema';
import { ClaroProfile } from '../../models/Profile';
import GraphQlClient from '../../services/graphql';
import InputAnimated from './input/input';


interface LostPasswordProps extends BaseReact { }

function PasswordSet({ history, location, match }: LostPasswordProps) {
    const [isValidToken, setIsValidToken] = useState(false);
    const [isTestingToken, setIsTestingToken] = useState(true);

    const [password, setPassword] = useState('');
    const [confirmPassowrd, setConfirmPassword] = useState('');

    const [registerRedirect, setRegisterRedirect] = useState(false);


    const [validateToken] = useMutation(graphqlSchema.PROFILE.validateToken, {
        onCompleted: ({ validateToken }: { validateToken: boolean }) => {
            console.log('Token valid: ', validateToken);
            setIsTestingToken(false)
            setIsValidToken(validateToken)
        },
        onError: (e) => {
            setIsTestingToken(false);
            setIsValidToken(false)
        }
    })

    useEffect(() => {
        console.log('Match: ', match)
        const tokenIs = (match.params as { token: string })['token'];
        validateToken({
            variables: {
                token: tokenIs
            }
        })
    }, [match, validateToken])

    const [applyNewPassword] = useMutation(graphqlSchema.PROFILE.passwordReset, {
        onCompleted: ({ passwordReset }: { passwordReset: { claroProfile: ClaroProfile, token: string } }) => {
            setIsTestingToken(false);
            console.log('Password reset applied: ', passwordReset);
            GraphQlClient.logSession(passwordReset.token)
                .then(d => {
                    console.log('Me Result: ', d);
                    setRegisterRedirect(true)
                });

        }, onError: (e) => {
            console.log('Error requesting password reset: ', e);
        }
    })


    const submitRecover = (e: FormEvent) => {
        e.preventDefault();
        if (confirmPassowrd !== password) {
            return;
        }
        applyNewPassword({
            variables: {
                token: (match.params as { token: string }).token,
                newPassword: password
            }
        })
    }


    return (
        <div id="LostPassword">
            <form className="_loginForm recover" onSubmit={submitRecover}>
                <h3>Cambiar contraseña</h3>
                {
                    isTestingToken ?
                        <p>
                            Confirmando su link, espere...
                        </p>
                        :

                        (
                            isValidToken ?
                                <>
                                    <InputAnimated hasError={password && confirmPassowrd && (password !== confirmPassowrd)}
                                        type="password" label="Nueva contraseña"
                                        value={password} onChange={setPassword} />
                                    <InputAnimated hasError={password && confirmPassowrd && (password !== confirmPassowrd)} 
                                        type="password" label="Confirmar contraseña"
                                        value={confirmPassowrd} onChange={setConfirmPassword} />
                                    <Button primary={true} type="submit" disabled={(password !== confirmPassowrd) || !password || !confirmPassowrd}>
                                        <span>Establecer contraseña</span>
                                    </Button>
                                </>
                                :
                                <p>
                                    El token de cambio de contraseña es inválido o venció
                                </p>)
                }
                <div className="_toRegiser">
                    <Link to="/login">
                        {
                            !isValidToken ?
                                <span>
                                   <b> Regresar!</b> no quiero cambiar contraseña
                                </span>
                                :
                                <span>
                                    Regresar
                                </span>
                        }
                    </Link>
                </div>
            </form>
            {
                registerRedirect ?
                    <Redirect to="/" />
                    : ''
            }
        </div>
    )
}

export default PasswordSet;