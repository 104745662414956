import { graphqlSchema } from './graphql.schema';
import { getCookies } from './document.cookie';
import { NormalizedCacheObject } from 'apollo-boost';
// import { ApolloClient } from 'apollo-client';

import { split, HttpLink, ApolloClient, InMemoryCache } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';

// import { HttpLink } from 'apollo-link-http';
// import { setContext } from 'apollo-link-context';
// import { InMemoryCache } from 'apollo-boost';
import { getQueryFromWindow } from './window.query';

import { BehaviorSubject } from 'rxjs';
import { ProfileQL, FiscalpopProfile } from '../models/Profile';

const AUTH_TOKEN = 'claro_profileid';
const FP_ADMIN_AUTH_TOKEN = 'fp_internal';
const REDIRECT_TOKEN = 'token';
const GESTOR_TOKEN = 'gestores_profileid';


class GraphQlClientService {
    private _userInvoked: boolean = false;
    private _isGestorUser: boolean = false;
    private _client: ApolloClient<NormalizedCacheObject>;
    private _currentUserSubject: BehaviorSubject<ProfileQL> = new BehaviorSubject({ claroProfile: null, fiscalpopProfile: null, fiscalpopProfileStatus: null, billing: null });
    constructor() {
        console.log('Setting authLink');
        const authLink = setContext((_, { headers }) => {
            console.log('Setting context')
            const storage = localStorage.getItem(AUTH_TOKEN)
            const cookies = getCookies();
            const query = getQueryFromWindow();
            const token = (query[AUTH_TOKEN] ? btoa(query[AUTH_TOKEN]) : '') || cookies[AUTH_TOKEN] || storage || '';
            if (token) {
                localStorage.setItem(AUTH_TOKEN, token)
            }
            if(cookies[GESTOR_TOKEN] && !this._isGestorUser) {
                this._isGestorUser = true;
            }
            console.log('[Apollo Client] token: ', token);
            return {
                headers: {
                    ...headers,
                    authorization: token ? `${token}` : '',
                    fpadmin: cookies[FP_ADMIN_AUTH_TOKEN] || query[FP_ADMIN_AUTH_TOKEN] || ''
                }
            }
        })
        // Crate WS 
        const wsLink = new WebSocketLink({
            uri: 'wss://apiclaro.fiscalpop.com/graphql',
            // uri: 'ws://localhost:3022/graphql',
            options: {
                reconnect: true,
                connectionParams: {
                    authorization: (() => {
                        const storage = localStorage.getItem(AUTH_TOKEN)
                        const cookies = getCookies();
                        const query = getQueryFromWindow();
                        const token = (query[AUTH_TOKEN] ? btoa(query[AUTH_TOKEN]) : '') || cookies[AUTH_TOKEN] || storage || '';
                        console.log(`[Apollo WS] token: `, token);
                        return token ? `${token}` : ''
                    })()
                }
            }
        });

        // Create an http link:
        const httpLink = new HttpLink({
            uri: 'https://apiclaro.fiscalpop.com/graphql',
            // uri: 'http://localhost:3022/graphql',
            fetch: window.fetch,
        });

        const authHttpLink = authLink.concat(httpLink);

        const splitLink = split(
            ({ query }) => {
                const definition = getMainDefinition(query);
                return (
                    definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
                );
            },
            wsLink,
            authHttpLink,
        );

        this._client = new ApolloClient({
            link: splitLink,
            cache: new InMemoryCache({
                dataIdFromObject: o => (o._id ? `${o.__typename}:${o._id}`: null),
            }),

        });
    }

    private compileCookieSessions(me:ProfileQL) {
        const cookies = getCookies();
        const query = getQueryFromWindow();
        if (!cookies[AUTH_TOKEN]) {
            return;
        }
        if(this._isGestorUser){
            console.warn('<compileCookieSessions> User is Gestor type')
            return;
        }
        const COMPILED_SESSIONS = 'cr_session';
        const current: { profileid: string[], mws_profileid: string[], claro_profileid: string[] } = !cookies[COMPILED_SESSIONS] ? {} : JSON.parse(atob(cookies[COMPILED_SESSIONS]));
        console.log('compileCookieSessions: ', current);
        if (!current.claro_profileid) {
            current.claro_profileid = [cookies[AUTH_TOKEN]];
            // Store cookie
            document.cookie = `${COMPILED_SESSIONS}=${btoa(JSON.stringify(current))};domain=.fiscalpop.com;max-age=31536000;path=/;secure`;
        } else if (!current.claro_profileid.includes(cookies[AUTH_TOKEN])) {
            current.claro_profileid.push(cookies[AUTH_TOKEN]);
            document.cookie = `${COMPILED_SESSIONS}=${btoa(JSON.stringify(current))};domain=.fiscalpop.com;max-age=31536000;path=/;secure`;
        }
        // Check if session is comming from redirect (gestores should not set this)
        console.log('Compile Query: ', query);
        const redirectSession = query[REDIRECT_TOKEN] ? query[REDIRECT_TOKEN] : '';
        const linkerId = query['linker'] ? query['linker'] : '';
        if(!redirectSession){
            return;
        }
        if(current.profileid.includes(redirectSession)){
            console.log('Setting due to cr_session: ', `${AUTH_TOKEN}=${redirectSession};domain=.fiscalpop.com;max-age=31536000;path=/app;secure`);
            document.cookie = `${AUTH_TOKEN}=${redirectSession};domain=.fiscalpop.com;max-age=31536000;path=/app;secure`;
        } else if(!!me.claroProfile.linkerId && (me.claroProfile.linkerId === linkerId)){
            // May open on another browser but have the redirect linker enabled, but cookies are missing, try to compare account linkerIds
            console.log('Setting due to LinkerId: ', `${AUTH_TOKEN}=${redirectSession};domain=.fiscalpop.com;max-age=31536000;path=/app;secure`);
            document.cookie = `${AUTH_TOKEN}=${redirectSession};domain=.fiscalpop.com;max-age=31536000;path=/app;secure`;
        }
    }

    logSession(token: string) {
        if (token) {
            const domain = '.claro.fiscalpop.com'
            localStorage.setItem(AUTH_TOKEN, token);
            document.cookie = `${AUTH_TOKEN}=${token};domain=${domain};`;
        }
        return this._invokeGQLuser();
    }

    logOut() {
        const storage = localStorage.getItem(AUTH_TOKEN)
        const cookies = getCookies();
        if (storage) {
            localStorage.removeItem(AUTH_TOKEN);
        }
        if (cookies[AUTH_TOKEN]) {
            const domain = '.claro.fiscalpop.com'
            document.cookie = `${AUTH_TOKEN}=;domain=${domain};expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
        }
        this._currentUserSubject.next(null);
    }

    refreshGQLuser() {
        this._invokeGQLuser();
    }

    private _invokeGQLuser() {
        // To be called whenever user has been updated without using Subscriptions
        this._userInvoked = true;
        return this._client.query({
            query: graphqlSchema.PROFILE.me,
            fetchPolicy: 'network-only'
        })
            .then((response) => {
                if (response.data.me) {
                    console.log('[_invokeGQLuser] Currrent User: ', response.data.me);
                    this._currentUserSubject.next(response.data.me);
                    this.compileCookieSessions(response.data.me);
                } else {
                    console.error('[_invokeGQLuser] Currrent User: ', response.data.me);
                    console.error(new Error('User needs Profile Id'))
                    this._currentUserSubject.next(response.data.me);
                }
                return response.data.me as ProfileQL;
            })
    }

    modifyClientMLprofile(props: { [key: string]: any }) {
        const profile = this._currentUserSubject.value;
        const currentMLprofile = profile.claroProfile;
        const newMLprofile = Object.assign({}, currentMLprofile, props);
        profile.claroProfile = newMLprofile;
        this._currentUserSubject.next(profile);
    }

    recallCurrentUser() {
        this._invokeGQLuser();
    }

    submitFiscalpopClient(client: FiscalpopProfile) {
        return this._client.mutate({
            mutation: graphqlSchema.FISCALPOP.SETUP.setFiscalpopClient,
            variables: {
                client
            }
        })
            .then((clientMutation) => {
                console.log('Client mutation: ', clientMutation);
                this._invokeGQLuser();
                return clientMutation;
            })
    }

    getCurrentUser() {
        console.log('Getting current user')
        if (!this._userInvoked) {
            this._invokeGQLuser();
        }
        return this._currentUserSubject;
    }

    get client() {
        return this._client;
    }
}


const GraphQlClient = (() => {
    const client = new GraphQlClientService();
    return client;
})();
export default GraphQlClient;