import React, { useState, FormEvent } from 'react';
import './Register.scss';
import { BaseReact } from '../../base.model';
import Button from '../Forms/Button';
import { Link } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { graphqlSchema } from '../../services/graphql.schema';
import { ClaroProfile } from '../../models/Profile';
import GraphQlClient from '../../services/graphql';
import sharedToasterSubject from '../../services/shared.toasterSubject';

import Modal from 'react-modal';
import InputAnimated from './input/input';
import { LogoBanner } from '../visuals/banner.logo';
import { getCookies } from '../../services/document.cookie';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        padding: '0px',
        border: 'none',
        boxShadow: 'rgba(0, 0, 0, 0.32) 1px 1px 3px 1px'
    }
};

interface RegisterProps extends BaseReact { }

function Register({ history, location }: RegisterProps) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    // Account data
    const [companyName, setCompanyName] = useState('');
    const [privateKey, setPrivateKey] = useState('');
    const [publicKey, setPublicKey] = useState('');

    const [displayInstruction, setDisplayInstructions] = useState(false);
    const [registering, setRegistering] = useState(false);

    const [keysError, setKeysError] = useState(false);

    const [registerAccount] = useMutation(graphqlSchema.PROFILE.SETUP.registerAccount, {
        onCompleted: ({ registerAccount }: { registerAccount: { claroProfile: ClaroProfile, token: string } }) => {
            console.log('Registration: ', registerAccount);
            if (!registerAccount.token) {
                return sharedToasterSubject.next({ type: 'error', message: `Contraseña o usuario equivocados` });
            }
            GraphQlClient.logSession(registerAccount.token)
                .then(d => {
                    console.log('Me Result: ', d);
                    history.push('/');
                });

        },
        onError: (e) => {
            console.error('Error Registration: ', e.graphQLErrors[0].message);
            setRegistering(false);
            setKeysError(true);
            sharedToasterSubject.next({ type: 'error', message: `${e.graphQLErrors[0].message}` })
        }
    })

    const testForEmail = (str: string) => {
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(str)
    }

    const inputsValid = () => {
        if (passwordConfirm !== password || !password || !passwordConfirm) {
            return false;
        }
        if (!companyName || !email) {
            return false;
        }
        if (!publicKey || !privateKey) {
            return false;
        }
        const emailValid = testForEmail(email)
        if (!emailValid) {
            return false;
        }
        return true
    }

    const closeInstructions = () => {
        setDisplayInstructions(false);
    }
    const openInstructions = () => {
        setDisplayInstructions(true);
    }

    const submitRegistration = (e: FormEvent) => {
        e.preventDefault();
        if (passwordConfirm !== password) {
            return;
        }
        if (!companyName || !email) {
            return
        }
        setRegistering(true);
        console.log('<submitRegistration> Calling registration: ', {
            email,
            password,
            companyName
        });
        setKeysError(false);
        const { _ad_origin } = getCookies()
        registerAccount({
            variables: {
                account: {
                    email,
                    password,
                    companyName,
                    publicKey,
                    privateKey,
                    adOrigin: _ad_origin || null
                }
            }
        })
    }

    const renderInstructions = () => {
        return (
            <Modal
                isOpen={displayInstruction}
                onRequestClose={closeInstructions}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="ClaroShop instructions"
            >
                <div id="mws_instructions">
                    <h3>Obtener las llaves de API para ClaroShop</h3>
                    <ol>
                        <li>
                            <p>Entra a tu cuenta de ClaroShop</p>
                        </li>
                        <li>
                            <p>Escoge <b>"Mis productos"</b></p>
                        </li>
                        <li>
                            <p>Selecciona <b>Carga po API</b></p>
                        </li>
                        <li>
                            <p>Elige <b>Crear App</b> y guarda nombre API como "FiscalPOP"</p>
                        </li>
                        <li>
                            <p>Usa los datos de <b>Public Key</b> y <b>Private Key</b> en tu registro de FiscalPOP</p>
                        </li>
                    </ol>
                    <img src="/Claro_Keys.png" alt="Instrucciones conectar ClaroShop a fiscalpop" />
                    <div className="actions">
                        <Button primary={true} handleClick={closeInstructions}>
                            <span>Ya conozco mis llaves, cerrar</span>
                        </Button>
                    </div>
                </div>
            </Modal>
        )
    }


    return (
        <div id="Register">
            <div className="_loginContainer">
                <LogoBanner />

                <div className="_logoSelect">
                    <div className="_withReturn">
                        <a href="https://fiscalpop.com/login?type=ecommerce">
                            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                <path d="M26 13.5H1" stroke="#AFAFAF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M13.5 26L1 13.5L13.5 1" stroke="#AFAFAF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </a>
                        <div> <h2 className="gray">eCommerce </h2><h3>ClaroShop</h3></div>
                    </div>
                    <form onSubmit={submitRegistration}>
                        <InputAnimated
                            type="text"
                            value={email}
                            label="Email"
                            hasError={!!email && !testForEmail(email)}
                            onChange={setEmail}
                        />
                        <InputAnimated
                            type="text"
                            value={companyName}
                            label="Nombre de tu empresa"
                            onChange={setCompanyName}
                        />
                        <InputAnimated type="password" label="Contraseña"
                            value={password}
                            onChange={setPassword}
                            hasError={password && passwordConfirm && (password !== passwordConfirm)} />
                        <InputAnimated type="password" label="Confirmar Contraseña"
                            value={passwordConfirm}
                            onChange={setPasswordConfirm}
                            hasError={password && passwordConfirm && (password !== passwordConfirm)} />

                        <InputAnimated type="text" label="Llave pública (Public Key)" value={publicKey} onChange={setPublicKey} hasError={keysError} />
                        <InputAnimated type="text" label="Llave privada (Private Key)" value={privateKey} onChange={setPrivateKey} hasError={keysError} />
                        <Button tertiary={true} type="button" handleClick={openInstructions}>
                            <span>
                                ¿Donde obtengo mis <b>Llaves de ClaroShop</b>?
                            </span>
                        </Button>
                        <div className="_actions">
                            <span></span>
                            <button type="submit" disabled={registering || !inputsValid()}>
                                {
                                    registering ? 'Creando cuenta' :
                                        'Crear cuenta'
                                }
                            </button>
                        </div>
                    </form>
                </div>

                {renderInstructions()}

                <Link to="/login">
                    ¿Ya tienes cuenta? <b>Entra</b>
                </Link>
            </div>
        </div>
    )
}

export default Register;