import { ClaroOrderBinder, ClaroOrderDetail } from './../models/Orders';
import { BillConceptos } from './../models/Factura';
import { ClaroProfile } from '../models/Profile';

export const orderDetailToConceptos = (order: ClaroOrderDetail, binders: ClaroOrderBinder[], profile: ClaroProfile) => {
    console.log(`<orderDetailToConceptos> order: `, order);
    const productos = order.productos.filter(p => !!p.guia);
    // Partial stamping of guias is allowed, but Guia must be present
    const pendingProductos = productos.filter(p => !binders.find(b => b.guia === p.guia));
    return pendingProductos.reduce((p, c) => {
        const valorUnitarioBase = c.importe;
        console.log("valorUnitarioBase-- ", valorUnitarioBase);
        const valorUnitario = valorUnitarioBase / 1.16;
        const entry: BillConceptos = {
            claveProdServ: profile.defaultClaveSatProdServ || '01010101',
            claveUnidad: profile.defaultUnidadSat || 'ACT',
            descripcion: c.producto,
            noIdentificacion: `${order.orderId}`,
            valorUnitario: valorUnitario,
            cantidad: 1,
            impuestos: [{
                tasa: 0.16,
                type: 'iva',
                retencion: false
            }]
        }
        p.push(entry);
        return p;
    }, [] as BillConceptos[]);
} 