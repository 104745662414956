import React, { useState, useEffect } from 'react';

import './Stamp.global.grouping.scss';
import { ClaroOrdersBinded } from '../../models/Orders';
import moment from '../../services/unifiedMoment';
import StampGlobalGroupingGroup from './Stamp.global.grouping.group';
import { ProfileQL } from '../../models/Profile';

function StampGlobalGrouping({ currentUser, orders, grouping, lugarExpedicion, toggleSelect, unSelectedDict, propagateStampedGroup }: { currentUser: ProfileQL, propagateStampedGroup: (orderIds: string[], uuid: string, lastDate: number) => void; orders: ClaroOrdersBinded[]; grouping: 1 | 7 | 30; lugarExpedicion: string; toggleSelect: (orderId: string) => (value: boolean) => void; unSelectedDict: { [key: string]: boolean } }) {
    const [orderGroups, setOrderGroups] = useState<{ label: string, orders: ClaroOrdersBinded[], serie: string, folio: number }[]>([]);

    const groupOrders = (_orders: ClaroOrdersBinded[]) => {
        const _orderGroups = _orders.reduce((p, c) => {
            const _dateMom = moment(new Date(c.order.fechaautorizacion).getTime());
            const _date = grouping === 30 ? _dateMom.endOf('month') : (grouping === 7 ? _dateMom.endOf('week') : _dateMom.endOf('day'));
            const _dateLabel = _date.format('dddd, MMMM Do YYYY');
            if (p[_dateLabel]) {
                p[_dateLabel].push(c);
            } else {
                p[_dateLabel] = [c];
            }
            return p;
        }, {} as { [key: string]: ClaroOrdersBinded[] });
        return Object.keys(_orderGroups).map((key, index) => ({ label: key, orders: _orderGroups[key], serie: 'GB-AMAZON', folio: 1 + index }));
    }

    useEffect(() => {
        setOrderGroups(groupOrders(orders));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orders, grouping]);

    if (!orderGroups.length) {
        return (
            <div id="StampGlobalGrouping">
                <div className="noOrdersGroup">
                    <h2>
                        No hay facturas a emitir por ahora
                    </h2>
                </div>
            </div>
        )
    }

    return (
        <div id="StampGlobalGrouping">
            {orderGroups.map((order, i) => {
                return (
                    <StampGlobalGroupingGroup key={i} currentUser={currentUser} propagateStampedGroup={propagateStampedGroup} toggleSelect={toggleSelect} unSelectedDict={unSelectedDict} label={order.label} serie={order.serie} folio={order.folio} group={order.orders} lugarExpedicion={lugarExpedicion} />
                )
            })}
        </div>
    )
}

export default StampGlobalGrouping;