import React, { useState } from "react";

import './Input.scss';

interface InputProps {
    onChange?: Function;
    onBlur?: (value?: string | number) => void;
    placeholder: string;
    label?: string;
    value: string | number;
    step?: number;
    type: 'number' | 'text' | 'file' | 'date' | 'password';
    accept?: string;
    disabled?: boolean;
    hasError?: boolean;
    min?:string | number;
    max?:string | number;
}

const Input = React.forwardRef((props: InputProps, ref: React.Ref<HTMLButtonElement>) => {
    const selectRef = React.createRef<HTMLInputElement>();

    const [active, setActive] = useState(false);
    const [fileName, setFileName] = useState('');

    const activate = () => {
        setActive(true);
    }

    const deactivate = () => {
        setActive(false);
        if(!!props.onBlur){
            props.onBlur(props.value);
        }
    }

    const selectFile = () => {
        selectRef.current.click();
    }

    const changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.type === 'number') {
            if (props.onChange) {
                props.onChange(parseFloat(event.target.value));
            }
        } else if (props.type === 'text' || props.type === 'date' || props.type === 'password') {
            if (props.onChange) {
                props.onChange(event.target.value);
            }
        } else {
            if (!!event.target && !!event.target.files && !!event.target.files[0] && !!event.target.files[0].name) {
                setFileName(event.target.files[0].name);
                if (props.onChange) {
                    props.onChange(event.target.files[0]);
                }
            } else {
                setFileName('');
            }
        }
    }

    const classInput = `inputWrapper ${props.disabled ? 'disabled' : ''} ${active ? 'active' : ''} ${props.hasError ? 'error' : ''}`;
    return (
        <div className={`inputBlock ${props.type === 'date' ? 'dateType' : ''} ${active ? 'active' : ''} ${props.disabled ? 'disabled' : ''} ${(props.value || props.value === 0) || (props.type === 'file') ? 'dirty' : ''} ${props.hasError ? 'error' : ''}`}>
            {
                !!props.label ?
                    <label>
                        {props.label}
                    </label>
                    : <label></label>
            }
            <div className={`${classInput} ${props.type === 'file' ? 'fileWrapper' : ''}`} >
                {
                    props.type === 'file' ?
                        <div className="fileInput">
                            <input type={props.type}
                                readOnly={!props.onChange}
                                ref={selectRef}
                                accept={props.accept}
                                onFocus={activate} onBlur={deactivate}
                                placeholder={props.placeholder} onChange={changeValue} disabled={!!props.disabled} />
                            <button type="button" className="submit" onClick={selectFile} ref={ref}>
                                Seleccionar archivo
                                </button>
                            <button type="button" className="submit xs" onClick={selectFile}>
                                Seleccionar
                                </button>
                            <p>{fileName || 'Nombre de archivo...'}</p>
                        </div>
                        :
                        (
                            props.step && props.type === 'number' ?
                                <input type={props.type}
                                    readOnly={!props.onChange}
                                    ref={selectRef}
                                    value={props.value}
                                    step={props.step}
                                    onFocus={activate} onBlur={deactivate}
                                    placeholder={props.placeholder} onChange={changeValue}
                                    disabled={!!props.disabled} />
                                :
                                <input type={props.type}
                                    readOnly={!props.onChange}
                                    ref={selectRef}
                                    value={props.value}
                                    min={props.min || null}
                                    max={props.max || null}
                                    onFocus={activate} onBlur={deactivate}
                                    placeholder={props.placeholder} onChange={changeValue}
                                    disabled={!!props.disabled} />
                        )

                }
            </div>
        </div>
    )

})

export default Input;