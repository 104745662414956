import React, { useState, useReducer, useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import './App.scss';
import Loading from './controllers/Animations/loadScreen';
import { ProfileQL } from './models/Profile';
import { Redirect, BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ToasterNotifications from './controllers/visuals/ToasterNotifications';
import MenuGeneral from './controllers/Menu/Menu';
import Support from './controllers/Support/Support';
import Account from './controllers/Account/Account';
import GraphQlClient from './services/graphql';
import Register from './controllers/Registration/Register';
import Login from './controllers/Registration/Login';
import RecoverPassword from './controllers/Registration/LostPassword';
import SetupFiscalPop from './controllers/Setup/Setup';
import OrdersList from './controllers/Orders/Orders.list';
import Facturas from './controllers/Facturas/Facturas';
import FacturaReporte from './controllers/Facturas/Reporte/Facturas.reporte';
import ProductsList from './controllers/Productos/Products.list';
import ItemsBulkEdit from './controllers/Productos/BulkEdit/Items.BulkEdit';
import StampSingle from './controllers/Stamp/Stamp.single';
import StampGlobal from './controllers/StampGlobal/Stamp.global';

import * as LogRocket from 'logrocket';
import PasswordSet from './controllers/Registration/PasswordSet';

function currentUserReducer(state: ProfileQL, action: { type: string, payload: ProfileQL }) {
  switch (action.type) {
    case 'set':
      return Object.assign({}, action.payload);
    case 'refresh':
      return Object.assign({}, action.payload);
    default:
      return Object.assign({}, action.payload);
  }
}

function App() {

  const [userFetched, setUserFetched] = useState(false);
  const [currentUser, dispatchCurrentUser] = useReducer(currentUserReducer, null as ProfileQL);


  useEffect(() => {

    console.log('[APP] Use Effect User');
    const userrSubs = GraphQlClient.getCurrentUser()
      .subscribe(user => {
        console.log('[APP] Setting user from token: ', user);
        if (!user) {
          setUserFetched(true);
          dispatchCurrentUser({ type: 'set', payload: user });
        } else if (user.claroProfile && !userFetched) {
          dispatchCurrentUser({ type: 'set', payload: user });
          setUserFetched(true);

          LogRocket.identify(`${user.claroProfile._id}`, {
            name: `${user.claroProfile.companyName}`,
            email: user.claroProfile.email,

            // Add your own custom user variables here, ie:
            linker: user.claroProfile.linkerId,
            subscriptionType: 'default',
            canBill: !!user.fiscalpopProfile && !!user.fiscalpopProfile.authToken ? user.fiscalpopProfile.authToken : '',
            hasDonePayments: !!user.billing && !!user.billing.lastPaymentId ? user.billing.lastPaymentId : ''
          });
        } else if (user.claroProfile && !!userFetched) {
          dispatchCurrentUser({ type: 'refresh', payload: user });
          setUserFetched(true);
        }
      }, (e) => {
        console.error('[APP] Error fetching user: ', e);
        setUserFetched(true);
      })
    return () => {
      console.warn('Current User Unsubscribed!');
      userrSubs.unsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderLoading = () => {
    return (
      <div id="_AppLoading">
        <Loading display={true} />
      </div>
    )
  }

  const renderForLoggedOut = (RenderComponent: JSX.Element) => {
    return !!currentUser?.claroProfile?.email ? <Redirect to="/ordenes" /> : RenderComponent;
  }


  const renderForRedirect = (RenderComponent: JSX.Element) => {
    // return !currentUser || (!!currentUser && !currentUser.claroProfile) ? <Redirect to="/login" /> : RenderComponent;
    return !currentUser || (!!currentUser && !currentUser?.claroProfile?.email) ? <Redirect to="/login" /> : RenderComponent;
  }

  const renderMenuForLoggedIn = (RenderComponent: JSX.Element) => {
    return !!currentUser && currentUser?.claroProfile?.email ? RenderComponent : '';
  }

  const renderMain = () => {
    const fpStatus = !!currentUser && !!currentUser.fiscalpopProfileStatus ? currentUser.fiscalpopProfileStatus : null;
    const hasMessage = !!fpStatus && !(fpStatus.modulusMatch && fpStatus.keyModulus && fpStatus.cerModulus);
    const fpTimingErr = !fpStatus ? false : (!!fpStatus.notBefore && (new Date(fpStatus.notBefore).getTime() > Date.now())) || (!!fpStatus.notAfter && (new Date(fpStatus.notAfter).getTime() < Date.now()));
    console.log('MAIN user: ', currentUser);
    return (
      <Router>
        <ToasterNotifications />
        <div id="Master" className={`${hasMessage || fpTimingErr ? 'withMessage' : ''} ${!!currentUser && currentUser.claroProfile ? '' : 'notLogged'}`}>
          <Route path="/" render={(props) => renderMenuForLoggedIn(<MenuGeneral {...props} currentUser={currentUser} />)} />
          <Switch>
            <Route exact path="/" render={(props) => renderForRedirect(
              !currentUser || (!!currentUser && !currentUser.fiscalpopProfile) ? <Redirect to="/setup" {...props} /> : <Redirect to="/ordenes" {...props} />
            )} />

            <Route exact path="/facturas" render={(props) => renderForRedirect(
              !currentUser || (!!currentUser && !currentUser.fiscalpopProfile)  ? <Redirect to="/setup" {...props} /> : <Facturas {...props} currentUser={currentUser} />
            )} />
            <Route path="/facturas/report" render={(props) => renderForRedirect(
              !currentUser || (!!currentUser && !currentUser.fiscalpopProfile)  ? <Redirect to="/setup" {...props} /> : <FacturaReporte {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/items" render={(props) => renderForRedirect(
              !currentUser || (!!currentUser && !currentUser.fiscalpopProfile)  ? <Redirect to="/setup" {...props} /> : <ProductsList {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/items/bulk" render={(props) => renderForRedirect(
              !currentUser || (!!currentUser && !currentUser.fiscalpopProfile)  ? <Redirect to="/setup" {...props} /> : <ItemsBulkEdit {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/ordenes" render={(props) => renderForRedirect(
              !currentUser || (!!currentUser && !currentUser.fiscalpopProfile)  ? <Redirect to="/setup" {...props} /> : <OrdersList {...props} currentUser={currentUser} />
            )} />
            <Route path="/ordenes/stamp" render={(props) => renderForRedirect(
              !currentUser || (!!currentUser && !currentUser.fiscalpopProfile)  ? <Redirect to="/setup" {...props} /> : <StampSingle {...props} currentUser={currentUser} />
            )} />
            <Route path="/ordenes/globalstamp" render={(props) => renderForRedirect(
              !currentUser || (!!currentUser && !currentUser.fiscalpopProfile)  ? <Redirect to="/setup" {...props} /> : <StampGlobal {...props} currentUser={currentUser} />
            )} />
            <Route path="/setup" render={(props) => renderForRedirect(
              <SetupFiscalPop {...props} currentUser={currentUser} />
            )} />
            <Route path="/account" render={(props) => renderForRedirect(
              <Account {...props} currentUser={currentUser} />
            )} />
            <Route path="/support" render={(props) => renderForRedirect(
              <Support {...props} currentUser={currentUser} />
            )} />
            <Route path="/login" render={(props) => renderForLoggedOut(<Login {...props} />)} />
            <Route path="/register" render={(props) => renderForLoggedOut(<Register {...props} />)} />

            <Route path="/token/:token" render={(props) => <PasswordSet {...props} />} />
            <Route path="*" render={(props) => <Redirect to="/" {...props} />} />
          </Switch>
        </div>
      </Router>
    )
  }

  return (
    <ApolloProvider client={GraphQlClient.client}>
      {
        userFetched ?
          renderMain() :
          renderLoading()
      }
    </ApolloProvider>
  )
}

export default App;
