import React, { useState, FormEvent } from 'react';
import './LostPassword.scss';
import { BaseReact } from '../../base.model';
import Button from '../Forms/Button';
import { useMutation } from '@apollo/client';
import { graphqlSchema } from '../../services/graphql.schema';
import { validateEmail } from '../../utils/formatting';
import Modal from 'react-modal';
import InputAnimated from './input/input';


interface LostPasswordProps {
    location: BaseReact['location'];
    history: BaseReact['history']
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        padding: '0px',
        border: 'none',
        boxShadow: 'rgba(0, 0, 0, 0.32) 1px 1px 6px -1px'
    }
};

function RecoverPassword({ history, location }: LostPasswordProps) {
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const [instructionsSent, setInstructionsSent] = useState(false);

    const [requestReset] = useMutation(graphqlSchema.PROFILE.requestPasswordReset, {
        onCompleted: ({ requestPasswordReset }: { requestPasswordReset: boolean }) => {
            setInstructionsSent(true);
            console.log('Password reset correct: ', requestPasswordReset);

        }, onError: (e) => {
            setInstructionsSent(true);
            console.log('Error requesting password reset: ', e);
        }
    })


    const close = () => {
        history.push('/login')
    }


    const submitRecover = (e: FormEvent) => {
        e.preventDefault();
        requestReset({
            variables: {
                email: email
            }
        })
        setTimeout(() => {
            history.push('/login')
        }, 5000)
    }

    const validateEmailInput = (val: string) => {
        if (!!val) {
            setEmailValid(validateEmail(val as string))
        }
        setEmail(val.toLowerCase())
    }

    const isOpen = location.search.includes('recover')

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={close}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Producto Edit Modal"
        >
            <div id="LostPassword">
                <form className="_loginForm" onSubmit={submitRecover}>
                    <h2>Recuperar tu contraseña</h2>
                    {
                        instructionsSent ?
                            <p>
                                Se envió la liga para recuperar tu contraseña por correo
                            </p>
                            :
                            <>
                                <InputAnimated hasError={!emailValid} type="text" label="Email de tu cuenta" value={email} onChange={validateEmailInput} />
                                <Button primary={true} type="submit">
                                    <span>Recuperar</span>
                                </Button>
                            </>
                    }
                </form>
            </div>
        </Modal>
    )
}

export default RecoverPassword;