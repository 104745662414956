import React from 'react';
import { FiscalpopCFDI } from '../../models/Factura';

function FacturasEntry({ cfdi, authToken, requestCancelCfdi }: { cfdi: FiscalpopCFDI, authToken: string, requestCancelCfdi: (uuid: string) => () => void }) {
    const xmlLinkRef = React.createRef<HTMLAnchorElement>();
    const pdfLinkRef = React.createRef<HTMLAnchorElement>();

    const downloadPdf = () => {
        pdfLinkRef.current.click();
    }
    const downloadXml = () => {
        xmlLinkRef.current.click();
    }

    return (
        <div className="itemEntry">
            <div className="_serieDate">
                <p>
                    {cfdi.json["@"].Serie} -
                    <b>
                        {cfdi.json["@"].Folio}
                    </b>
                </p>
                <p>
                    {new Date(cfdi.created).toDateString()}
                </p>
            </div>
            <div className="_serie">
                <p>
                    <b>
                        {cfdi.json["@"].Folio}
                    </b>
                </p>
                <p>
                    {cfdi.json["@"].Serie}
                </p>
            </div>
            <div className="_client">
                <p>
                    {cfdi.json["cfdi:Receptor"]["@"].Nombre}
                </p>
                <p>
                    {cfdi.json["cfdi:Receptor"]["@"].Rfc}
                </p>
            </div>
            <p className="_uuid">
                {cfdi.uuid}
            </p>
            <p className="_date">
                {new Date(cfdi.created).toDateString()}
            </p>
            <p className={`${cfdi.status ? '' : 'accent'}`}>
                {cfdi.status ? 'Emitida' : 'Cancelada'}
            </p>
            <div className="buttons">
                <div className="__links">
                    <a ref={xmlLinkRef} rel="noopener noreferrer" href={`https://api.fiscalpop.com/api/v1/cfdi/download/xml/${authToken}?uuid=${cfdi.uuid}`} download={true}>Descargar XML</a>
                    <a ref={pdfLinkRef} rel="noopener noreferrer" href={`https://api.fiscalpop.com/api/v1/cfdi/download/pdf/${authToken}?uuid=${cfdi.uuid}`} download={true}>Descargar PDF</a>
                </div>
                <button className="svg" onClick={downloadXml}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z"></path></svg>
                </button>
                <button className="svg" onClick={downloadPdf}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z"></path></svg>
                </button>
                <button className="svg cancel" onClick={requestCancelCfdi(cfdi.uuid)} disabled={!cfdi.status}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
                        <path d="M1 5H3H19" stroke="#EA2B2B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6 5V3C6 2.46957 6.21071 1.96086 6.58579 1.58579C6.96086 1.21071 7.46957 1 8 1H12C12.5304 1 13.0391 1.21071 13.4142 1.58579C13.7893 1.96086 14 2.46957 14 3V5M17 5V19C17 19.5304 16.7893 20.0391 16.4142 20.4142C16.0391 20.7893 15.5304 21 15 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5H17Z" stroke="#EA2B2B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M8.875 9.375C8.875 8.82272 8.42728 8.375 7.875 8.375C7.32272 8.375 6.875 8.82272 6.875 9.375H8.875ZM6.875 17.375C6.875 17.9273 7.32272 18.375 7.875 18.375C8.42728 18.375 8.875 17.9273 8.875 17.375H6.875ZM12.875 9.375C12.875 8.82272 12.4273 8.375 11.875 8.375C11.3227 8.375 10.875 8.82272 10.875 9.375H12.875ZM10.875 17.375C10.875 17.9273 11.3227 18.375 11.875 18.375C12.4273 18.375 12.875 17.9273 12.875 17.375H10.875ZM6.875 9.375V17.375H8.875V9.375H6.875ZM10.875 9.375V17.375H12.875V9.375H10.875Z" fill="#EA2B2B" />
                    </svg>
                </button>
            </div>
        </div>
    )
}

export default FacturasEntry;